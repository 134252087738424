import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import CatalogService from "../../services/CatalogService";
import { Categories, CurrentCategory } from "./interfaces";

const initialState: Categories = {
  categories: {
    allCategories: [],
    currentCategory: {},
    statusCategories: null,
    errorCategories: null,
  },
};

export const fetchGetCategoriesThunk = createAsyncThunk<
  CurrentCategory[],
  undefined,
  { rejectValue: string }
>("contractorsCompany/fetchGetCategoriesThunk", async function (_, { rejectWithValue }) {
  try {
    // console.log("fetching GetCategories");
    const response = await CatalogService.getCategories();

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

const catalogSlice = createSlice({
  name: "catalog",
  initialState,
  reducers: {
    addCurrentCategory(state, action: PayloadAction<CurrentCategory | undefined>) {
      if (action.payload) {
        state.categories.currentCategory = action.payload;
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchGetCategoriesThunk.pending, (state) => {
        state.categories.statusCategories = "loading";
        state.categories.errorCategories = null;
      })
      .addCase(fetchGetCategoriesThunk.fulfilled, (state, action) => {
        state.categories.statusCategories = "resolvedCategories";
        state.categories.allCategories = action.payload.map((el) => ({
          ...el,
          label: el.title,
          value: el.slug,
        }));
      })
      .addCase(fetchGetCategoriesThunk.rejected, (state, action) => {
        state.categories.statusCategories = "rejectedCategories";
        state.categories.errorCategories = action.payload;
      });
  },
});

export const { addCurrentCategory } = catalogSlice.actions;

export default catalogSlice.reducer;
