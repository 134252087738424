import {
  Table,
  TableColumn,
  TableFilters,
  TableNumberFilter,
  TableTextFilter,
} from "@consta/uikit/Table";
import { Theme } from "@consta/uikit/Theme";
import { greenPreset } from "../../../../../../../ui-presets/presetsTheme/greenPreset";
import React, { useEffect, useState, useRef } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../hooks/reduxTypesHooks";
import { fetchGetBuyLotsThunk } from "../../../../../../../Store/marketSlice/marketSlice";
import BuySellButton from "../BuySellButton/BuySellButton";
import Card from "../Card/Card";
import { PriceFilterComponent } from "./CustomFilters";
import cn from "classnames";
import styles from "./table.module.scss";
import dayjs from "dayjs";
import { parsePhoneNumber } from "libphonenumber-js";
import uuid from "react-uuid";
import { useCallback } from "react";
import { RootState } from "../../../../../../../Store/store";
import { ICultureTypesItems } from "../../interfaces/interfaces";
import { SelectedFilters } from "@consta/uikit/__internal__/src/components/Table/filtering";
import {  useNavigate } from "react-router-dom";
import { formatQuantity } from "../../../../../../../helpers/helpers";
import { FC } from "react";

export interface ITableProps {
  regionsHash: { [key: string]: string };
  culturesHash: { [key: string]: string };
}

const SellTable: FC<ITableProps> = ({ regionsHash, culturesHash }): JSX.Element => {
  const dispatch = useAppDispatch();
  const [queryFilters, setQueryFilters] = useState<string>("");
  const [categoryFilter, setCategoryFilter] = useState<string[]>([]);
  const [regionFilter, setRegionFilter] = useState<string[]>([]);
  const [priceMin, setPriceMin] = useState<string>("");
  const [priceMax, setPriceMax] = useState<string>("");
  const [quantityMin, setQuantityMin] = useState<string>("");
  const [quantityMax, setQuantityMax] = useState<string>("");
  const [pageCount, setPageCount] = useState<number>(1);
  const [shouldRequestNewPage, setShouldRequestNewPage] = useState<boolean>(false);
  const [emptyRows, setEmptyRows] = useState<number>(9);
  const [counterOffer, setCounterOffer] = useState<boolean>(false);
  const navigate = useNavigate();
  // useEffect to make async request with query params in deps

  useEffect(() => {
    dispatch(
      fetchGetBuyLotsThunk({
        ordering: queryFilters,
        price_min: priceMin,
        price_max: priceMax,
        quantity_min: quantityMin,
        quantity_max: quantityMax,
        category: categoryFilter[0] === "initial" ? [] : categoryFilter,
        region: regionFilter[0] === "initial" ? [] : regionFilter,
        page: shouldRequestNewPage ? 1 : pageCount,
        counterOffer,
      })
    );
    setShouldRequestNewPage(false);
  }, [
    dispatch,
    queryFilters,
    priceMin,
    priceMax,
    quantityMin,
    quantityMax,
    categoryFilter,
    regionFilter,
    pageCount,
    shouldRequestNewPage,
    counterOffer,
  ]);

  // useEffect to check if query params changed
  useEffect(() => {
    setPageCount(1);
    const table = tableRef.current;
    if (table !== null) {
      table.scrollTo({ top: 0 });
    }
  }, [queryFilters, priceMin, priceMax, quantityMin, quantityMax, categoryFilter]);

  // useEffect to check if Table scrolled to bottom without deps
  const tableRef = useRef<HTMLDivElement | null>(null);
  const nextPage: string = useAppSelector(
    (state: RootState) => state.market.buyLots.data.next
  );
  const prevPage: string = useAppSelector(
    (state: RootState) => state.market.buyLots.data.previous
  );

  //scrolling parts

  function debounce(func: Function, wait: number) {
    let timeout: ReturnType<typeof setTimeout>;
    return function (this: any, ...args: any[]) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(context, args);
      }, wait);
    };
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useCallback(
    debounce((event) => {
      const { scrollTop, offsetHeight, scrollHeight } = event.target;
      if (scrollTop + offsetHeight >= scrollHeight - 200 && nextPage !== null) {
        setPageCount((prev) => prev + 1);
      }
    }, 100),
    [nextPage]
  );

  useEffect(() => {
    const table = tableRef.current;
    if (table) {
      table.addEventListener("scroll", handleScroll);

      return () => {
        table.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll, nextPage]);

  const buyLotsState: any = useAppSelector((state) => state.market?.buyLots);
  const buyLotsStatus: string | null = useAppSelector(
    (state) => state.market.buyLots.status
  );

  const cultureTypesItems: ICultureTypesItems[] = Object.keys(culturesHash).map(
    (item: string) => ({
      name: culturesHash[item],
      value: culturesHash[item],
      slug: item,
    })
  );
  const regionTypesItems: any = Object.keys(regionsHash).map((item: string) => ({
    name: regionsHash[item],
    value: regionsHash[item],
    slug: item,
  }));
  const filters: TableFilters<(typeof rows)[number]> = [
    {
      id: "volume",
      name: "Объем ",
      filterer: (cellValue, filterValue) => {
        const { min, max } = filterValue as { min: string; max: string };

        if (min && max) {
          return cellValue >= min && cellValue <= max;
        } else if (min) {
          return cellValue >= min;
        } else if (max) {
          return cellValue <= max;
        }
        return true;
      },
      field: "volume",
      component: {
        name: TableNumberFilter,
      },
    },
    {
      id: "price",
      name: "Цена ",
      field: "price",
      filterer: (cellValue, filterValue) => {
        return true;
      },
      component: {
        name: PriceFilterComponent,
      },
    },
    {
      id: "type",
      name: "Тип культуры ",
      field: "type",
      filterer: (cellValue, filterValues: Array<{ value: string; name: string }>) => {
        return filterValues.some(
          (filterValue) => filterValue && filterValue.value === cellValue
        );
      },
      component: {
        name: TableTextFilter,
        props: {
          withSearch: true,
          items: cultureTypesItems,
        },
      },
    },
    {
      id: "region",
      name: "Регион ",
      field: "region",
      filterer: (cellValue, filterValues: Array<{ value: string; name: string }>) => {
        return filterValues.some(
          (filterValue) => filterValue && filterValue.value === cellValue
        );
      },
      component: {
        name: TableTextFilter,
        props: {
          withSearch: true,
          items: regionTypesItems,
        },
      },
    },
  ];

  const filtersUpdated = (filters: SelectedFilters): void => {

    const { volume, price, type, region } = filters;
    setEmptyRows(9);
    setPageCount(1);

    const setToFuncEmptyRows = (func, value) => {
      func(value);
      setEmptyRows(8);
    };
    // price logic
    price?.value?.min === undefined
      ? setPriceMin("")
      : price.value.min !== priceMin && setToFuncEmptyRows(setPriceMin, price.value.min);
    price?.value?.max === undefined
      ? setPriceMax("")
      : price.value.max !== priceMax && setToFuncEmptyRows(setPriceMax, price.value.max);
    price?.value?.withOffer === undefined
      ? setCounterOffer(false)
      : setCounterOffer(price.value.withOffer);
    // quantity logic
    volume?.value?.min === undefined
      ? setQuantityMin("")
      : volume.value.min !== quantityMin &&
        setToFuncEmptyRows(setQuantityMin, volume.value.min);
    volume?.value?.max === undefined
      ? setQuantityMax("")
      : volume.value.max !== quantityMax &&
        setToFuncEmptyRows(setQuantityMax, volume.value.max);

    // type logic
    if (type?.value !== undefined) {
      const categoryArr = type.value.map((item) => item.slug);
      setCategoryFilter([...categoryArr]);
    } else if (type?.value === undefined) {
      setCategoryFilter([]);
    }

    // region logic
    if (region?.value !== undefined) {
      const regionArr = region.value.map((item) => item.slug);
      setRegionFilter([...regionArr]);
    } else if (region?.value === undefined) {
      setRegionFilter([]);
    }
    setShouldRequestNewPage(true);
  };

  let rows = Array(9).fill({});
  const columns: TableColumn<(typeof rows)[number]>[] = [
    {
      title: "Дата",
      accessor: "date",
      align: "left",
      sortable: true,
      width: 130,
    },
    { title: "Тип культуры", accessor: "type", align: "left", width: 140 },
    {
      title: "Регион",
      accessor: "region",
      align: "left",
      width: 250,
    },
    { title: "Адрес", accessor: "address", align: "left", width: 150 },
    {
      title: "Объем, т",
      accessor: "volume",
      align: "left",
      sortable: true,
      width: 130,
    },
    {
      title: "Цена, руб.",
      accessor: "price",
      align: "left",
      sortable: true,
      width: 140,
    },

    { title: "Телефон", accessor: "phone", align: "left", width: 160 },
    { title: "Действия", accessor: "actions", align: "left", width: 120 },
  ];
  const handleSort = (props): void => {
    if (props === null) {
      setQueryFilters("");
    }
    if (props !== null && props.sortingBy === "price") {
      props.sortOrder === "asc" ? setQueryFilters("price") : setQueryFilters("-price");
    }
    if (props !== null && props.sortingBy === "volume") {
      props.sortOrder === "asc"
        ? setQueryFilters("quantity")
        : setQueryFilters("-quantity");
    }
    if (props !== null && props.sortingBy === "date") {
      props.sortOrder === "asc"
        ? setQueryFilters("created")
        : setQueryFilters("-created");
    }
  };

    const formatPhoneNumber = (phoneNumber: string): string | undefined => {
      if (!phoneNumber) {
        return "Нет телефона";
      } else {
        const parsed = parsePhoneNumber(phoneNumber, "RU");
        return parsed.formatNational().replace(/^8/, "+7");
      }
    };

  const formatPrice = (price) => {
    return parseFloat(price).toLocaleString("ru-RU", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const handleCellClick = ({ e }: any) => {
    if (e.target.value !== undefined) {
      navigate(`/lot/${e.target.value}`);
    }
  };

  if (buyLotsStatus === "resolvedGetBuyLots" || prevPage === null) {
    if (buyLotsState.data.results.length < 9) {
      const startRows = buyLotsState.data.results.map((lot: any): any => {
        return {
          id: uuid(),
          date: dayjs(lot.created).format("DD.MM в HH:mm"),
          type: culturesHash[lot.product.category],
          region: regionsHash[lot.region],
          address: lot.location,
          volume: formatQuantity(lot.quantity),
          price: formatPrice(lot.price),
          phone: formatPhoneNumber(lot.author.mobile),
          actions: (
            <BuySellButton
              key={uuid()}
              backgroundColor={
                lot.type === "buy"
                  ? "green"
                  : lot.type === "sell" && lot.counterOffer === false
                  ? "gold"
                  : "red"
              }
              value={lot.id}
            />
          ),
        };
      });

      rows = [
        ...startRows,
        ...Array(emptyRows - buyLotsState.data.results.length).fill({}),
      ];
    } else {
      rows = [
        ...buyLotsState.data.results.map((lot) => {
          return {
            id: uuid(),
            date: dayjs(lot.created).format("DD.MM в HH:mm"),
            type: culturesHash[lot.product.category],
            region: regionsHash[lot.region],
            address: lot.location,
            volume: formatQuantity(lot.quantity),
            price: formatPrice(lot.price),
            phone: formatPhoneNumber(lot.author.mobile),
            actions: (
              <BuySellButton
                key={uuid()}
                backgroundColor={
                  lot.type === "buy"
                    ? "green"
                    : lot.type === "sell" && lot.counterOffer === false
                    ? "gold"
                    : "red"
                }
                value={lot.id}
              />
            ),
          };
        }),
      ];
    }

    return (
      <Card backgroundColor="green" size="xl">
        <h3 className={styles.h3Tag}>Покупка</h3>
        <div className={cn(styles.table_wrapper, styles.green_border)}>
          <Theme preset={greenPreset}>
            <Table
              zebraStriped={emptyRows === 9 ? "even" : "odd"}
              verticalAlign="center"
              size="s"
              headerVerticalAlign="center"
              className={styles.table}
              columns={columns}
              rows={rows}
              filters={filters}
              borderBetweenColumns
              borderBetweenRows
              stickyHeader
              emptyRowsPlaceholder="По вашим фильтрам нет лотов"
              onSortBy={handleSort}
              onFiltersUpdated={filtersUpdated}
              getCellWrap={(row) => "truncate"}
              ref={tableRef}
              onRowClick={handleCellClick}
            />
          </Theme>
        </div>
      </Card>
    );
  } else {
    return <></>;
  }
};

export default SellTable;
