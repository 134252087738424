/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React from "react";
import { NavLink } from "react-router-dom";
import { changeLangToEN, changeLangToRU } from "../../Store/langSlice/langSlice";
import lkLogoHeader from "../../Svgs/mainLogoMob.svg";
import { Menu } from "antd";
import ruLogo from "../../Svgs/RU.svg";
import enLogo from "../../Svgs/EN.svg";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypesHooks";

// interface Props {
//   authStage: number,
//   setAuthStage:React.Dispatch<React.SetStateAction<number>>,
//   setSidebarActive: React.Dispatch<React.SetStateAction<boolean>>,
// }

const HeaderAuth: React.FC = () => {
  const lang = useAppSelector((state) => state.lang.lang.locale);
  const dispatch = useAppDispatch();

  const changeLangClick = (e) => {
    e.key === '1' ? dispatch(changeLangToRU()) : dispatch(changeLangToEN());
  };

  const menu = (
    <Menu
      onClick={changeLangClick}
      items={[
        {
          key: "1",
          
          label: 'ru',
          icon: <img src={ruLogo} alt="ru"/>,
        },
        {
          key: "2",
          label: 'en',
          icon: <img src={enLogo} alt="en" />,
        },
      ]}
    />
  );
  return (
    <div className="header-auth">
      <div className="container">
        <div className="header__inner">
          <NavLink to="/">
            <img className="mainlogo" src={lkLogoHeader} alt="logoCompany" />
          </NavLink>
          <nav className="menu">
            <ul className="menu__list">
              {/* <Dropdown overlay={menu}>
                <Button style={{border: 'none'}}>
                  <Space>
                    <img src={lang === 'ru-RU' ? ruLogo : enLogo} alt="ru" />
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown> */}
              {/* <li className="menu__item" onClick={() => setAuthStage(2)}>
                Log in
              </li> */}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default HeaderAuth;
