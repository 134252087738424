import { AxiosResponse } from "axios";
import $api from "../http";
import { CurrentCategory } from "../Store/catalogSlice/interfaces";



export default class CatalogService{
  static async getCategories(): Promise<AxiosResponse<CurrentCategory[]>> {
    return $api.get<CurrentCategory[] | any>(`/api/v1/catalog/categories/`);
  }
  static async putUsersMe(date) {
    return $api.patch(`/api/v1/users/me/`, date);
  }
  static async usersMeCountry() {
    // return $api.options(`/api/v1/users/me/`);
    return $api.options(`/api/v1/contractors/company/my/`);
  }
}