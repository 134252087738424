import { Breadcrumb, PageHeader } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import InformerForm from "../../Components/InformerPageComponents/InformerForm";

const InformerPage = () => {
  return (
    <div className="fullbox__main">
      <PageHeader
        className="site-page-header1"
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/" style={{ color: "gray" }}>
                Главная
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Информер</Breadcrumb.Item>
          </Breadcrumb>
        }
      />

      <InformerForm />
    </div>
  );
};

export default InformerPage;
