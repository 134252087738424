/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Form, Input, Button, Select, Checkbox, Spin, notification } from "antd";
import { getContryThunk } from "../../../Store/userSlice/userSlice";
import { fetchAllRegionsThunk } from "../../../Store/contractorsSlice/contractorsSlice";
import {
  addCurrentCategory,
  fetchGetCategoriesThunk,
} from "../../../Store/catalogSlice/catalogSlice";
import { useEffect } from "react";
import {
  changeStatusLot2To,
  changeStatusLotTo,
  fetchCreateLotThunk,
  fetchGetIncoTermsThunk,
} from "../../../Store/marketSlice/marketSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxTypesHooks";
import { CurrentIncoTerms, RequestLot } from "../../../Store/marketSlice/interfaces";
import { CurrType } from "../../../Store/contractorsSlice/interfaces";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import Tooltip from "antd/es/tooltip";
import infoCirleIcon from "../../../Svgs/info-circleNewLotPage.svg";
import { RootState } from "../../../Store/store";
const { TextArea } = Input;

const countries = [
  {
    label: "Россия",
    value: "RU",
  },
];

const options = [
  {
    label: "1%",
    value: "1",
  },
  {
    label: "2%",
    value: "2",
  },
  {
    label: "3%",
    value: "3",
  },
  {
    label: "4%",
    value: "4",
  },
  {
    label: "5%",
    value: "5",
  },
  {
    label: "6%",
    value: "6",
  },
  {
    label: "7%",
    value: "7",
  },
  {
    label: "8%",
    value: "8",
  },
  {
    label: "9%",
    value: "9",
  },
  {
    label: "10%",
    value: "10",
  },
];
const oplatas = [
  {
    label: "По факту доставки",
    value: "По факту доставки",
  },
  {
    label: "Предоплата",
    value: "Предоплата",
  },
];

const BuyForm: React.FC = (): JSX.Element => {
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const contractors = useAppSelector((state: RootState) => state.contractors);
  const { AllRegionsDate, myCompanyDate } = contractors;
  const { allRegions, statusRegions } = AllRegionsDate;
  const allRegions2:
    | { label: string | Element | JSX.Element; value: string }[]
    | CurrType[] = allRegions;
  const { categories } = useAppSelector((state: RootState) => state.catalog);
  const { allCategories, statusCategories, currentCategory } = categories;

  const { incoTerms, lot } = useAppSelector((state: RootState) => state.market);
  const { allIncoTerms, statusIncoTerms } = incoTerms;
  const allIncoTerms2:
    | { label: string | Element | JSX.Element | undefined; value: string }[]
    | CurrentIncoTerms[] = allIncoTerms;
  const price = Form.useWatch("price", form);
  const quantity = Form.useWatch("quantity", form);
  // const ndsRate = Form.useWatch('ndsRate', form);
  const category = Form.useWatch("category", form);
  const gost = Form.useWatch("gost", form);
  useEffect(() => {
    if (lot.statusLot2 === "resolvedCreateLot") {
      notification.open({
        message: "Лот успешно создан",
      });
      navigate("/");
    } else if (lot.statusLot2 === "rejectedCreateLot") {
      notification.open({
        message: "Проверьте, пожалуйста, заполнили ли все поля",
      });
    }
    dispatch(changeStatusLot2To(null));
  }, [lot.statusLot2]);

  useEffect(() => {
    if (price && quantity) {
      const fullPr = +price * +quantity;
      form.setFieldsValue({
        fullPrice: fullPr,
      });
    }
  }, [price, quantity]);

  useEffect(() => {
    category &&
      dispatch(
        addCurrentCategory(
          allCategories.find((el) => el.slug === form.getFieldValue("category"))
        )
      );
  }, [category]);
  // useEffect for categoryClass select

  const onFinishForm = (values: any) => {
      console.log("Success:", { ...values });
      console.log("В БЭКЕ НЕОБХОДИМО ОТКЛЮЧИТЬ ПРОВЕРКУ НА АТРИБУТЫ КУЛЬТУРЫ ТОЛЬКО ЕСЛИ ТИП ЛОТА == 'buy'. Ровно поэтому не проходит запрос к серверу без gost = true ")
    const dataForFetch: RequestLot = {
      status: "published",
      quantity: values.quantity,
      price: values.price,
      incoterms: values.deliveryTerms,
      type: "buy",
      country: values.country,
      region: JSON.parse(values.region).region,
      paymentTerms: values.payForm.value,
      improvement: values.gost ? false : !!values.podrabotka,
      product: {
        year: values.year,
        categoryClass: values.class,
        comments: values.comment || "",
        manufacturer: myCompanyDate.MyComp?.name,
        // "files": [
        //   {
        //     "file": {
        //       "name": "string"
        //     },
        //     "fileType": 0
        //   }
        // ],
        attributeValues: {
          // "additionalProp3": "string"
        },
        category: values.category,
        gostStandard: Boolean(values.gost),
      },
      ndsRate: values.ndsRate ? 10 : 0,
      location: values.address,
    };
    console.log("data", dataForFetch);
    dispatch(fetchCreateLotThunk(dataForFetch));
  };

  const onFinishFailedForm = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    dispatch(changeStatusLotTo("rejectedCreateLot"));
  };
  useEffect(() => {
    if (gost) {
      form.setFieldValue("class", null);
    }
    if (
      !allCategories
        .find((el) => el!.slug === category)
        ?.attributes!.find((el) => el.slug === "class")
    ) {
      form.setFieldValue("class", null);
    }
  }, [gost, category]);
  return (
    <>
      <Form
        onFinish={onFinishForm}
        onFinishFailed={onFinishFailedForm}
        form={form}
        layout={width! <= 1087 ? "vertical" : "horizontal"}
        initialValues={{
          year: 2023,
          option: options[1],
          payForm: oplatas[0],
        }}
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        // onValuesChange={onFormLayoutChange}
      >
        <div className="form-sell">
          <Form.Item
            className="input-wrapper l1"
            name="category"
            label="Культура"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            {/* {
              statusCategories !== 'loading' ?
                <Select options={allCategories}  placeholder="Арахис" />
                :
                <Select options={[{ label: <Spin />, value: 1 }]}  placeholder="Арахис" />

            } */}
            <Select
              onClick={
                statusCategories !== "resolvedCategories"
                  ? () => dispatch(fetchGetCategoriesThunk())
                  : undefined
              }
              options={statusCategories !== "loading" ? allCategories : undefined}
              placeholder="Ячмень"
            />
          </Form.Item>
          <div className="two-el">
            <Form.Item
              className="input-wrapper two-el__children"
              label="Год урожая"
              name="year"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: "",
                  pattern: new RegExp(/^[0-9]+$/),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              labelAlign="left"
              className="input-wrapper two-el__children"
              name="class"
              dependencies={["category"]}
              label="Класс/вид"
              rules={
                allCategories
                  .find((el) => el!.slug === category)
                  ?.attributes!.find((el) => el.slug === "class") && !gost
                  ? [
                      {
                        required: true,
                        message: "",
                      },
                    ]
                  : undefined
              }
              // rules={[
              //   (allCategories
              //     .find(el => el.slug === category)?.attributes
              //     .find(el => el.slug === 'class')
              //     ?
              //     {
              //       required: true,
              //       message: '',
              //     }
              //     :
              //     null
              //   )
              // ]}
            >
              <Select
                options={[
                  { label: "1", value: 1 },
                  { label: "2", value: 2 },
                  { label: "3", value: 3 },
                  { label: "4", value: 4 },
                  { label: "5", value: 5 },
                ]}
                disabled={
                  currentCategory?.attributes &&
                  currentCategory?.attributes.filter((el) => el.slug === "class")[0]
                    ? false
                    : true
                }
              />
            </Form.Item>
          </div>
          <div className="two-el">
            <Form.Item
              className="input-wrapper two-el__children"
              label="Объем, т"
              name="quantity"
              style={{ marginRight: "353px" }}
              rules={[
                {
                  required: true,
                  message: "",
                  pattern: new RegExp(/^[0-9]+$/),
                },
                {
                  message: "Не может быть < 10т",
                  validator: (_, value) => {
                    if (+value >= 10) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("не валидный ИНН");
                    }
                  },
                },
              ]}
            >
              <Input placeholder="т" />
            </Form.Item>
          </div>
          <div className="two-el nds">
            <div className="input-wrapper two-el__children">
              <Form.Item
                label="Цена руб./т"
                name="price"
                rules={[
                  {
                    required: true,
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                  {
                    message: "От 7000 до 25000руб/т",
                    validator: (_, value) => {
                      if (+value >= 7000 && +value <= 25000) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("не валидный ИНН");
                      }
                    },
                  },
                ]}
              >
                <Input className="inputt" />
              </Form.Item>
              <Form.Item name="ndsRate" valuePropName="checked">
                <Checkbox className="check">с НДС</Checkbox>
              </Form.Item>
            </div>

            <Form.Item
              className="input-wrapper two-el__children"
              style={{}}
              label="Оплата"
              name="payForm"
            >
              <Select className="inputt" options={oplatas} />
            </Form.Item>
          </div>
          <div className="two-el">
            <Form.Item
              className="input-wrapper two-el__children"
              label="Страна"
              name="country"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Select
                className="inputt"
                onClick={() => dispatch(getContryThunk())}
                style={{ width: "173px" }}
                options={countries}
                placeholder="Выберите"
              />
            </Form.Item>
            <Form.Item
              className="input-wrapper two-el__children "
              label="Регион"
              name="region"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Select
                className="inputt"
                onClick={
                  statusRegions !== "resolved"
                    ? () => dispatch(fetchAllRegionsThunk())
                    : undefined
                }
                options={
                  statusRegions === "loading"
                    ? [{ label: <Spin />, value: "1" }]
                    : allRegions2
                }
                placeholder="Выберите"
              />
            </Form.Item>
          </div>
          <Form.Item
            className="input-wrapper l1 adrress"
            name="address"
            label="Адрес доставки"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Input className="input-wrapper l1 " />
          </Form.Item>
          <Form.Item
            className="input-wrapper l1 comment"
            label="Комментарий"
            name="comment"
          >
            <TextArea
              placeholder="Опишите подробнее место доставки в случае необходимости"
              rows={2}
            />
          </Form.Item>
          <Form.Item
            label="Условия доставки"
            name="deliveryTerms"
            className="input-wrapper l1"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Select
              className="inputt"
              onClick={
                statusIncoTerms !== "resolvedIncoTerms"
                  ? () => dispatch(fetchGetIncoTermsThunk())
                  : undefined
              }
              options={
                statusIncoTerms === "loading"
                  ? [{ label: <Spin />, value: "1" }]
                  : allIncoTerms2
              }
              placeholder="Выберите"
            />
          </Form.Item>
          <div className="parametres">
            <div className="par">Параметры</div>
            <Form.Item className="input-wrapper  pp" name="gost" valuePropName="checked">
              <Checkbox checked>ГОСТ 2616-7616</Checkbox>
            </Form.Item>
          </div>
          <Form.Item
            className="input-wrapper podrabotka"
            name="podrabotka"
            valuePropName="checked"
          >
            {!gost && (
              <Checkbox disabled={gost}>
                Возможна подработка на территории производителя
                <Tooltip
                  title="В случае несоответстия заявленному качеству производитель в состоянии провести сушки или очистку от сорной примеси на своей территории"
                  placement="rightTop"
                  color="#95D708"
                >
                  <img style={{ paddingLeft: "4px" }} src={infoCirleIcon} alt="info" />
                </Tooltip>
              </Checkbox>
            )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" size="large" className="submit-all">
              Опубликовать
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default BuyForm;

/*  removed until option is released
<Button className="surveer" disabled>
            Сюрвейерские услуги
          </Button>
          */
