import { Button, Select } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypesHooks";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { changeLangToEN, changeLangToRU } from "../../Store/langSlice/langSlice";
import { addPropertiesUser, getContryThunk } from "../../Store/userSlice/userSlice";
import mainLogoMob from "../../Svgs/mainLogoMob.svg";

const areas = [
  {
    label: "Russian",
    value: "ru-RU",
  },
  {
    label: "English",
    value: "en-EN",
  },
];
const countryArr = [
  {
    label: "Russia",
    value: "RU",
  },
  {
    label: "Germany",
    value: "Germany",
  },
];
interface Props {
  setAuthStage: React.Dispatch<React.SetStateAction<number>>;
}

const LanguageBlock: React.FC<Props> = ({ setAuthStage }) => {
  const dispatch = useAppDispatch();
  const [currlang, setCurrLang] = useState("ru-RU");
  const [currCountry, setCurrCountry] = useState("Russia");
  const { countries } = useAppSelector((state) => state.user);
  const { width } = useWindowDimensions();
  useEffect(() => {
    dispatch(getContryThunk());
  }, [dispatch]);

  const addLangToUserHandler = () => {
    dispatch(addPropertiesUser({ country: currCountry, lang: currlang }));
    currlang === "ru-RU" ? dispatch(changeLangToRU()) : dispatch(changeLangToEN());
    setAuthStage(2);
  };
  return (
    <>
      <div className="authblock__top">
        <img src={mainLogoMob} alt="CompanyLogo" className="authblock__main-logo" />
        <h4 className="authblock__title">Выберите страну</h4>
        <p className="authblock__input-title" style={{ display: "none" }}>
          Language
        </p>
        <Select
          className="authblock__language"
          onChange={(e) => setCurrLang(e)}
          options={areas}
          defaultValue={areas[0].value}
          style={{ display: "none", textAlign: "start" }}
        />
        <p className="authblock__input-title" style={{ marginTop: "40px" }}>
          Страна
        </p>
        <Select
          className="authblock__language"
          onChange={(e) => setCurrCountry(e)}
          options={countries}
          defaultValue={countryArr[0].value}
        />
      </div>
      <div>
        <Button className="authblock__submit-btn" onClick={() => addLangToUserHandler()}>
          Продолжить
        </Button>
        <div style={{marginTop: "20px", marginBottom: "-15px"}}>Демо-версия</div>
      </div>
    </>
  );
};

export default LanguageBlock;
