import { ConsoleSqlOutlined } from "@ant-design/icons";
import React from "react";
import { formatPhoneNumber } from "../../helpers/helpers";
import { useAppSelector } from "../../hooks/reduxTypesHooks";
import { RootState } from "../../Store/store";
import rightbarLogo from "../../Svgs/avatar4040.svg";
import { companyTypes } from '../MyOffersPageComponents/modal/Modal';
import "./currentPageRighbar.scss";
interface Props {
  currentBid?: any;
  layout: string;
}

const CurrentPageRighbar: React.FC<Props> = ({ layout, currentBid }): JSX.Element => {
  const regionsData = useAppSelector((state: RootState) => state.shipping.regions.data);
  const regionsStatus = useAppSelector(
    (state: RootState) => state.shipping.regions.statusRegions
  );

  const getRegionName = (code) => {
    if (regionsStatus === "resolvedGetRegions" && code !== undefined) {
      const region = regionsData.find((region) => region.codes.includes(code.toString()));

      return region ? region.name : code;
    } else {
      return code;
    }
  };
  return (
    <div
      className={
        layout === "vertical"
          ? "current-rightbar-vertical"
          : "current-rightbar-horizontal"
      }
    >
      <img className="logo" src={rightbarLogo} alt="Avatar" />
      <div className="title">
        <h3 className="title__about">Данные об организации</h3>
        <p className="title__tag">
          Название: <span className="title__subtag">{currentBid.company.name}</span>
        </p>
        <p className="title__tag">
          Деятельность:{" "}
          <span className="title__subtag">{companyTypes[currentBid.company.type]}</span>
        </p>
        <p className="title__tag">
          Расположение:{" "}
          <span className="title__subtag">
            {getRegionName(currentBid.company.region)}
          </span>
        </p>
        <p className="title__tag">
          Телефон:{" "}
          <span className="title__subtag">
            {formatPhoneNumber(currentBid.company.phone)}
          </span>
        </p>
      </div>
      <div className="title">
        <h3 className="title__about">Представители организации:</h3>
        <p className="title__tag">
          ФИО: <span className="title__subtag">{currentBid.user.fullName}</span>
        </p>
        <p className="title__tag">
          Телефон:{" "}
          <span className="title__subtag">
            {formatPhoneNumber(currentBid.user.mobile)}
          </span>
        </p>
      </div>
    </div>
  );
};

export default CurrentPageRighbar;
