import { Table, TableColumn } from "@consta/uikit/Table";
import { Theme } from "@consta/uikit/Theme";
import React, { useState } from "react";
import { greenPreset } from "../../../ui-presets/presetsTheme/greenPreset";
import styles from "./offerTables.module.scss";
import cn from "classnames";
import Card from "../../MainPageComponents/MainContent/Middle/MiddleCenter/components/Card/Card";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxTypesHooks";
import { fetchGetMyOfferLotsThunk } from "../../../Store/marketSlice/marketSlice";
import { RootState } from "../../../Store/store";
import dayjs from "dayjs";
import CategoryService from "../../../services/CategoriesService";
import RegionsService from "../../../services/RegionsService";
import { formatPrice, formatQuantity } from "../../../helpers/helpers";

export interface IAllOffersTable {
  tableType: "sell" | "buy";
  setCurrentLotId: Function;
  setTableActive: Function;
  companyId: number;
  getLotsData: Function;
}

const AllOffersTable = ({
  tableType,
  setCurrentLotId,
  setCurrentLotNumber,
  setTableActive,
  companyId,
  getLotsData,
}) => {
  const [emptyRows, setEmptyRows] = useState<number>(9);
  const [cultures, setCultures] = useState<any>();
  const [regions, setRegions] = useState<any>();
  const dispatch = useAppDispatch();

  let type;
  let title =
    tableType === "sell" ? "Мои аукционы на продажу" : "Мои аукционы на покупку";
  const handleRowClick = async (id) => {
    if (id) {
      setCurrentLotId(id);
      getLotsData(id);
      setTableActive(true);
    }
  };

  const myOfferLotsState: any = useAppSelector((state) => state.market?.myOfferLots);
  const myOfferLotsStatus = useAppSelector(
    (state: RootState) => state.market.myOfferLots.status
  );
  const prevPage = useAppSelector(
    (state: RootState) => state.market.myOfferLots.prevPage
  );
  let rows = Array(9).fill({});

  useEffect(() => {
    type = tableType === "sell" ? "sell" : "buy";
    dispatch(
      fetchGetMyOfferLotsThunk({
        company: companyId.id,
        type,
        counterOffer: false,
        ordering: "-created",
      })
    );
    setTableActive(false);
  }, [tableType]);

  const columns: TableColumn<(typeof rows)[number]>[] = [
    {
      title: "№ Предложения",
      accessor: "offerId",
      align: "left",
      width: 175,
    },
    {
      title: "Дата",
      accessor: "date",
      align: "left",

      width: 175,
    },
    { title: "Тип культуры", accessor: "type", align: "left", width: 180 },

    { title: "Адрес", accessor: "address", align: "left", width: 350 },
    {
      title: "Объем, т",
      accessor: "volume",
      align: "left",

      width: 130,
    },

    {
      title: "Цена, руб.",
      accessor: "price",
      align: "left",

      width: 120,
    },
  ];

  useEffect(() => {
    getFilterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getFilterData() {
    const res = await Promise.all([
      CategoryService.getCategories(),
      RegionsService.getRegions(),
    ]);

    setCultures(convertCutures(res[0].data));
    setRegions(convertRegions(res[1].data));
  }

  function convertCutures(getCategoriesResponse) {
    const culturesHash = {};
    getCategoriesResponse.forEach((category: any) => {
      culturesHash[category.slug] = category.title;
    });
    return culturesHash;
  }

  function convertRegions(getRegionsResponse) {
    const regionsHash = {};
    getRegionsResponse.forEach((region) => {
      regionsHash[region.isoCode] = region.name;
    });
    return regionsHash;
  }
  if (myOfferLotsStatus === "resolvedGetMyOfferLots" || prevPage === null) {
    if (myOfferLotsState.data.results.length < 9) {
      const startRows = myOfferLotsState.data.results.map((lot: any): any => {
        return {
          id: lot.id,
          offerId: lot.number,
          date:
            lot.type === "sell"
              ? `от ${dayjs(lot.dateStart).format("DD.MM")} до ${dayjs(
                  lot.dateFinish
                ).format("DD.MM")}`
              : `${dayjs(lot.created).format("DD.MM в HH:MM")}`,
          type:
            `${cultures ? cultures[lot.product.category] : ""} ` +
            (lot.product.categoryClass ? `${lot.product.categoryClass} класс` : ""),
          address: lot.location,
          volume: formatQuantity(lot.quantity),
          price: formatPrice(lot.price),
          remainedVolume: formatQuantity(lot.quantity),
        };
      });

      rows = [
        ...startRows,
        ...Array(emptyRows - myOfferLotsState.data.results.length).fill({}),
      ];
    } else {
      rows = [
        ...myOfferLotsState.data.results.map((lot) => {
          return {
            id: lot.id,
            offerId: lot.number,
            date:
              lot.type === "sell"
                ? `от ${dayjs(lot.dateStart).format("DD.MM")} до ${dayjs(
                    lot.dateFinish
                  ).format("DD.MM")}`
                : `${dayjs(lot.created).format("DD.MM в HH:MM")}`,
            type:
              `${cultures ? cultures[lot.product.category] : ""} ` +
              (lot.product.categoryClass ? `${lot.product.categoryClass} класс` : ""),
            address: lot.location,
            volume: formatQuantity(lot.quantity),
            price: formatPrice(lot.price),
            remainedVolume: formatQuantity(lot.quantity),
          };
        }),
      ];
    }

    return (
      <div className={styles.wrapper}>
        <Card backgroundColor="white" size="xl">
          <h3 className={styles.h3Tag}>{title}</h3>
          <div className={cn(styles.table_wrapper, styles.green_border)}>
            <Theme preset={greenPreset}>
              <Table
                key={tableType}
                zebraStriped={"odd"}
                verticalAlign="center"
                size="s"
                headerVerticalAlign="center"
                className={styles.table}
                columns={columns}
                rows={rows}
                borderBetweenColumns
                borderBetweenRows
                stickyHeader
                emptyRowsPlaceholder="По вашим фильтрам нет лотов"
                getCellWrap={(row) => "truncate"}
                onRowClick={({ id }) => {
                  handleRowClick(id);
                }}
              />
            </Theme>
          </div>
        </Card>
      </div>
    );
  } else {
    return <></>;
  }
};

export default AllOffersTable;
