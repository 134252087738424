import { Button, Form, Input, notification, Select } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypesHooks";

import {
  addCurrentCategory,
  fetchGetCategoriesThunk,
} from "../../Store/catalogSlice/catalogSlice";
import styles from "./informerForm.module.scss";
import cn from "classnames";
import RegionsService from "../../services/RegionsService";
import {
  changeSetStatus,
  fetchGetInformerThunk,
  fetchSetInformerThunk,
} from "../../Store/informerSlice/informerSlice";
import { RootState } from "../../Store/store";
import InformerCard from "./InformerCard";
import uuid from "react-uuid";
import "./informer.css";

const InformerForm: React.FC = (): JSX.Element => {
  const [value, setValue] = useState([]);
  const [informerKey, setInformerKey] = useState<string>(uuid());
  const [regions, setRegions] = useState<any>("");

  const { categories } = useAppSelector((state: RootState) => state.catalog);
  const { allCategories, statusCategories, currentCategory } = categories;
  const statusPostInformer = useAppSelector(
    (state: RootState) => state.informer.setInformer.statusInformer
  );

  const getInformerData = useAppSelector(
    (state: RootState) => state.informer.getInformer.data
  );

  // get informer logic

  useEffect(() => {
    dispatch(fetchGetInformerThunk());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set informer

  const [form] = Form.useForm();

  const category = Form.useWatch("category", form);
  useEffect(() => {
    getRegions();
  }, []);

  async function getRegions() {
    const res = await RegionsService.getRegions();

    setRegions(res.data);
  }
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (statusPostInformer === "resolvedSetInformer") {
      notification.open({ message: "Информер успешно создан" });
      dispatch(changeSetStatus(null));
      dispatch(fetchGetInformerThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusPostInformer]);

  const onFinishForm = (values: any) => {
    const data = {
      category: values.category,
      categoryClass: values.class ? values.class : null,
      lotType: values.type,
      priceFrom: values.priceFrom,
      priceTo: values.priceTo,
      regions: values.region,
      notifyTypes: ["email"],
    };

    dispatch(fetchSetInformerThunk(data));
    form.setFieldsValue({
      category: "",
      class: "",
      type: "",
      priceFrom: null,
      priceTo: null,
      region: [],
    });
    setValue([]);
  };

  const onFinishFailedForm = (errorInfo) => {
    console.log("Failed:", errorInfo);
    notification.open({ message: "Произошла ошибка, попробуйте еще раз" });
  };

  const convertRegions = () => {
    return regions.map((el) => {
      return { label: el.name, value: el.isoCode };
    });
  };

  useEffect(() => {
    category &&
      dispatch(
        addCurrentCategory(
          allCategories.find((el) => el.slug === form.getFieldValue("category"))
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  return (
    <>
      <div className={styles.informerBlock}>
        <p>
          Информер позволяет оперативно получать информацию о встречных заявках, используя
          выставленные Вами фильтры, на электронную почту или СМС
        </p>
        <Form
          onFinish={onFinishForm}
          onFinishFailed={onFinishFailedForm}
          form={form}
          style={{ marginTop: "20px" }}
        >
          <div className={styles.informerBlock_twoEl}>
            <Form.Item
              className={styles.informerBlock_twoEl__element}
              label="Культура:"
              name="category"
              rules={[
                {
                  required: true,
                  message: "Выберите категорию",
                },
              ]}
            >
              <Select
                className={styles.informerBlock_twoEl__element__select}
                onClick={
                  statusCategories !== "resolvedCategories"
                    ? () => dispatch(fetchGetCategoriesThunk())
                    : undefined
                }
                options={statusCategories !== "loading" ? allCategories : undefined}
                placeholder="Ячмень"
                allowClear
              />
            </Form.Item>

            <Form.Item
              className={styles.informerBlock_twoEl__element}
              name="class"
              dependencies={["category"]}
              label={"Класс/вид:"}
              rules={
                currentCategory?.attributes &&
                currentCategory?.attributes.filter((el) => el.slug === "class")[0]
                  ? [
                      {
                        required: true,
                        message: "Выберите класс",
                      },
                    ]
                  : undefined
              }
            >
              <Select
                className={styles.informerBlock_twoEl__element__select}
                options={[
                  { label: "1", value: 1 },
                  { label: "2", value: 2 },
                  { label: "3", value: 3 },
                  { label: "4", value: 4 },
                  { label: "5", value: 5 },
                ]}
                allowClear
                disabled={
                  currentCategory?.attributes &&
                  currentCategory?.attributes.filter((el) => el.slug === "class")[0]
                    ? false
                    : true
                }
              />
            </Form.Item>
          </div>

          <div className={styles.informerBlock_twoEl}>
            <Form.Item
              name="priceFrom"
              className={styles.informerBlock_twoEl__element}
              style={{ width: "100%" }}
              label="Цена от, ₽/т"
              rules={[
                {
                  message: "",
                  pattern: new RegExp(/^[0-9]+$/),
                },
                { required: true, message: "Введите стоимость" },
                {
                  message: "От 7000 до 25000руб/т",
                  validator: (_, value) => {
                    if (+value >= 7000 && +value <= 25000) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("не валидный ИНН");
                    }
                  },
                },
              ]}
            >
              <Input className={styles.informerBlock_twoEl__element__input} />
            </Form.Item>
            <Form.Item
              name="priceTo"
              className={styles.informerBlock_twoEl__element}
              style={{ width: "100%" }}
              label="Цена до, ₽/т"
              rules={[
                {
                  message: "",
                  pattern: new RegExp(/^[0-9]+$/),
                },
                { required: true, message: "Введите стоимость" },
                {
                  message: "От 7000 до 25000руб/т",
                  validator: (_, value) => {
                    if (+value >= 7000 && +value <= 25000) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("не валидный ИНН");
                    }
                  },
                },
              ]}
            >
              <Input
                className={cn(
                  styles.informerBlock_twoEl__element__input,
                  styles.inputMargin
                )}
              />
            </Form.Item>
          </div>
          <div className={styles.informerBlock_checkboxes}>
            <label style={{ display: "flex", flexDirection: "column" }}>
              Расположение лота :{" "}
              <Form.Item
                name="region"
                rules={[{ required: true, message: "Выберите регион" }]}
              >
                <Select
                  style={{ width: "100%", maxWidth: "630px", marginTop: "8px" }}
                  mode="multiple"
                  options={regions ? convertRegions() : ""}
                  placeholder={"Выберите регион"}
                  maxTagCount={"responsive"}
                  allowClear
                />
              </Form.Item>
            </label>
          </div>
          <div className={styles.informerBlock_notifictionBlock}>
            <label className={styles.informerBlock_notificationBlock__label}>
              Уведомления :{" "}
              <Form.Item
                className={styles.informerBlock_notificationBlock__label__el}
                style={{ marginLeft: "10px" }}
                name="email"
              >
                <Checkbox className="check" checked>
                  E-mail
                </Checkbox>
              </Form.Item>
              <Form.Item
                className={styles.informerBlock_notificationBlock__label__el}
                name="sms"
              >
                <Checkbox className="check" disabled>
                  SMS
                </Checkbox>
              </Form.Item>
            </label>
          </div>
          <div className={styles.informerBlock_twoEl}>
            <Form.Item
              className={styles.informerBlock_twoEl__element}
              name="type"
              label={"Тип лота: "}
              rules={[
                {
                  required: true,
                  message: "Выберите тип    ",
                },
              ]}
            >
              <Select
                className={styles.informerBlock_twoEl__element__select}
                value={value}
                options={[
                  { label: "Продажа", value: "sell" },
                  { label: "Покупка", value: "buy" },
                ]}
                placeholder={"Выберите тип"}
              />
            </Form.Item>
          </div>

          <Button type="primary" htmlType="submit" size="large" className="submit-all">
            Сохранить
          </Button>
        </Form>
      </div>
      {getInformerData.length ? (
        <div
          className={styles.informerCards}
          style={{ display: "flex", flexDirection: "column" }}
          key={informerKey}
        >
          <h1 className={styles.hTag}>Мои информеры </h1>
          {getInformerData.map((el) => (
            <InformerCard
              key={uuid()}
              informerInfo={el}
              setInformerKey={setInformerKey}
              regionsData={regions}
            />
          ))}
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default InformerForm;
