import React, { useState } from "react";
import { Button } from "@consta/uikit/Button";
import { Theme } from '@consta/uikit/Theme';
import { whitePreset } from '../../../ui-presets/presetsTheme/whitePreset';
const ApproveButton = () => {
  const [clicked, setClicked] = useState<boolean>(false);
    return (
      <Theme preset={whitePreset}>
        <Button
          form="round"
          view={clicked ? "ghost" : "primary"}
          label={clicked ? "Отменить" : "Принять"}
          onClick={() => setClicked(!clicked)}
          size="xs"
          style={{ minWidth: "90px" }}
        ></Button>
      </Theme>
    );
};

export default ApproveButton;
