/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CategoryService from "../../../services/CategoriesService";
import {
  Form,
  Input,
  Button,
  Select,
  Checkbox,
  Spin,
  Tooltip,
  DatePicker,
  notification,
  Upload,
  UploadFile,
  message,
} from "antd";
import infoCirleIcon from "../../../Svgs/info-circleNewLotPage.svg";
import { getContryThunk } from "../../../Store/userSlice/userSlice";
import { fetchAllRegionsThunk } from "../../../Store/contractorsSlice/contractorsSlice";
import {
  addCurrentCategory,
  fetchGetCategoriesThunk,
} from "../../../Store/catalogSlice/catalogSlice";
import {
  changeStatusLotTo,
  fetchCreateLotThunk,
  fetchGetIncoTermsThunk,
} from "../../../Store/marketSlice/marketSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxTypesHooks";
import { CurrentIncoTerms } from "../../../Store/marketSlice/interfaces";
import { CurrType } from "../../../Store/contractorsSlice/interfaces";
import { PlusOutlined } from "@ant-design/icons";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import locale from "antd/lib/date-picker/locale/ru_RU";
import "moment/locale/ru";
import moment from "moment";
import ContractorsService from "../../../services/ContractorsService";
import { RootState } from "../../../Store/store";
import { $apiUpload } from "../../../http";

const { TextArea } = Input;

async function getCategories() {
  const res = await Promise.all([
    CategoryService.getCategories(),
    ContractorsService.getCompanyMy(),
  ]);
  console.log(res);
}

const countries = [
  {
    label: "Россия",
    value: "RU",
  },
];

const options = [
  {
    label: "1%",
    value: "1",
  },
  {
    label: "2%",
    value: "2",
  },
  {
    label: "3%",
    value: "3",
  },
  {
    label: "4%",
    value: "4",
  },
  {
    label: "5%",
    value: "5",
  },
  {
    label: "6%",
    value: "6",
  },
  {
    label: "7%",
    value: "7",
  },
  {
    label: "8%",
    value: "8",
  },
  {
    label: "9%",
    value: "9",
  },
  {
    label: "10%",
    value: "10",
  },
];
const oplatas = [
  {
    label: "По факту доставки",
    value: "По факту доставки",
  },
  {
    label: "Предоплата",
    value: "Предоплата",
  },
];

const onlyNumbers = /^[0-9]+$/;

const SellForm: React.FC = () => {
  // for upload docs
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  useEffect(() => {
    getCategories();
  }, []);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  const contractors = useAppSelector((state: RootState) => state.contractors);
  const { AllRegionsDate } = contractors;
  const { allRegions, statusRegions } = AllRegionsDate;
  const allRegions2:
    | { label: string | Element | JSX.Element; value: string }[]
    | CurrType[] = allRegions;

  const { categories } = useAppSelector((state: RootState) => state.catalog);
  const { allCategories, statusCategories, currentCategory } = categories;

  const { incoTerms, lot } = useAppSelector((state: RootState) => state.market);
  const { allIncoTerms, statusIncoTerms } = incoTerms;
  const allIncoTerms2:
    | { label: string | Element | JSX.Element | undefined; value: string }[]
    | CurrentIncoTerms[] = allIncoTerms;

  let price = Form.useWatch("price", form);
  let quantity = Form.useWatch("quantity", form);
  let loadingCost = Form.useWatch("loadingCost", form);
  // const ndsRate = Form.useWatch('ndsRate', form);
  const category = Form.useWatch("category", form);
  const gost = Form.useWatch("gost", form);

  const formatPrice = (price) => {
    return parseFloat(price).toLocaleString("ru-RU", {});
  };

  useEffect(() => {
    console.log("=========", lot.statusLot);

    if (lot.statusLot === "resolvedCreateLot") {
      notification.open({
        message: "Лот успешно создан",
      });
      navigate("/");
    } else if (lot.statusLot === "rejectedCreateLot") {
      notification.open({
        message: "Проверьте, пожалуйста, заполнили ли все поля",
      });
    }
    dispatch(changeStatusLotTo(null));
  }, [lot.statusLot, dispatch, navigate]);

  useEffect(() => {
    if (price && quantity) {
      console.log(price, quantity);
      console.log(allIncoTerms);
      const fullLoadCost =
        loadingCost && Number.isInteger(+loadingCost) ? +quantity * +loadingCost : 0;
      const fullPr = +price * +quantity + fullLoadCost;
      form.setFieldsValue({
        fullPrice: formatPrice(fullPr),
      });
    }
  }, [price, quantity, loadingCost]);
  useEffect(() => {
    category &&
      dispatch(
        addCurrentCategory(
          allCategories.find((el) => el.slug === form.getFieldValue("category"))
        )
      );
  }, [category]);

  const onFinishForm = (values) => {
    console.log("Success:", {
      ...values,
    });

    /* 
      for upload

      const formData = new FormData();;
      fileList.forEach((file) => {
        formData.append("files", file.originFileObj);
      });

     */
    // const additionalProps = currentCategory.attributes.map(el => (obj[el.slug] = {[el.slug]: values[el.slug]}))

    const additionalProps = currentCategory.attributes!.reduce(
      (obj, item) => Object.assign(obj, { [item.slug]: values[item.slug] }),
      {}
    );

    console.log("============", additionalProps);

    const dataForFetch: any = {
      status: "published",
      quantity: values.quantity,
      price: values.price,
      incoterms: values.deliveryTerms,
      type: "sell",
      country: values.country,
      region: JSON.parse(values.region).region,
      paymentTerms: values.payForm.value,
      improvement: values.gost ? false : !!values.podrabotka,
      option: values.option.value,
      dateStart: moment(startDate, "DD.MM.YYYY").format("YYYY-MM-DD"),
      dateFinish: moment(endDate, "DD.MM.YYYY").format("YYYY-MM-DD"),
      product: {
        year: values.year,
        categoryClass: values.class,
        comments: values.comment,
        manufacturer: "", // myCompanyDate.MyComp?.name,
        /*files: [
           {
             file: {
               name: "string"
             },
             fileType: 0
          }
         ],*/
        attributeValues: {
          ...additionalProps,
        },
        category: values.category,
        gostStandard: values.gost,
      },
      ndsRate: values.ndsRate ? 10 : 0,
      location: values.address,
    };
    console.log("data", dataForFetch);
    if (values.gost) {
      delete dataForFetch.product.attributeValues;
    } else {
      delete dataForFetch.product.gostStandard;
    }
    dispatch(fetchCreateLotThunk(dataForFetch));
    //dispatch(changeStatusLotTo("resolvedCreateLot"));
  };

  const onFinishFailedForm = (errorInfo) => {
    console.log("Failed:", errorInfo, errorInfo.values.fullPrice.replace(/\s+/g, ""));
    dispatch(changeStatusLotTo("rejectedCreateLot"));
  };

  const onChangeStartDate = (date, dateString) => {
    setStartDate(dateString);
  };
  const onChangeEndDate = (date, dateString) => {
    setEndDate(dateString);
  };

  const handleUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const headers = {
        "Content-Type": file.type,
        "Content-Disposition": `attachment; filename="${encodeURIComponent(file.name)}"`,
      };
      const response = await $apiUpload.post(`/files/upload/`, formData, {
        headers: headers,
      });
      console.log(response);
      message.success("Файл успешно загружен");
    } catch (error) {
      console.error(error);
      message.error("Ошибка при загрузке файла");
    }
  };

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const beforeUpload = (file) => {
    const isSizeValid = file.size / 1024 / 1024 < 5;

    if (!isSizeValid) {
      message.error("Размер файла должен быть не более 5 МБ");
    }

    return isSizeValid;
  };

  useEffect(() => {
    if (gost) {
      form.setFieldValue("class", null);
    }
    if (
      !allCategories
        .find((el) => el!.slug === category)
        ?.attributes!.find((el) => el.slug === "class")
    ) { form.setFieldValue("class", null);
    }
  }, [gost, category]);

  return (
    <>
      <Form
        onFinish={onFinishForm}
        onFinishFailed={onFinishFailedForm}
        form={form}
        labelAlign="left"
        layout={width! <= 1087 ? "vertical" : "horizontal"}
        initialValues={{
          year: 2023,
          option: options[1],
          payForm: oplatas[0],
        }}
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
      >
        <div className="form-sell">
          <Form.Item
            className="input-wrapper l1"
            name="category"
            label="Культура"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Select
              onClick={
                statusCategories !== "resolvedCategories"
                  ? () => dispatch(fetchGetCategoriesThunk())
                  : undefined
              }
              options={statusCategories !== "loading" ? allCategories : undefined}
              placeholder="Ячмень"
            />
          </Form.Item>
          <div className="two-el">
            <Form.Item
              className="input-wrapper two-el__children"
              label="Год урожая"
              name="year"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: "",
                  pattern: new RegExp(/^[0-9]+$/),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className="input-wrapper two-el__children"
              name="class"
              dependencies={["category"]}
              label="Класс/вид"
              rules={
                allCategories
                  .find((el) => el!.slug === category)
                  ?.attributes!.find((el) => el.slug === "class") && !gost
                  ? [
                      {
                        required: true,
                        message: "",
                      },
                    ]
                  : undefined
              }
            >
              <Select
                options={[
                  { label: "1", value: 1 },
                  { label: "2", value: 2 },
                  { label: "3", value: 3 },
                  { label: "4", value: 4 },
                  { label: "5", value: 5 },
                ]}
                disabled={
                  gost ||
                  (currentCategory?.attributes &&
                  currentCategory?.attributes.filter((el) => el.slug === "class")[0]
                    ? false
                    : true)
                }
              />
            </Form.Item>
          </div>
          <div className="two-el">
            <Form.Item
              className="input-wrapper two-el__children"
              label="Объем, т"
              name="quantity"
              rules={[
                {
                  required: true,
                  message: "",
                  pattern: new RegExp(/^[0-9]+$/),
                },
                {
                  message: "Не может быть < 10т",
                  validator: (_, value) => {
                    if (+value >= 10) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("не валидный ИНН");
                    }
                  },
                },
              ]}
            >
              <Input placeholder="т" />
            </Form.Item>
            <Form.Item
              className="input-wrapper two-el__children"
              label="Опцион"
              name="option"
            >
              <Select options={options} />
            </Form.Item>
          </div>
          <div className="two-el nds">
            <div className="input-wrapper two-el__children">
              <Form.Item
                label="Цена ₽/т"
                name="price"
                rules={[
                  {
                    required: true,
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                  {
                    message: "От 7000 до 25000руб/т",
                    validator: (_, value) => {
                      if (+value >= 7000 && +value <= 25000) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("не валидный ИНН");
                      }
                    },
                  },
                ]}
              >
                <Input className="inputt" />
              </Form.Item>
              <Form.Item name="ndsRate" valuePropName="checked">
                <Checkbox className="check">с НДС</Checkbox>
              </Form.Item>
            </div>

            <Form.Item
              className="input-wrapper two-el__children"
              style={{}}
              label="Оплата"
              name="payForm"
            >
              <Select className="inputt" options={oplatas} />
            </Form.Item>
          </div>

          <div className="two-el">
            <Form.Item
              className="input-wrapper two-el__children"
              label="Цена погрузки, ₽/т"
              name="loadingCost"
              rules={[
                {
                  pattern: onlyNumbers,
                  message: "Введите правильную цену",
                },
              ]}
            >
              <Input className="inputt" placeholder="0" />
            </Form.Item>
            <Form.Item
              className="input-wrapper two-el__children"
              label="Итоговая цена"
              dependencies={["price", "quantity", "loadingCost"]}
              name="fullPrice"
            >
              <Input
                className="inputt"
                disabled={true}
                style={{ background: "white", color: "black" }}
              />
            </Form.Item>
          </div>
          <div className="two-el ">
            <Form.Item
              className="input-wrapper two-el__children"
              label="Страна"
              name="country"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Select
                className="inputt"
                onClick={() => dispatch(getContryThunk())}
                style={{ width: "173px" }}
                options={countries}
                placeholder="Выберите"
              />
            </Form.Item>
            <Form.Item
              className="input-wrapper two-el__children "
              label="Регион"
              name="region"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Select
                className="inputt"
                onClick={
                  statusRegions !== "resolved"
                    ? () => dispatch(fetchAllRegionsThunk())
                    : undefined
                }
                options={
                  statusRegions === "loading"
                    ? [{ label: <Spin />, value: "1" }]
                    : allRegions2
                }
                placeholder="Выберите"
              />
            </Form.Item>
          </div>
          <Form.Item
            className="input-wrapper l1 adrress"
            name="address"
            label="Адрес отгрузки"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Input className="input-wrapper l1 " />
          </Form.Item>
          <Form.Item
            className="input-wrapper l1 comment"
            label="Комментарий"
            name="comment"
          >
            <TextArea
              placeholder="Опишите подробнее место отгрузки в случае необходимости"
              rows={2}
            />
          </Form.Item>
          <Form.Item
            label="Условия доставки"
            name="deliveryTerms"
            className="input-wrapper l1"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Select
              className="inputt"
              onClick={
                statusIncoTerms !== "resolvedIncoTerms"
                  ? () => dispatch(fetchGetIncoTermsThunk())
                  : undefined
              }
              options={
                statusIncoTerms === "loading"
                  ? [{ label: <Spin />, value: "1" }]
                  : allIncoTerms2
              }
              placeholder="Выберите"
            />
          </Form.Item>
          <div className="parametres">
            <div className="par">Параметры</div>
            <Form.Item className="input-wrapper pp" name="gost" valuePropName="checked">
              <Checkbox>ГОСТ 2616-7616</Checkbox>
            </Form.Item>
          </div>
          <div className="params">
            {allCategories[0] && !gost
              ? allCategories
                  .find((el) => el.slug === category)
                  ?.attributes!.map((el) => (
                    <Form.Item
                      key={el.slug}
                      labelCol={{ span: 24 }}
                      className="params-item"
                      name={el.slug}
                      label={el.title}
                      style={{}}
                      rules={
                        el.extra
                          ? [
                              {
                                message: `от ${+el.extra.min} до ${+el.extra.max}`,
                                validator: (_, value) => {
                                  if (
                                    +value >= +el.extra!.min &&
                                    +value <= +el.extra!.max
                                  ) {
                                    return Promise.resolve();
                                  } else {
                                    return Promise.reject(
                                      "PROMISE ОШИБКА В ЗАПОЛНЕНИИ SELLFORM"
                                    );
                                  }
                                },
                              },
                            ]
                          : undefined
                      }
                    >
                      <Input
                        disabled={gost}
                        suffix={el.type === "percent" ? "%" : null}
                        placeholder={
                          el.extra ? `от ${+el.extra.min} до ${+el.extra.max}` : undefined
                        }
                      />
                    </Form.Item>
                  ))
              : null}
          </div>
          <Form.Item
            className="input-wrapper podrabotka"
            name="podrabotka"
            valuePropName="checked"
          >
            {!gost && (
              <Checkbox disabled={gost}>
                Возможна подработка на территории производителя
                <Tooltip
                  title="В случае несоответстия заявленному качеству производитель в состоянии провести сушки или очистку от сорной примеси на своей территории"
                  placement="rightTop"
                  color="#95D708"
                >
                  <img style={{ paddingLeft: "4px" }} src={infoCirleIcon} alt="info" />
                </Tooltip>
              </Checkbox>
            )}
          </Form.Item>
          <Form.Item
            name="upload"
            className="input-wrapper download"
            valuePropName="fileList"
          >
            <Upload
              action="/upload.do"
              listType="picture-card"
              fileList={fileList}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              onRemove={() => setFileList([])}
              customRequest={({ file }) => handleUpload(file)}
              data={(file) => ({
                filename: file.name,
              })}
            >
              <div className="download-inner">
                <PlusOutlined />
                <div>Документы</div>
              </div>
            </Upload>
            <Tooltip
              placement="rightTop"
              color="#95D708"
              title="Загрузите все необходимые документы"
            >
              <img className="info-circle" src={infoCirleIcon} alt="info" />
            </Tooltip>
          </Form.Item>
          <div className="two-el ">
            <Form.Item
              className="input-wrapper two-el__children"
              name="start"
              label="Начало торгов"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <DatePicker
                onChange={onChangeStartDate}
                format={"DD.MM.YYYY"}
                placeholder="Выберите дату"
                placement="topRight"
                locale={locale}
              />
            </Form.Item>
            <Form.Item
              className="input-wrapper two-el__children"
              name="end"
              label="Окончание торгов"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <DatePicker
                onChange={onChangeEndDate}
                format={"DD.MM.YYYY"}
                placeholder="Выберите дату"
                placement="topRight"
                locale={locale}
              />
            </Form.Item>
          </div>
          <Button type="primary" htmlType="submit" size="large" className="submit-all">
            Опубликовать
          </Button>
        </div>
      </Form>
    </>
  );
};

export default SellForm;
