
import { AxiosResponse } from "axios";
import {$auth} from "../http";
import { RegLoginRequest } from "./models/request/AuthRequest";
import { AuthResponse, RegLoginResponse } from "./models/response/AuthResponse";


export default class AuthService{
  static async authMobile(mobile: string): Promise<AxiosResponse<AuthResponse>> {
    return $auth.post<AuthResponse>(`/api/v1/auth/mobile/`, {'mobile': mobile})
  }
  static async authEmail(email: string): Promise<AxiosResponse<AuthResponse>> {
    return $auth.post<AuthResponse>('/api/v1/auth/email/', {'email': email})
  }
  static async registration({mobile, email, token}: RegLoginRequest): Promise<AxiosResponse<RegLoginResponse>>  {
    return $auth.post<RegLoginResponse>('/api/v1/auth/token/', {mobile, token, email})
  }
  static async login({mobile, email, token}) {
    return $auth.post<RegLoginResponse>('/api/v1/auth/token/', {mobile,token,email})
  }
}
