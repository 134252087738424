import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { formatPhoneNumber } from '../../helpers/helpers';
import { useAppSelector } from "../../hooks/reduxTypesHooks";
import { RootState } from "../../Store/store";

const InfoBlock: React.FC = (): JSX.Element => {
  const { fullName, mobile } = useAppSelector((state: RootState) => state.user.user);
  const navigate = useNavigate();
  return (
    <>
      <div className="info-block">
        <h3 className="title">Представители компании</h3>
        <div className="flex">
          <div className="flex__item name-line">
            <p className="name-title">Имя, Фамилия</p>
            <p className="name">{fullName}</p>
          </div>
          <div className="flex__item phone-line">
            <p className="name-title">Номер телефона</p>
            <p className="name">{formatPhoneNumber(mobile)}</p>
          </div>
          {/*<div className="flex__item">
            <p className="name-title" style={{ color: "transparent" }}>
              Удалить
            </p>
            <p className="name g">Удалить</p>
  </div>*/}
        </div>
              {/*<Button
                  className="add"
                  type="primary"
                  size="large"
                  onClick={() => navigate("/addNewUser")}
                  disabled
              >
                  <div className="btn-inner">
                      <PlusOutlined />
                      <div>Добавить представителя</div>
                  </div>
              </Button>*/}
      </div>
    </>
  );
};

export default InfoBlock;
