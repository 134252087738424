import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import filterLogoMobileGreen from "../../../../../../../../Svgs/filterLogoMobileGreen.svg";
import filterLogoMobileGold from "../../../../../../../../Svgs/filterLogoMobileGold.svg";
import crossLogoGold from "../../../../../../../../Svgs/crossLogoGold.svg";
import crossLogo from "../../../../../../../../Svgs/crossLogo.svg";
import styles from "./mobileFilter.module.scss";
import { Form, Input, Button, Select } from "antd";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../hooks/reduxTypesHooks";
import CategoryService from "../../../../../../../../services/CategoriesService";
import { fetchGetCategoriesThunk } from "../../../../../../../../Store/catalogSlice/catalogSlice";
import {
  fetchGetBuyLotsThunk,
  fetchGetSellLotsThunk,
  firstBuyRequestChange,
  firstSellRequestChange,
  setBuyQuery,
  setSellQuery,
} from "../../../../../../../../Store/marketSlice/marketSlice";

const sortOptions = [
  { label: "По умолчанию", value: "" },
  { label: "По возрастанию цены", value: "price" },
  { label: "По убыванию цены", value: "-price" },
  { label: "По возрастанию объема", value: "quantity" },
  { label: "По убыванию объема", value: "-quantity" },
  { label: "По дате", value: "-created" },
];

const defaultOptions = {
  ordering: "",
  category: [],
  price_min: "",
  price_max: "",
  quantity_min: "",
  quantity_max: "",
};
async function getCategories() {
  const res = await CategoryService.getCategories();
  console.log(res);
}
const MobileFilterPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    getCategories();
  }, []);

  const location = useLocation();
  const { type, sellFetchData, buyFetchData } = location.state as any;
  const typeSelector = type.current.type === "sell" ? true : false;
  const buttonColor = typeSelector ? "#6FB200" : "#BEA14B";

  const [form] = Form.useForm();
  const { categories } = useAppSelector((state) => state.catalog);
  const { allCategories, statusCategories } = categories;

  const onFinishForm = (values) => {
    if (typeSelector) {
      sellFetchData.current.category = values.category ? values.category : [];
      sellFetchData.current.ordering = values.ordering ? values.ordering : "";
      sellFetchData.current.price_min = values.priceFrom ? values.priceFrom : "";
      sellFetchData.current.price_max = values.priceTo ? values.priceTo : "";
      sellFetchData.current.quantity_min = values.quantityFrom ? values.quantityFrom : "";
      sellFetchData.current.quantity_max = values.qunatityTo ? values.qunatityTo : "";
      dispatch(setBuyQuery(values));
      dispatch(fetchGetBuyLotsThunk({ ...sellFetchData.current, page: 1 }));
      navigate("/", { state: { type: type.current.type } });
    } else {
      buyFetchData.current.category = values.category ? values.category : [];
      buyFetchData.current.ordering = values.ordering ? values.ordering : "";
      buyFetchData.current.price_min = values.priceFrom ? values.priceFrom : "";
      buyFetchData.current.price_max = values.priceTo ? values.priceTo : "";
      buyFetchData.current.quantity_min = values.quantityFrom ? values.quantityFrom : "";
      buyFetchData.current.quantity_max = values.qunatityTo ? values.qunatityTo : "";
      dispatch(setSellQuery(values));
      dispatch(fetchGetSellLotsThunk({ ...buyFetchData.current, page: 1 }));
      navigate("/", { state: { type: type.current.type } });
    }
  };

  const handleResetFilters = () => {
    if (typeSelector) {
      sellFetchData.current.category = [];
      sellFetchData.current.ordering = "";
      sellFetchData.current.price_min = "";
      sellFetchData.current.price_max = "";
      sellFetchData.current.quantity_min = "";
      sellFetchData.current.quantity_max = "";
      dispatch(setBuyQuery(defaultOptions));
      dispatch(firstBuyRequestChange(true));
      navigate("/", { state: { type: type.current.type } });
    } else {
      buyFetchData.current.category = [];
      buyFetchData.current.ordering = "";
      buyFetchData.current.price_min = "";
      buyFetchData.current.price_max = "";
      buyFetchData.current.quantity_min = "";
      buyFetchData.current.quantity_max = "";
      dispatch(setSellQuery(defaultOptions));
      dispatch(firstSellRequestChange(true));
      navigate("/", { state: { type: type.current.type } });
    }
  };
  return (
    <div className={styles.filter}>
      <div className={styles.filter_header}>
        <div className={styles.filter_header__left}>
          <img src={typeSelector ? filterLogoMobileGreen : filterLogoMobileGold} alt="" />
          <p style={{ paddingLeft: "10px" }}>
            {typeSelector ? "Фильтры продажи" : "Фильтры покупки"}
          </p>
        </div>
        <Link to="/" state={{ type: type.current.type }}>
          <img
            className={styles.filter_header__right}
            src={typeSelector ? crossLogo : crossLogoGold}
            alt=""
          />
        </Link>
      </div>
      <Form
        onFinish={onFinishForm}
        form={form}
        className={styles.filter__form}
        layout="vertical"
      >
        <Form.Item name="ordering" label="Сортировка" className={styles.filter__select}>
          <Select
            className={styles.filter__select}
            options={sortOptions}
            placeholder="По умолчанию"
          />
        </Form.Item>
        <Form.Item name="category" label="Культура" className={styles.filter__select}>
          <Select
            onClick={
              statusCategories !== "resolvedCategories"
                ? () => dispatch(fetchGetCategoriesThunk())
                : undefined
            }
            options={statusCategories !== "loading" ? allCategories : undefined}
            mode={"multiple"}
            placeholder={"Ячмень"}
            showArrow={true}
            showSearch={false}
            allowClear={true}
          />
        </Form.Item>
        <div className={styles.filter__two_elements}>
          <Form.Item
            label="Цена от, ₽"
            name="priceFrom"
            className="input_item"
            style={{ width: "49%" }}
            /*rules={[
              {
                pattern: new RegExp(/^[0-9]+$/),
              },
              {
                message: "От 7000 до 25000руб/т",
                validator: (_, value) => {
                  if (+value >= 7000 && +value <= 25000) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("не валидный ИНН");
                  }
                },
              },
            ]}*/
          >
            <Input placeholder={"7000"} style={{ borderRadius: "16px" }} />
          </Form.Item>
          <Form.Item
            label="Цена до, ₽"
            name="priceTo"
            className="input_item"
            style={{ width: "49%" }}
            /*rules={[
              {
                pattern: new RegExp(/^[0-9]+$/),
              },
              {
                message: "От 7000 до 25000руб/т",
                validator: (_, value) => {
                  if (+value !== null && +value >= 7000 && +value <= 25000) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("не валидный ИНН");
                  }
                },
              },
            ]}*/
          >
            <Input placeholder={"25000"} style={{ borderRadius: "16px" }} />
          </Form.Item>
        </div>
        <div className={styles.filter__two_elements}>
          <Form.Item
            label="Объем от, т"
            name="quantityFrom"
            className="input_item"
            style={{ width: "49%" }}
          >
            <Input placeholder={"10"} style={{ borderRadius: "16px" }} />
          </Form.Item>
          <Form.Item
            label="Объем до, т"
            name="quantityTo"
            className="input_item"
            style={{ width: "49%" }}
          >
            <Input placeholder={"10000"} style={{ borderRadius: "16px" }} />
          </Form.Item>
        </div>

        <Button
          type="primary"
          htmlType="submit"
          size="large"
          className="filter_submit_button"
          style={{ borderColor: buttonColor, background: buttonColor }}
        >
          Применить
        </Button>
      </Form>
      <button onClick={handleResetFilters} className={styles.filter_reset_button}>
        Сбросить фильтры
      </button>
    </div>
  );
};

export default MobileFilterPage;
