import { AxiosResponse } from 'axios';
import $api from "../http";
import { IBidResponse } from './models/response/BidResponse';



export default class BidService {
  static async getBid(id: number): Promise<AxiosResponse<IBidResponse>> {
    return $api.get(`/api/v1/market/bid/?lot_id=${id}`);
  }
  static async postBid(bidData): Promise<AxiosResponse<IBidResponse>> {
    return $api.post(`/api/v1/market/bid/`, bidData);
  }
}
