import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BidService from "../../services/BidsService";
import LotsService from "../../services/LotsServices";
import MarketService from "../../services/MarketService";
import {
  CurrentIncoTerms,
  IRequestLots,
  LotsResponse,
  MarketState,
  RequestLot,
  ResponseLot,
  Result,
} from "./interfaces";

const initialState: MarketState = {
  incoTerms: {
    allIncoTerms: [],
    currentIncoTerms: {
      label: "",
      value: "",
    },
    statusIncoTerms: null,
    errorIncoTerms: null,
  },
  buyLots: {
    status: null,
    errorBuyLots: null,
    data: { count: 0, next: null, previous: null, results: [] },
    query: {
      ordering: "",
      price_min: "",
      price_max: "",
      quantity_min: "",
      quantity_max: "",
      category: [],
      page: 1,
    },
    isFirstRequest: true,
  },
  sellLots: {
    status: null,
    errorSellLots: null,
    data: { count: 0, next: null, previous: null, results: [] },
    query: {
      ordering: "",
      price_min: "",
      price_max: "",
      quantity_min: "",
      quantity_max: "",
      category: [],
      page: 1,
    },
    isFirstRequest: true,
  },
  myOfferLots: {
    status: null,
    errorSellLots: null,
    data: { count: 0, next: null, previous: null, results: [] },
    query: {
      ordering: "",
      price_min: "",
      price_max: "",
      quantity_min: "",
      quantity_max: "",
      category: [],
      page: 1,
    },
    isFirstRequest: true,
  },
  currentLotBids: {
    status: null,
    errorCurrentLotBids: null,
    data: {},
  },
  lot: {
    createdLot: {},
    statusLot: null,
    statusLot2: null,
    errorLot: null,
  },
};

export const fetchGetBuyLotsThunk = createAsyncThunk<
  LotsResponse,
  IRequestLots,
  { rejectValue: string }
>("market/fetchGetBuyLotsThunk", async function (filters, { rejectWithValue }) {
  try {
    const response = await LotsService.getBuyLots(filters);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const fetchGetSellLotsThunk = createAsyncThunk<
  LotsResponse,
  IRequestLots,
  { rejectValue: string }
>("market/fetchGetSellLotsThunk", async function (filters, { rejectWithValue }) {
  try {
    const response = await LotsService.getSellLots(filters);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const fetchGetMyOfferLotsThunk = createAsyncThunk<
  LotsResponse,
  IRequestLots,
  { rejectValue: string }
>("market/fetchGetMyOfferLotsThunk", async function (filters, { rejectWithValue }) {
  try {
    const response = await LotsService.getMyOfferLots(filters);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const fetchGetIncoTermsThunk = createAsyncThunk<
  CurrentIncoTerms[],
  undefined,
  { rejectValue: string }
>("market/fetchGetIncoTermsThunk", async function (_, { rejectWithValue }) {
  try {
    const response = await MarketService.getIncoTerms();
    return response.data.map((el: { title: string; code: string }) => ({
      label: el.title,
      value: el.code,
    })) as CurrentIncoTerms[];
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});
export const fetchCreateLotThunk = createAsyncThunk<
  ResponseLot,
  RequestLot,
  { rejectValue: string }
>("market/fetchCreateLotThunk", async function (lotDate, { rejectWithValue, dispatch }) {
  try {
    const response = await MarketService.createLot(lotDate);
    const data = await response.data;
    return data;
  } catch (error: any) {
    console.error(error);
    if (lotDate.type === "sell") {
      dispatch(changeStatusLotTo("rejectedCreateLot"));
    } else {
      dispatch(changeStatusLot2To("rejectedCreateLot"));
    }
    return rejectWithValue(error.message);
  }
});

export const fetchGetCurrentLotBids = createAsyncThunk<
  any,
  number,
  { rejectValue: string }
>("market/fetchGetCurrentLotBids", async function (id, { rejectWithValue }) {
  try {
    const response = await BidService.getBid(id);
    const data = await response.data;
    return data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

const marketSlice = createSlice({
  name: "market",
  initialState,
  reducers: {
    changeStatusLotTo(state, action) {
      state.lot.statusLot = action.payload;
    },
    changeStatusLot2To(state, action) {
      state.lot.statusLot2 = action.payload;
    },
    firstBuyRequestChange(state, action) {
      state.buyLots.isFirstRequest = action.payload;
    },
    firstSellRequestChange(state, action) {
      state.sellLots.isFirstRequest = action.payload;
    },
    firstMyOfferRequestChange(state, action) {
      state.sellLots.isFirstRequest = action.payload;
    },
    setBuyQuery(state, action) {
      state.buyLots.query.category = action.payload.category
        ? action.payload.category
        : [];
      state.buyLots.query.price_min = action.payload.priceFrom
        ? action.payload.priceFrom
        : "";
      state.buyLots.query.price_max = action.payload.priceTo
        ? action.payload.priceTo
        : "";
      state.buyLots.query.quantity_min = action.payload.quantityFrom
        ? action.payload.quantityFrom
        : "";
      state.buyLots.query.quantity_max = action.payload.quantityTo
        ? action.payload.quantityTo
        : "";
      state.buyLots.query.ordering = action.payload.ordering
        ? action.payload.ordering
        : "";
    },
    setBuyPage(state, action) {
      state.buyLots.query.page = action.payload;
    },
    setSellQuery(state, action) {
      state.sellLots.query.category = action.payload.category
        ? action.payload.category
        : [];
      state.sellLots.query.price_min = action.payload.priceFrom
        ? action.payload.priceFrom
        : "";
      state.sellLots.query.price_max = action.payload.priceTo
        ? action.payload.priceTo
        : "";
      state.sellLots.query.quantity_min = action.payload.quantityFrom
        ? action.payload.quantityFrom
        : "";
      state.sellLots.query.quantity_max = action.payload.quantityTo
        ? action.payload.quantityTo
        : "";
      state.sellLots.query.ordering = action.payload.ordering
        ? action.payload.ordering
        : "";
    },
    setSellPage(state, action) {
      state.sellLots.query.page = action.payload;
    },
    setMyOfferPage(state, action) {
      state.sellLots.query.page = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchGetIncoTermsThunk.pending, (state) => {
        state.incoTerms.statusIncoTerms = "loading";
        state.incoTerms.errorIncoTerms = null;
      })
      .addCase(fetchGetIncoTermsThunk.fulfilled, (state, action) => {
        state.incoTerms.statusIncoTerms = "resolvedGetIncoTerms";

        state.incoTerms.allIncoTerms = action.payload;
      })
      .addCase(fetchGetIncoTermsThunk.rejected, (state, action) => {
        state.incoTerms.statusIncoTerms = "rejectedGetIncoTerms";
        state.incoTerms.errorIncoTerms = action.payload;
      })

      .addCase(fetchCreateLotThunk.pending, (state) => {
        state.lot.statusLot = "loading";
        state.lot.errorLot = null;
      })
      .addCase(fetchCreateLotThunk.fulfilled, (state, action) => {
        if (action.payload.type === "sell") {
          state.lot.statusLot = "resolvedCreateLot";
        } else {
          state.lot.statusLot2 = "resolvedCreateLot";
        }

        state.lot.createdLot = action.payload;
      })
      .addCase(fetchCreateLotThunk.rejected, (state, action) => {
        state.lot.errorLot = action.payload;
      })

      .addCase(fetchGetBuyLotsThunk.pending, (state) => {
        state.buyLots.status = "loading";
        state.buyLots.errorBuyLots = null;
      })
      .addCase(fetchGetBuyLotsThunk.fulfilled, (state, action) => {
        state.buyLots.status = "resolvedGetBuyLots";
        if (action.payload.previous) {
          state.buyLots.data.results = [
            ...state.buyLots.data.results,
            ...(action.payload.results as Result[]),
          ];
          state.buyLots.data.next = action.payload.next;
          state.buyLots.data.count += action.payload.count;
        } else {
          state.buyLots.data = action.payload;
        }
      })
      .addCase(fetchGetBuyLotsThunk.rejected, (state, action) => {
        state.buyLots.status = "rejectedGetBuyLots";
        state.buyLots.errorBuyLots = action.payload;
      })
      .addCase(fetchGetSellLotsThunk.pending, (state) => {
        state.sellLots.status = "loading";
        state.sellLots.errorSellLots = null;
      })
      .addCase(fetchGetSellLotsThunk.fulfilled, (state, action) => {
        state.sellLots.status = "resolvedSellBuyLots";
        if (action.payload.previous) {
          state.sellLots.data.results = [
            ...state.sellLots.data.results,
            ...(action.payload.results as Result[]),
          ];
          state.sellLots.data.next = action.payload.next;
          state.sellLots.data.count += action.payload.count;
        } else {
          state.sellLots.data = action.payload;
        }
      })
      .addCase(fetchGetSellLotsThunk.rejected, (state, action) => {
        state.sellLots.status = "rejectedGetSellLots";
        state.sellLots.errorSellLots = action.payload;
      })
      .addCase(fetchGetMyOfferLotsThunk.pending, (state) => {
        state.myOfferLots.status = "loading";
        state.myOfferLots.errorSellLots = null;
      })
      .addCase(fetchGetMyOfferLotsThunk.fulfilled, (state, action) => {
        state.myOfferLots.status = "resolvedGetMyOfferLots";
        if (action.payload.previous) {
          state.myOfferLots.data.results = [
            ...state.myOfferLots.data.results,
            ...(action.payload.results as Result[]),
          ];
          state.myOfferLots.data.next = action.payload.next;
          state.myOfferLots.data.count += action.payload.count;
        } else {
          state.myOfferLots.data = action.payload;
        }
      })
      .addCase(fetchGetMyOfferLotsThunk.rejected, (state, action) => {
        state.myOfferLots.status = "rejectedGetMyOfferLots";
        state.myOfferLots.errorSellLots = action.payload;
      })
      .addCase(fetchGetCurrentLotBids.pending, (state) => {
        state.currentLotBids.status = "loading";
        state.currentLotBids.errorCurrentLotBids = null;
      })
      .addCase(fetchGetCurrentLotBids.fulfilled, (state, action) => {
        state.currentLotBids.status = "resolvedGetCurrentLotBids";
        if (action.payload.previous) {
          state.currentLotBids.data.results = [
            ...state.currentLotBids.data.results,
            ...(action.payload.results as Result[]),
          ];
          state.currentLotBids.data.next = action.payload.next;
          state.currentLotBids.data.count += action.payload.count;
        } else {
          state.currentLotBids.data = action.payload;
        }
      })
      .addCase(fetchGetCurrentLotBids.rejected, (state, action) => {
        state.currentLotBids.status = "rejectedGetCurrentLotBids";
        state.currentLotBids.errorCurrentLotBids = action.payload;
      });
  },
});

export const {
  changeStatusLotTo,
  changeStatusLot2To,
  firstBuyRequestChange,
  firstSellRequestChange,
  firstMyOfferRequestChange,
  setBuyPage,
  setBuyQuery,
  setSellPage,
  setMyOfferPage,
  setSellQuery,
} = marketSlice.actions;

export default marketSlice.reducer;
