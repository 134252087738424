import React, { DetailedHTMLProps, HTMLAttributes } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import locationLogo from "../../Svgs/locationLogo.svg";
import { Button } from "@consta/uikit/Button";
import { FC } from "react";
import styles from "./mobileCard.module.scss";
import dayjs from "dayjs";
import { formatPrice } from "../../helpers/helpers";
import Card from "../MainPageComponents/MainContent/Middle/MiddleCenter/components/Card/Card";
import { Theme } from "@consta/uikit/Theme";
import { whitePreset } from "../../ui-presets/presetsTheme/whitePreset";
import { parsePhoneNumber } from "libphonenumber-js";
import { useAppSelector } from "../../hooks/reduxTypesHooks";

export interface IMobileCardProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  type?: "buy" | "sell";
  culture?: string | undefined;
  lot?: any;
  myLots?: boolean;
  currentLotBid?: boolean;
  checkSize: any;
  getLotsData?: any;
  currentLotBids?: any;
}

const MobileCard: FC<IMobileCardProps> = ({
  type,
  lot,
  myLots = false,
  currentLotBid = false,
  checkSize,
}) => {
    const formatPhoneNumber = (phoneNumber: string): string | undefined => {
      if (!phoneNumber) {
        return "Нет телефона";
      } else {
        const parsed = parsePhoneNumber(phoneNumber, "RU");
        return parsed.formatNational().replace(/^8/, "+7");
      }
    };
  const { allCategories, statusCategories } = useAppSelector(
    (state) => state.catalog.categories
  );
  const convertCulture = (culture) => {
    if (statusCategories === "resolvedCategories" && allCategories) {
      const currentCulture = allCategories.find((el) => el.slug === culture);
      return currentCulture?.title;
    }
  };

  return (
    <Card
      backgroundColor={
        myLots || currentLotBid ? "white" : type === "buy" ? "green" : "gold"
      }
      size={checkSize}
    >
      <div className={styles.card}>
        <div className={styles.card__left}>
          <div className={styles.lot_count}>
            № {lot.number ? lot.number : lot.id}
          </div>
          <div className={styles.lot__name}>
            {
              currentLotBid
                ? lot.company.name
                : `${convertCulture(lot.product.category)}${
                    lot.product.categoryClass
                      ? `, ${lot.product.categoryClass} класс`
                      : ""
                  }`

              /*currentLotBid ? (
              lot.company.name
            ) : (
              <FormattedMessage
                id="MiddleCenter.cardsLotName"
                values={{
                  lotName: culture,

                  lotClassNum: "5",
                }}
              />
            )*/
            }
          </div>

          <div>
            {currentLotBid ? (
              formatPhoneNumber(lot.company.phone)
            ) : (
              <div className={styles.lot__location}>
                <img
                  src={locationLogo}
                  alt="location-logo"
                  className={styles.lot__location__logo}
                  style={{ marginRight: "6px" }}
                />
                <p className={styles.lot__location__pTag}>{lot.location}</p>
              </div>
            )}
          </div>
        </div>
        <div className={styles.card__right}>
          <div className={styles.lot__date}>
            <FormattedMessage
              id="MiddleCenter.cardsLotDate"
              values={{
                lotDate: dayjs(lot.created).format("DD.MM.YYYY"),
              }}
            />
          </div>
          <div className={styles.lot__weight}>
            {myLots ? (
              <div style={{ height: "16px" }}></div>
            ) : (
              <FormattedMessage
                id="MiddleCenter.cardsLotWeight"
                values={{ lotWeight: lot.quantity }}
              />
            )}
          </div>

          <div
            className={styles.lot__price}
            style={{
              color: `${myLots ? "#6fb200" : type === "sell" ? "#C5962E" : "#6fb200"}`,
            }}
          >
            {myLots ? (
              <FormattedMessage
                id="MiddleCenter.cardsLotWeight"
                values={{ lotWeight: lot.quantity }}
              />
            ) : (
              `${lot.price && formatPrice(lot.price).replace(/,.*/, "")} ₽/т`
            )}
          </div>
        </div>
      </div>
      {currentLotBid && (
        <Theme preset={whitePreset}>
          <div className={styles.myLotsButtons}>
            <Button
              size="s"
              form="round"
              label="Отказаться"
              view="secondary"
              style={{ minWidth: `${checkSize === "m" ? "150px" : "120px"}` }}
            ></Button>
            <Button
              size="s"
              form="round"
              label="Принять"
              style={{ minWidth: `${checkSize === "m" ? "150px" : "120px"}` }}
            ></Button>
          </div>
        </Theme>
      )}
    </Card>
  );
};

export default MobileCard;
