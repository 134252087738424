import $api from "../http";

export default class InformerService {
  static async setInformer(data) {
    return $api.post(`/api/v1/informer/notifications/`, data);
  }
  static async getInformer() {
    return $api.get(`/api/v1/informer/notifications/`);
  }
  static async deleteInformer(id) {
    return $api.delete(`/api/v1/informer/notifications/${id}/`);
  }
}
