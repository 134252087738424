import "./_color/Theme_color_gpnGold.css";
import "./_color/Theme_color_gpnDark.css";
import "./_control/Theme_control_gpnGold.css";
import "./_font/Theme_font_gpnGold.css";
import "./_size/Theme_size_gpnGold.css";
import "./_space/Theme_space_gpnGold.css";
import "./_shadow/Theme_shadow_gpnGold.css";

export const goldPreset = {
  color: {
    primary: "gpnGold",
    accent: "gpnDark",
    invert: "gpnGold",
  },
  control: "gpnGold",
  font: "gpnGold",
  size: "gpnGold",
  space: "gpnGold",
  shadow: "gpnGold",
};
