import { Select, Form, Input, Button, PageHeader, Breadcrumb, notification } from "antd";
import React, { FC, useEffect} from "react";

import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypesHooks";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { getContryThunk } from "../../Store/userSlice/userSlice";
import ContractorsService from "../../services/ContractorsService";
import { RootState } from "../../Store/store";
import {
  changeStatusInvite,
  fetchSendInviteThunk,
} from "../../Store/contractorsSlice/contractorsSlice";

const AddUserPage: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { countries } = useAppSelector((state) => state.user);
  const myCompanyData = useAppSelector(
    (state: RootState) => state.contractors.myCompanyDate.MyComp
  );
  const { statusInvite } = useAppSelector(
    (state: RootState) => state.contractors.sendInvite
  );
  useEffect(() => {
    dispatch(getContryThunk());
    ContractorsService.getCompanyMy();
  }, [dispatch]);

  useEffect(() => {
    if (statusInvite === "resolved") {
      notification.open({
        message: "Приглашение отправлено",
      });
      navigate("/profileMyCompany");
      changeStatusInvite("");
    } else if (statusInvite === "rejected") {
      notification.open({
        message: "Произошла ошибка, попробуйте позже",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusInvite]);
  const onFinishForm = (values) => {
    console.log("Success:", values);

    const dataForFetch = {
      company: myCompanyData.id,
      phone: values.mobile,
    };
    console.log("data", dataForFetch);
    dispatch(fetchSendInviteThunk(dataForFetch));
  };

  const onFinishFailedForm = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div>
      <PageHeader
        className="site-page-header1"
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/" style={{ color: "gray" }}>
                Главная
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Добавить представителя</Breadcrumb.Item>
          </Breadcrumb>
        }
      />
      <Form
        form={form}
        layout={width! > 1060 ? "horizontal" : "vertical"}
        onFinish={onFinishForm}
        onFinishFailed={onFinishFailedForm}
      >
        <div className="profile-block">
          <Form.Item
            rules={[
              {
                required: true,
                message: "Введите ФИО",
              },
            ]}
            name="fullName"
            className="input-wrapper l1"
            label="   Имя, Фамилия"
          >
            <Input className="inputt" placeholder="Иван Иванов" />
          </Form.Item>
          <div className="two-el">
            <Form.Item
              className="input-wrapper two-el__children"
              style={{ marginRight: "356px" }}
              label="Страна"
              name="country"
              rules={[
                {
                  required: true,
                  message: "Выберите страну",
                },
              ]}
            >
              <Select
                className="inputt"
                /*options={countries}*/
                options={[{ label: "Россия", value:"Russia" }]}
                placeholder="Выберите страну"
              />
            </Form.Item>
          </div>
          <div className="two-el">
            <Form.Item
              className="input-wrapper two-el__children"
              label="E-mail"
              name="email"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: "Некорректный email",
                },
                {
                  type: "email",
                  message: "Некорректный email",
                },
              ]}
            >
              <Input className="inputt" />
            </Form.Item>
            <Form.Item
              className="input-wrapper two-el__children phone"
              label="Моб.тел"
              style={{ marginLeft: "107px" }}
              name="mobile"
              rules={[{ required: true, message: "Введите валидный телефон" }]}
            >
              <Input className="inputt" style={{ width: "173px" }} />
            </Form.Item>
          </div>
        </div>
        <Form.Item>
          <Button
            className="submit-all all3"
            style={{ borderRadius: "20px" }}
            type="primary"
            htmlType="submit"
            size="large"
          >
            Добавить представителя
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

/* <Form form={form} className={styles.addUser}>
        <div className={styles.addUser_form}>
          <div className={styles.addUser_form_left}>
            <Form.Item
              name="lastName"
              label="Фамилия"
              className={styles.addUser_form_left__item}
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Input className={styles.addUser_form_left__item__input} />
            </Form.Item>
            <Form.Item
              name="firstName"
              label="Имя"
              className={styles.addUser_form_left__item}
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Input className={styles.addUser_form_left__item__input} />
            </Form.Item>
            <Form.Item
              name="patronymic"
              label="Отчество"
              className={styles.addUser_form_left__item}
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Input className={styles.addUser_form_left__item__input} />
            </Form.Item>
          </div>
          <div className={styles.addUser_form_right}>
            <Form.Item
              label="Страна"
              name="country"
              className={styles.addUser_form_right__item}
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Select
                placeholder="Выберите страну"
                className={styles.addUser_form_right__item__select}
                options={[{ label: "Russia", value: "Россия" }]}
              />
            </Form.Item>

            <Form.Item
              label="E-mail"
              name="email"
              className={styles.addUser_form_right__item}
              rules={[
                {
                  message: "Неверный e-mail",
                },
              ]}
            >
              <Input
                placeholder="example@mail.ru"
                className={styles.addUser_form_right__item__input}
              />
            </Form.Item>
            <Form.Item
              label="Моб. телефон"
              name="phoneNumber"
              className={styles.addUser_form_right__item}
            >
              <Input className={styles.addUser_form_right__item__input} />
            </Form.Item>
          </div>
        </div>
        <Button
          className={styles.submitButton}
          type="primary"
          size="large"
          onClick={() => navigate("/profileMyCompany")}
        >
          <div>
            <div>Добавить представителя</div>
          </div>
        </Button>
      </Form> */

export default AddUserPage;
