import React, { useState } from "react";
import LanguageBlock from "../../Components/AuthComponents/0LanguageBlock";
import NumberBlock from "../../Components/AuthComponents/1NumberBlock";
import SmsCodeBlock from "../../Components/AuthComponents/2SmsCodeBlock";
import FioBlock from "../../Components/AuthComponents/3FIOBlock";
import "./authorization.scss";
import mainLogoMob from "../../../src/Svgs/mainLogoMob.svg";
interface Props {
  authStage: number;
  setAuthStage: React.Dispatch<React.SetStateAction<number>>;
}



const Authorization: React.FC<Props> = ({ authStage, setAuthStage }) => {
  const [selectedMenu, setSelectedMenu] = useState({ reg: false, login: true });
  console.log("------------------->", authStage);
  return (
    <div className="authorization">
      <div className="about">
        <h1 className="about__title">Зерновая биржа</h1>
        <h3 className="about__sub-title">Escrow Operator Agro Exchange</h3>
      </div>
      <div className="content">
        <div className="authblock">
          <img className="authblock__img" src={mainLogoMob} alt="" />
          <div className="authblock__lower">
            {(authStage === 1 && <LanguageBlock setAuthStage={setAuthStage} />) ||
              (authStage === 2 && (
                <NumberBlock
                  setAuthStage={setAuthStage}
                  selectedMenu={selectedMenu}
                  setSelectedMenu={setSelectedMenu}
                />
              )) ||
              (authStage === 3 && (
                <SmsCodeBlock setAuthStage={setAuthStage} selectedMenu={selectedMenu} />
              )) ||
              (authStage === 4 && <FioBlock setAuthStage={setAuthStage} />)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Authorization;

// {/* <Routes>
//               <Route path="*" element={<LanguageBlock />} />
//               {/* <Route path="/r1" element={<CountryBlock/>}/> */}
//               <Route path="/r1" element={<NumberBlock selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu}/>} />
//               <Route path="/r2" element={<SmsCodeBlock selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu}/>} />
//               <Route path="/r3" element={<FioBlock />} />
//             </Routes> */}
