import $agrogo_api from "../http/agrogo_api";

export default class ShippingService {
  static async getShippingCultures() {
    return $agrogo_api.get(`/api/v1/cargo_type/`);
  }
  static async calcDistance(data) {
    return $agrogo_api.post(`/api/v1/calc/distance/`, data);
  }
  static async calcPrice(data) {
    return $agrogo_api.post(`/api/v1/calc/price/`, data);
  }
  static async calcTrips(data) {
    return $agrogo_api.post(`/api/v1/calc/trips/`, data);
  }
  static async getRegions() {
    return $agrogo_api.get(`/api/v1/regions/`);
  }
}
