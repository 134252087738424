import React, { useEffect, useRef, useState } from "react";
import "../../center.scss";
import SellMobileList from "./SellMobileList";
import BuyMobileList from "./BuyMobileList";
import filterLogoMobileGreen from "../../../../../../../Svgs/filterLogoMobileGreen.svg";
import { Tabs } from "antd";
import { Link, useLocation } from "react-router-dom";
import styles from "./mobileList.module.scss";

const MobileLists = ({ regionsHash, culturesHash }) => {
  const filtersState = useRef({ type: "sell" });
  const sellFetchData = useRef({
    ordering: "",
    price_min: "",
    price_max: "",
    quantity_min: "",
    quantity_max: "",
    category: [],
    page: 1,
  });

  const buyFetchData = useRef({
    ordering: "",
    price_min: "",
    price_max: "",
    quantity_min: "",
    quantity_max: "",
    category: "",
    page: "",
  });

  const location = useLocation();
  if (location.state) {
    const { type } = location.state as any;
    filtersState.current.type = type;
  }

  return (
    <div className={styles.filter_block}>
      <Link
        to="/mobileFilters"
        state={{ type: filtersState, buyFetchData, sellFetchData }}
      >
        <div className={styles.info_button}>
          <img src={filterLogoMobileGreen} />
        </div>
      </Link>

      <Tabs
        className={styles.filter_block_inner}
        defaultActiveKey={filtersState.current.type}
        onChange={(e) => (filtersState.current.type = e)}
        items={[
          {
            label: `Продажа`,
            key: "buy",
            children: (
              <BuyMobileList
                regionsHash={regionsHash}
                culturesHash={culturesHash}
                buyFetchData={buyFetchData}
              />
            ),
          },
          {
            label: "Покупка",
            key: "sell",
            children: <SellMobileList culturesHash={culturesHash} />,
          },
        ]}
      />
    </div>
  );
};

export default MobileLists;
