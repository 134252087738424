import React, { FC } from "react";
import { IBuySellButton } from "./BuySellButton.interface";
import styles from "./BuySellButton.module.scss"
import cn from "classnames";

const BuySellButton: FC<IBuySellButton> = ({
  children,
    backgroundColor,
  value
}): JSX.Element => {
  return (
    <button
      className={cn
      (styles.button,
        {
          [styles.green]: backgroundColor === "green",
            [styles.gold]: backgroundColor === "gold",
          [styles.red]: backgroundColor === "red"
        })
      }
          value={value}
    >
      {backgroundColor === "green" ? "Купить" : ( backgroundColor === "gold" ? "Предложить" : "Продать")}
    </button>
  );
};

export default BuySellButton;
