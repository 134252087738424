import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Form, Input, notification, PageHeader, Select } from "antd";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypesHooks";
import {
  changeStatusToNull,
  getContryThunk,
  putDateMeThunk,
} from "../../Store/userSlice/userSlice";
import "./myProfile.scss";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { RootState } from "../../Store/store";

const MyProfilePage: React.FC = (): JSX.Element => {
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [dirtyForm, setDirtyForm] = useState<boolean>(false);
  const { user, status, countries, isAuth } = useAppSelector(
    (state: RootState) => state.user
  );

  useEffect(() => {
    dispatch(getContryThunk());
  }, [dispatch]);

  useEffect(() => {
    if (isAuth) {
      form.setFieldsValue({
        fullName: user?.fullName,
        country: { label: "Россия", value: "Russia" },
        /*country: {
          value: countries!.find((el) => el.code === user.country)?.code,
          label: countries!.find((el) => el.code === user.country)?.name,
        },*/ email: user?.email,
        mobile: user?.mobile,
      });
    }
    if (status === "resolvedPutDateMe") {
      notification.open({
        message: "Изменения сохранены",
      });
      dispatch(changeStatusToNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const onFinishForm = (values) => {
    console.log("Success:", values);

    const dataForFetch = {
      fullName: values.fullName,
      country: values.country,
      email: values.email,
      mobile: values.mobile,
    };
    console.log("data", dataForFetch);
    dispatch(putDateMeThunk(dataForFetch));
  };

  const onFinishFailedForm = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  console.log(countries);
  return (
    <div className="fullbox__main">
      <PageHeader
        className="site-page-header1"
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/" style={{ color: "gray" }}>
                Главная
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Личный профиль</Breadcrumb.Item>
          </Breadcrumb>
        }
      />
      <Form
        form={form}
        layout={width! > 1060 ? "horizontal" : "vertical"}
        onFinish={onFinishForm}
        onFinishFailed={onFinishFailedForm}
        onFieldsChange={() => setDirtyForm(true)}
      >
        <div className="profile-block">
          <Form.Item name="fullName" className="input-wrapper l1" label="   Имя, Фамилия">
            <Input className="inputt" placeholder="Иван Иванов" />
          </Form.Item>
          <div className="two-el">
            <Form.Item
              className="input-wrapper two-el__children"
              style={{ marginRight: "356px" }}
              label="Страна"
              name="country"
              rules={[
                {
                  required: true,
                  message: "false",
                },
              ]}
            >
              <Select
                className="inputt"
                options={[{ label: "Россия", value: "Russia" }]}
                placeholder="Выберите"
              />
            </Form.Item>
          </div>
          <div className="two-el">
            <Form.Item
              className="input-wrapper two-el__children"
              label="E-mail"
              name="email"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: "false",
                },
                {
                  type: "email",
                  message: "некорректный email",
                },
              ]}
            >
              <Input className="inputt" />
            </Form.Item>
            <Form.Item
              className="input-wrapper two-el__children phone"
              label="Моб.тел"
              style={{ marginLeft: "107px" }}
              name="mobile"
              rules={[{ required: true, message: "false" }]}
            >
              <Input className="inputt" style={{ width: "173px" }} />
            </Form.Item>
          </div>
        </div>
        <Form.Item>
          <Button
            className="submit-all all3"
            disabled={!dirtyForm}
            style={{ borderRadius: "20px" }}
            type="primary"
            htmlType="submit"
            size="large"
          >
            Сохранить изменения
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default MyProfilePage;
