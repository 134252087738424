import React from 'react'
import { useLocation, Navigate } from "react-router-dom";
import { useAppSelector } from "../hooks/reduxTypesHooks";

interface Props {
  children: any,
}

const AuthMiddleware: React.FC<Props> = ({children}) => {
  const location = useLocation();
  const {isAuth} = useAppSelector(state => state.user);
  
  if(isAuth === false) {
    return <Navigate to="/auth" state={{from: location}} />
  }
  return children;
}

export {AuthMiddleware};