import React, { useEffect, useState } from "react";
import { Theme, presetGpnDefault } from "@consta/uikit/Theme";
import Header from "./Components/Header/Header";
import "antd/dist/antd.min.css";
import "./App.less";
import "./scss/style.scss";
import Authorization from "./pages/Authorization/Authorization";
import { Route, Routes } from "react-router-dom";
import MainPage from "./pages/MainPage/MainPage";
import { IntlProvider } from "react-intl";
import HeaderAuth from "./Components/Header/HeaderAuth";
import { changeIsAuthToFalse, checkAuthThunk } from "./Store/userSlice/userSlice";
import { greenPreset } from "./ui-presets/presetsTheme/greenPreset";
import { goldPreset } from "./ui-presets/presetsTheme/goldPreset";
import dayjs from 'dayjs';
import {
  changeCompIsReg,
  fetchAllRegionsThunk,
  fetchGetMyCompanyThunk,
} from "./Store/contractorsSlice/contractorsSlice";
import ruRu from "antd/lib/locale-provider/ru_RU";
import { AuthMiddleware } from "./hoc/AuthMiddleware";
import { fetchGetCategoriesThunk } from "./Store/catalogSlice/catalogSlice";
import { useAppDispatch, useAppSelector } from "./hooks/reduxTypesHooks";
import LocaleProvider from "antd/lib/locale-provider";
import { ConfigProvider } from 'antd';


const App: React.FC = ():JSX.Element => {
   
  const dispatch = useAppDispatch();
  const [authStage, setAuthStage] = useState(1);
  const [sidebarActive, setSidebarActive] = useState(true);
  const langFromRedux = useAppSelector((state) => state.lang.lang);
  const { isAuth } = useAppSelector((state) => state.user);
  const { id } = useAppSelector((state) => state.contractors?.myCompanyDate?.MyComp);

  useEffect(() => {
    if (isAuth) {
      dispatch(fetchGetCategoriesThunk());
      dispatch(fetchAllRegionsThunk());
      dispatch(fetchGetMyCompanyThunk());
    }
  }, [isAuth, dispatch]);

  useEffect(() => {
    console.log("change Lang");
    localStorage.setItem("lang", langFromRedux.locale);
  }, [langFromRedux]);

  console.log("id=================", id);
  useEffect(() => {
    !localStorage.getItem("lang") && console.log("change Lang");
    !localStorage.getItem("lang") && localStorage.setItem("lang", langFromRedux.locale);
  }, [langFromRedux]);

  useEffect(() => {
    console.log("checkAuth");
    if (localStorage.getItem("token")) {
      dispatch(checkAuthThunk());
    } else {
      dispatch(changeIsAuthToFalse());
    }
  }, [dispatch]);

  useEffect(() => {
    if (isAuth) {
      if ((id === null || typeof id === "string") && id !== undefined) {
        dispatch(changeCompIsReg(false));
      } else if (id && id !== undefined) {
        dispatch(changeCompIsReg(true));
      }
    }
  }, [id, isAuth, dispatch]);

  return (

        
      <IntlProvider locale={langFromRedux.locale} messages={langFromRedux.langFile}>
        <div className="app">
          <Routes>
            <Route path="/auth" element={<HeaderAuth />} />
            <Route
              path="/*"
              element={
                <AuthMiddleware>
                  <Header setSidebarActive={setSidebarActive} />
                </AuthMiddleware>
              }
            />
          </Routes>
          <Routes>
            <Route
              path="auth"
              element={
                <Authorization authStage={authStage} setAuthStage={setAuthStage} />
              }
            />
            <Route
              path="/*"
              element={
                <AuthMiddleware>
                  <MainPage
                    sidebarActive={sidebarActive}
                    setSidebarActive={setSidebarActive}
                  />
                </AuthMiddleware>
              }
            />
          </Routes>
        </div>
      </IntlProvider>

  );
};

export default App;
