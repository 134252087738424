import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ShippingService from "../../services/ShippingService";
import {
  IRequestCalcDistance,
  IRequestCalcOrders,
  IRequestCalcPrice,
  IResponseCalcDistance,
  IResponseCalcOrders,
  IResponseCalcPrice,
  IResponseShipCultures,
  IShippingState,
} from "./interface";
const initialState: IShippingState = {
  cultureTypes: {
    statusShipCultures: null,
    errorShipCultures: null,
    cultures: {},
  },
  distance: {
    distanceValue: null,
    errorDistance: null,
    statusDistance: null,
  },
  trips: {
    statusTrips: null,
    errorTrips: null,
    data: {},
  },
  price: {
    statusPrice: null,
    errorPrice: null,
    data: {},
  },
  regions: {
    statusRegions: null,
    errorRegions: null,
    data: {},
  },
};

export const fetchGetShippingCultureTypesThunk = createAsyncThunk<
  IResponseShipCultures,
  undefined,
  { rejectValue: string }
>("shipping/fetchGetShippingCultureThunk", async function (_, { rejectWithValue }) {
  try {
    const response = await ShippingService.getShippingCultures();
    return response.data as IResponseShipCultures;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const fetchGetAgrogoRegionsThunk = createAsyncThunk<
  undefined,
  undefined,
  { rejectValue: string }
>("shipping/fetchGetAgrogoRegionsThunk", async function (_, { rejectWithValue }) {
  try {
    const response = await ShippingService.getRegions();
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const fetchCalcDistanceThunk = createAsyncThunk<
  IResponseCalcDistance,
  IRequestCalcDistance,
  { rejectValue: string }
>("shipping/fetchCalcDistanceThunk", async function (data, { rejectWithValue }) {
  try {
    const response = await ShippingService.calcDistance(data);
    return response.data as IResponseCalcDistance;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const fetchCalcPriceThunk = createAsyncThunk<
  IResponseCalcPrice,
  IRequestCalcPrice,
  { rejectValue: string }
>("shipping/fetchCalcPriceThunk", async function (data, { rejectWithValue }) {
  try {
    const response = await ShippingService.calcPrice(data);
    return response.data as IResponseCalcPrice;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});
export const fetchCalcTripsThunk = createAsyncThunk<
  IResponseCalcOrders,
  IRequestCalcOrders,
  { rejectValue: string }
>("shipping/fetchCalcTripsThunk", async function (data, { rejectWithValue }) {
  try {
    const response = await ShippingService.calcTrips(data);
    return response.data as IResponseCalcOrders;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

const shippingSlice = createSlice({
  name: "shipping",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchGetShippingCultureTypesThunk.pending, (state) => {
        state.cultureTypes.statusShipCultures = "loading";
        state.cultureTypes.errorShipCultures = null;
      })
      .addCase(fetchGetShippingCultureTypesThunk.fulfilled, (state, action) => {
        state.cultureTypes.statusShipCultures = "resolvedGetShipCultures";
        state.cultureTypes.cultures = action.payload;
      })
      .addCase(fetchGetShippingCultureTypesThunk.rejected, (state, action) => {
        state.cultureTypes.statusShipCultures = "rejected";
        state.cultureTypes.errorShipCultures = action.payload;
      })
      .addCase(fetchCalcDistanceThunk.pending, (state) => {
        state.distance.statusDistance = "loading";
        state.distance.errorDistance = null;
      })
      .addCase(fetchCalcDistanceThunk.fulfilled, (state, action) => {
        state.distance.statusDistance = "resolvedCalcDistance";
        state.distance.distanceValue = action.payload.distance;
      })
      .addCase(fetchCalcDistanceThunk.rejected, (state, action) => {
        state.distance.statusDistance = "rejected";
        state.distance.errorDistance = action.payload;
      })
      .addCase(fetchCalcPriceThunk.pending, (state) => {
        state.price.statusPrice = "loading";
        state.price.errorPrice = null;
      })
      .addCase(fetchCalcPriceThunk.fulfilled, (state, action) => {
        state.price.statusPrice = "resolvedCalcPrice";
        state.price.data = action.payload;
      })
      .addCase(fetchCalcPriceThunk.rejected, (state, action) => {
        state.price.statusPrice = "rejected";
        state.price.errorPrice = action.payload;
      })
      .addCase(fetchCalcTripsThunk.pending, (state) => {
        state.trips.statusTrips = "loading";
        state.trips.errorTrips = null;
      })
      .addCase(fetchCalcTripsThunk.fulfilled, (state, action) => {
        state.trips.statusTrips = "resolvedCalcTrips";
        state.trips.data = action.payload;
      })
      .addCase(fetchCalcTripsThunk.rejected, (state, action) => {
        state.trips.statusTrips = "rejected";
        state.trips.errorTrips = action.payload;
      })
      .addCase(fetchGetAgrogoRegionsThunk.pending, (state) => {
        state.regions.statusRegions = "loading";
        state.regions.errorRegions = null;
      })
      .addCase(fetchGetAgrogoRegionsThunk.fulfilled, (state, action) => {
        state.regions.statusRegions = "resolvedGetRegions";
        state.regions.data = action.payload;
      })
      .addCase(fetchGetAgrogoRegionsThunk.rejected, (state, action) => {
        state.regions.statusRegions = "rejected";
        state.regions.errorRegions = action.payload;
      });
  },
});

//export const {} = shippingSlice.actions;

export default shippingSlice.reducer;
