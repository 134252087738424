import { createSlice } from "@reduxjs/toolkit";
import English from "../../lang/en.json";
import Russian from "../../lang/ru.json";

interface LangState {
  lang: {
    locale: string,
    langFile: any,
  }
}
const initialState: LangState = {
  lang: localStorage.getItem('lang') ?
    {locale: localStorage.getItem('lang') === 'ru-RU' ? 'ru-RU' : 'en-EN', langFile: localStorage.getItem('lang') === 'ru-RU' ? Russian : English}
    :
    {locale: navigator.language === 'ru-RU' ? 'ru-RU' : 'en-EN', langFile: navigator.language === 'ru-RU' ? Russian : English }
  ,
}

const langSlice = createSlice({
  name: "lang",
  initialState,
  reducers: {
    changeLangToRU(state) {
      localStorage.setItem('lang', 'ru-RU')
      state.lang = {
        locale: 'ru-RU',
        langFile: Russian,
      };
    },
    changeLangToEN(state) {
      localStorage.setItem('lang', 'en-EN')
      state.lang = {
        locale: 'en-EN',
        langFile: English,
      };
    }
  },
});

export const {changeLangToRU, changeLangToEN } = langSlice.actions;

export default langSlice.reducer;
