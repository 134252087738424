import { Button } from "antd";
import React, { useState, useRef } from "react";
import $api, { API_URL } from "../../http";
import "./index.css";

interface FileFromInput {
  name: string;
  size: number;
  type: string;
  lastModified: number;
  lastModifiedDate?: any;
  webkitRelativePath?: string;
}
const Upp: React.FC = () => {
  const filePicker = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<FileFromInput | null>(null);
  const [buf, setBuf] = useState<string | ArrayBuffer | null>(null);
  const [uploaded, setUploaded] = useState(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.files);
    setSelectedFile(event.target.files![0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert("please select a file");
      return;
    }

    function fileToBinary(chosenFile) {
      let file = chosenFile;
      let reader = new FileReader();

      // reader.readAsArrayBuffer(Blob);
      reader.onloadend = function () {
        console.log("RESULT", reader.result);
        setBuf(reader.result);
        console.log("buf", buf);
      };
      reader.readAsArrayBuffer(file);
    }

    fileToBinary(selectedFile);
    console.log("buf", buf);

    // const formData = new FormData();
    // formData.append('file',selectedFile as File);
    // const formData = (selectedFile)

    const config = {
      headers: {
        "Content-Type": `${selectedFile?.type}`,
        "Content-Disposition": `attachment; filename="${selectedFile?.name}"`,
      },
    };
    const res = await $api.post(
      `${API_URL}/api/v1/files/upload/`,
      { mode: "raw", raw: buf },
      config
    );
    // const headers = {
    //   // "Access-Control-Allow-Origin": "*",
    //   // "Access-Control-Allow-Credentials": "true",
    //   // "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
    //   // "Access-Control-Allow-Headers":  "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
    //   'Authorization': `Token ${localStorage.getItem('token')}`,
    //   'Accept-Language': 'ru-RU',
    //   'Content-Type': `${selectedFile?.type}`,
    //   'Content-Disposition':`attachment; filename="${selectedFile?.name}"`,

    // }
    // console.log('preheaders ---', headers)

    // const res  = await fetch(`${API_URL}/api/v1/files/upload/`, {
    //   method: 'POST',
    //   headers,
    //   body: { mode: 'raw', raw: rawData},
    // })
    // console.log(res)

    const data = await res.data;
    setUploaded(data);
    console.log(uploaded);
  };

  const handlePick = () => {
    filePicker.current?.click();
  };

  return (
    <div style={{ display: "flex", margin: "50px", gap: "50px" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Button onClick={handlePick}>Pick file</Button>
        <input
          type="file"
          className="hiddenInput"
          ref={filePicker}
          onChange={handleChange}
          accept="image/*, .png, .jpg, .gif, .pdf"
        />
        {selectedFile && (
          <ul>
            <li>Name: {selectedFile?.name}</li>
            <li>Type: {selectedFile?.type}</li>
            <li>Size: {selectedFile?.size}</li>
            <li>
              lastModifiedDate: {selectedFile?.lastModifiedDate?.toLocaleDateString()}
            </li>
          </ul>
        )}
      </div>
      <Button onClick={handleUpload}>Upload now!</Button>
    </div>
  );
};

export default Upp;
