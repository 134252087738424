import React, { useRef } from "react";
import Sider from "antd/lib/layout/Sider";
import { Route, Routes } from "react-router-dom";
import MainContent from "../../Components/MainPageComponents/MainContent/MainContent";
import SideBar from "../../Components/MainPageComponents/SideBar/SideBar";
import { CheckRegCompMiddlware } from "../../hoc/CheckRegCompanyMiddleware";
import { useAppSelector } from "../../hooks/reduxTypesHooks";
import AddNewLot from "../AddLotPage/AddNewLot";
import CurrentLotPage from "../CurrentLotPage/CurrentLotPage";
import MyProfilePage from "../MyProfilePage/MyProfilePage";
import RegCompanyPage from "../RegistrationProfileCompanyPage/RegCompanyPage";
import "./mainPage.scss";
import Upp from "../../Components/Upload/Upp";
import { useAutoClose } from "../../hooks/useAutoClose";
import AddCounterOffer from "../AddCounterOfferPage/AddCounterOffer";
import MobileFilterPage from "../../Components/MainPageComponents/MainContent/Middle/MiddleCenter/components/MobileList/FiltersPage/MobileFilterPage";
import MyOffersPage from "../MyOffersPage/MyOffersPage";
import ShippingCalculatorPage from "../ShippingCalculator/ShippingCalculatorPage";
import InformerPage from "../InformerPage/InformerPage";
import AddUserPage from "../ProfileCompanyPage/AddUserPage";
import ProfileCompanyMain from "../ProfileCompanyPage/ProfileCompanyMain";
import ChangeProfileCompany from "../ProfileCompanyPage/ChangeProfileCompany";
import MobileCurrentLotBIds from "../../Components/MyOffersPageComponents/MobileCurrentLotBids";
import { RootState } from "../../Store/store";
import useWindowDimensions from '../../hooks/useWindowDimensions';

interface Props {
  sidebarActive: boolean;
  setSidebarActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const MainPage: React.FC<Props> = ({ sidebarActive, setSidebarActive }): JSX.Element => {
  const { burgerState } = useAppSelector((state: RootState) => state.burgerState);
    const {width } = useWindowDimensions()
  const menu = useRef(null);
  useAutoClose("menu", { menu });
  return (
    <div className="fullbox">
      <div
        id="menu"
        aria-labelledby="menu-button"
        className="fullbox__sidebar"
        ref={menu}
        aria-hidden={!burgerState}
        style={burgerState ? { display: "block" } : { display: "none" }}
      >
        <Sider
          className="sider-sidebar"
          trigger={null}
          collapsible
          width="250px"
          collapsed={!sidebarActive}
        >
          <SideBar sidebarActive={sidebarActive} setSidebarActive={setSidebarActive} />
        </Sider>
      </div>
      <Routes>
        <Route path="/" element={<MainContent />} />
        <Route path="/mobileFilters" element={<MobileFilterPage />} />
        <Route
          path="/lot/:id"
          element={
            <CheckRegCompMiddlware>
              <CurrentLotPage />
            </CheckRegCompMiddlware>
          }
        />
        <Route
          path="/newlot"
          element={
            <CheckRegCompMiddlware>
              <AddNewLot />
            </CheckRegCompMiddlware>
          }
        />
        <Route
          path="/profileMyCompany"
          element={
            <CheckRegCompMiddlware>
              <ProfileCompanyMain />
            </CheckRegCompMiddlware>
          }
        />
        <Route
          path="/changeMyCompanyInfo"
          element={
            <CheckRegCompMiddlware>
              <ChangeProfileCompany />
            </CheckRegCompMiddlware>
          }
        />
        <Route
          path="/addNewUser"
          element={
            <CheckRegCompMiddlware>
              <AddUserPage />
            </CheckRegCompMiddlware>
          }
        />
        <Route path="/myProfile" element={<MyProfilePage />} />
        <Route path="/regCompany" element={<RegCompanyPage />} />
        <Route path="/upload" element={<Upp />} />
        <Route
          path="/counterOffer"
          element={
            <CheckRegCompMiddlware>
              <AddCounterOffer />
            </CheckRegCompMiddlware>
          }
        />
        {/*<Route
          path="/myOffers"
          element={
            <CheckRegCompMiddlware>
              <MyOffersPage tableType="counterOffer" />
            </CheckRegCompMiddlware>
          }
        />*/}
        <Route
          path="/mySellAuctions"
          element={
            <CheckRegCompMiddlware>
              <MyOffersPage tableType="sell" />
            </CheckRegCompMiddlware>
          }
        />
        <Route
          path="/myBuyAuctions"
          element={
            <CheckRegCompMiddlware>
              <MyOffersPage tableType="buy" />
            </CheckRegCompMiddlware>
          }
        />
        <Route
          path="/mobileCurrentLotBids/:id"
          element={
            <CheckRegCompMiddlware>
              <MobileCurrentLotBIds />
            </CheckRegCompMiddlware>
          }
        />
        {width > 576 && (
          <Route
            path="/shippingCalculator"
            element={
              <CheckRegCompMiddlware>
                <ShippingCalculatorPage />
              </CheckRegCompMiddlware>
            }
          />
        )}

        <Route
          path="/informer"
          element={
            <CheckRegCompMiddlware>
              <InformerPage />
            </CheckRegCompMiddlware>
          }
        />
      </Routes>
    </div>
  );
};

export default MainPage;
