import { Button, Input, Spin } from "antd";
import { parsePhoneNumber } from "libphonenumber-js";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypesHooks";
import {
  addPropertiesUser,
  authEmailThunk,
  authMobThunk,
  changeStatusToNull,
  checkAuthThunk,
  loginThunk,
  registrationThunk,
} from "../../Store/userSlice/userSlice";
import backBtn from "../../Svgs/backBtnR2.svg";
import mainLogoMob from "../../Svgs/mainLogoMob.svg";

interface Props {
  selectedMenu: { reg: boolean; login: boolean };
  setAuthStage: React.Dispatch<React.SetStateAction<number>>;
}
const SmsCodeBlock: React.FC<Props> = ({ selectedMenu, setAuthStage }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, status, error } = useAppSelector((state) => state.user);
  const { mobile, email, country } = user;
  const [codeInput, setCodeInput] = useState("");
  const [validInput, setValidInput] = useState(false);

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCodeInput(e.target.value);
    const validCompare = String(e.target.value).match(/[0-9]{6}/);
    if (validCompare && e.target.value.length === 6) {
      setValidInput(true);
    } else {
      setValidInput(false);
    }
  };

  const repeatCodeHandler = () => {
    country === "Russia"
      ? dispatch(authMobThunk(mobile))
      : dispatch(authEmailThunk(email));
  };

  const rolesOrMainHandler = () => {
    dispatch(
      addPropertiesUser({
        code: codeInput,
      })
    );
    if (selectedMenu.reg) {
      dispatch(
        registrationThunk(
          mobile ? { mobile, token: codeInput } : { email, token: codeInput }
        )
      );
    } else {
      dispatch(
        loginThunk(mobile ? { mobile, token: codeInput } : { email, token: codeInput })
      );
    }
  };

  useEffect(() => {
    status === "resolvedAuth1" && dispatch(changeStatusToNull());
    if (status === "resolvedRegistration") {
      setAuthStage(4);
    }
    if (status === "resolvedLogin") {
      dispatch(checkAuthThunk());
      navigate("/");
      setAuthStage(1);
    }
  }, [status, navigate, setAuthStage, dispatch]);

  useEffect(() => {
    error && setCodeInput("");
  }, [error]);
    const formatPhoneNumber = (phoneNumber: string): string | undefined => {
      if (!phoneNumber) {
        return "Нет телефона";
      } else {
        const parsed = parsePhoneNumber(phoneNumber, "RU");
        return parsed.formatNational().replace(/^8/, "+7");
      }
    };
  return (
    <>
      <div className="authblock__top">
        <img src={mainLogoMob} alt="CompanyLogo" className="authblock__main-logo" />
        <h4 className="authblock__title">Авторизация</h4>
        <div className="authblock__s3text">
          {country === "Russia"
            ? `Мы отправили 6-тизначный код авторизации на Ваш номер ${formatPhoneNumber(
                mobile
              )}`
            : `Мы отправили 6-тизначный код авторизации на Ваш электронный адрес ${email}`}
        </div>
        <p className="authblock__s3smstext">SMS-код</p>
        <Input
          value={codeInput}
          onChange={inputHandler}
          maxLength={6}
          type="text"
          className="authblock__s3input"
        />
        <p
          className="authblock__s3warning"
          style={error && codeInput === "" ? { color: "red" } : { color: "transparent" }}
        >
          Неверный код
        </p>
        <div className="authblock__s3repeat" onClick={repeatCodeHandler}>
          {status === "loadingAuth1" ? <Spin /> : "Отправить код еще раз"}
        </div>
      </div>
      <div className="authblock__s3bottom">
        <img
          className="back__btn"
          
          src={backBtn}
          alt="backBtn"
          onClick={() => setAuthStage(2)}
        />
        <Button
          type="primary"
                  disabled={!validInput}
                  
          className="authblock__submit-btn"
          onClick={rolesOrMainHandler}
        >
          {status === "loading" ? <Spin /> : "Продолжить"}
              </Button>
              
      </div>
    </>
  );
};

export default SmsCodeBlock;
