import { Breadcrumb, PageHeader, Tabs } from "antd";
import React, { useState } from "react";
import NewLotPageRightbar from "../../Components/newLotPageComponents/newLotRightbar";
import BuyForm from "../../Components/newLotPageComponents/BuyForm/BuyForm";
import SellForm from "../../Components/newLotPageComponents/sellForm/SellForm";
import { Link } from "react-router-dom";
import "./newLot.scss";

const AddNewLot: React.FC = () => {
  const [infoBlockStatus, setInfoBlockStatus] = useState<boolean>(false);
  return (
    <div className="fullbox__main">
      <PageHeader
        className="site-page-header1"
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/" style={{ color: "gray" }}>
                Главная
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Добавить лот</Breadcrumb.Item>
          </Breadcrumb>
        }
      />
      <div className="buysell-content">
        <div className="newlot-block">
          <div
            className="info-button"
            onClick={() => setInfoBlockStatus((prev) => !prev)}
          >
            О компании
          </div>
          <div
            className="info-button-block"
            style={infoBlockStatus ? { display: "block" } : { display: "none" }}
          >
            <NewLotPageRightbar />
          </div>
          <Tabs
            className="newlot-block-inner"
            defaultActiveKey="1"
            onClick={() => setInfoBlockStatus(false)}
            // onChange={onChange}
            items={[
              {
                label: "Продажа",
                key: "1",
                children: <SellForm />,
              },
              {
                label: `Покупка`,
                key: "2",
                children: <BuyForm />,
              },
            ]}
          />
        </div>
        <div className="newlot-infoblock ">
          <NewLotPageRightbar />
        </div>
      </div>
    </div>
  );
};

export default AddNewLot;
