import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypesHooks";
import {
  fetchGetMyOfferLotsThunk,
  firstSellRequestChange,
} from "../../Store/marketSlice/marketSlice";
import MobileCard from "../MobileLotCard/MobileCard";
import styles from "./mobileMyOfferComponent.module.scss";
import uuid from "react-uuid";
import { RootState } from "../../Store/store";
import { useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useMemo } from "react";
import { Spin } from "antd";
import { fetchGetAgrogoRegionsThunk } from '../../Store/shippingSlice/shippingSlice';

const MobileMyOfferComponent = ({ companyId }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  let type = location.pathname === "/mySellAuctions" ? "sell" : ("buy" as "sell" | "buy");
  const [pageCount, setPageCount] = useState<number>(1);
  //const [shouldRequestNewPage, setShouldRequestNewPage] = useState<boolean>(false);

  const listRef = useRef<HTMLDivElement | null>(null);

  const myOfferLotsState: any = useAppSelector((state) => state.market?.myOfferLots);

  const myOfferLotsStatus: string | null = useAppSelector(
    (state) => state.market.myOfferLots.status
  );
  const IsFirstRequest = useAppSelector(
    (state) => state.market.myOfferLots.isFirstRequest
  );
  const nextPage: string = useAppSelector(
    (state: RootState) => state.market.myOfferLots.data.next
  );
  const query = useAppSelector((state) => state.market.myOfferLots.query);

  const { width } = useWindowDimensions();
  const checkSize = useMemo(() => {
    if (width && width < 500) {
      return "s";
    } else if (width && width >= 500 && width <= 1080) {
      return "m";
    } else if (width && width > 1080 && width <= 1350) {
      return "l";
    }
  }, [width]);
    
    useEffect(() => {
      dispatch(fetchGetAgrogoRegionsThunk());
    }, []);

  useEffect(() => {
    setPageCount(1);
    window.scrollTo(0, 0);

    dispatch(
      fetchGetMyOfferLotsThunk({
        company: companyId.id,
        type,
        counterOffer: false,
        ordering: "-created",
      })
    );
    dispatch(firstSellRequestChange(false));
  }, [type]);

  useEffect(() => {
    const ref = listRef.current;
    if (ref !== null) {
      const options = {
        rootMargin: "0px",
        threshold: 0.1,
      };

      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting && nextPage) {
          setPageCount((prev) => prev + 1);
          console.log("NEXT PAGE", nextPage);
          dispatch(
            fetchGetMyOfferLotsThunk({
              company: companyId.id,
              type,
              counterOffer: false,
              ordering: "-created",
              page: pageCount,
            })
          );
        }
      }, options);

      if (ref?.lastElementChild) {
        observer.observe(ref?.lastElementChild);
      }

      return () => {
        if (ref?.lastElementChild) {
          observer.unobserve(ref?.lastElementChild);
        }
      };
    }
  });
  const handleNavigate = async (lot) => {
    navigate(`/mobileCurrentLotBids/${lot.id}`, { state: { type: lot.type } });
  };
  const myOfferMobileCards: JSX.Element[]  = myOfferLotsState.data.results.map((lot, i) => (
    <div key={uuid()} onClick={() => handleNavigate(lot)}>
      <MobileCard lot={lot} type={type} checkSize={checkSize} myLots={true} />
    </div>
  ));
  if (myOfferLotsStatus === "resolvedGetMyOfferLots") {
    return (
      <div className={styles.fullbox_mobile} ref={listRef}>
        <p className={styles.fullbox_mobile__header}>
          Мои аукционы на {type === "sell" ? "продажу" : "покупку"}
        </p>

        {myOfferMobileCards.length
          ? myOfferMobileCards
          : "У вас нет созданных лотов! Вы можете создать их во вкладке 'Добавить лот'"}
      </div>
    );
  }
  return (
    <div className={styles.fullbox_mobile} ref={listRef}>
      <p className={styles.fullbox_mobile__header}>
        Мои аукционы на {type === "sell" ? "продажу" : "покупку"}
      </p>

      {myOfferMobileCards.length ? myOfferMobileCards : <Spin />}
    </div>
  );
};
export default MobileMyOfferComponent;
