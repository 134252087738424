import { AxiosResponse } from "axios";
import $api, { $auth } from "../http";
import {
  ChangeProfileComp,
  PutMyComp,
  ResponseCompany,
} from "../Store/contractorsSlice/interfaces";

export default class ContractorsService {
  static async suggestBankCode(bankCode) {
    return $api.post("/api/v1/contractors/suggest/bank/", { bankCode });
  }
  static async suggestCompany(companyName) {
    return $api.post("/api/v1/contractors/suggest/company/", { query: companyName });
  }
  static async sendInvite(data) {
    return $api.post("/api/v1/contractors/invite/", data);
  }
  static async getCompanyMy(): Promise<AxiosResponse<ResponseCompany>> {
    return $api.get<ResponseCompany | any>("/api/v1/contractors/company/my/");
  }
  static async putCompanyMy(
    companyAttributes: PutMyComp
  ): Promise<AxiosResponse<ResponseCompany>> {
      console.log(companyAttributes);
      const headers = {
        "Accept-Language": "ru-ru",
      };
    return $api.put<ResponseCompany | any>(
      "/api/v1/contractors/company/my/",
      companyAttributes, {headers}
    );
  }
  static async changeProfileCompanyMy(
    companyAttributes: ChangeProfileComp
  ): Promise<AxiosResponse<ResponseCompany>> {
      console.log(companyAttributes);
      const headers = {
        "Accept-Language": "ru-ru", 
      };
    return $api.patch<ResponseCompany | any>(
      "/api/v1/contractors/company/my/",
      companyAttributes, {headers}
    );
  }
  static async getCompanyTypes() {
    return $api.get("/api/v1/contractors/company_type/");
  }
  static async getRegions() {
    return $api.get("/api/v1/contractors/regions/");
  }
  static async getCountry() {
    return $auth.get("/api/v1/contractors/countries/");
  }
}
