import React from 'react'
import { useLocation, Navigate } from "react-router-dom";
import { useAppSelector } from "../hooks/reduxTypesHooks";


interface Props {
  children: any,
}

const CheckRegCompMiddlware: React.FC<Props> = ({children}) => {
  const location = useLocation();
  const {CompIsReg} = useAppSelector(state => state.contractors?.myCompanyDate);

  if(CompIsReg === false) {
    return <Navigate to="/regCompany" state={{from: location}} />
  }
  return children;
}

export {CheckRegCompMiddlware};