import { Breadcrumb, PageHeader, Tabs } from "antd";
import React, { useState } from "react";
import { FC } from "react";
import { Link } from "react-router-dom";
import CounterOfferForm from "../../Components/CouterOfferPageComponents/CounterOfferForm/CounterOfferForm";
import NewLotPageRightbar from "../../Components/newLotPageComponents/newLotRightbar";

const AddCounterOffer: FC = (): JSX.Element => {
  const [infoBlockStatus, setInfoBlockStatus] = useState<boolean>(false);
  return (
    <div className="fullbox__main">
      <PageHeader
        className="site-page-header1"
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/" style={{ color: "gray" }}>
                Главная
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Создание предложения</Breadcrumb.Item>
          </Breadcrumb>
        }
      />
      <div className="buysell-content">
        <div className="newlot-block">
          <div
            className="info-button"
            onClick={() => setInfoBlockStatus((prev) => !prev)}
          >
            О компании
          </div>
          <div
            className="info-button-block"
            style={infoBlockStatus ? { display: "block" } : { display: "none" }}
          >
            <NewLotPageRightbar />
          </div>
          <Tabs
            className="newlot-block-inner"
            defaultActiveKey="1"
            // onChange={onChange}
            items={[
              {
                label: "Запрос предложения",
                key: "1",
                children: <CounterOfferForm />,
              },
            ]}
          />
        </div>
        <div className="newlot-infoblock">
          <NewLotPageRightbar />
        </div>
      </div>
    </div>
  );
};

export default AddCounterOffer;
