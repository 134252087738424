import { useCallback, useEffect } from "react";
import { closeBurgerState } from "../Store/burgerMenuSlice/burgerSlice";
import { useAppDispatch, useAppSelector } from "./reduxTypesHooks";

export const useAutoClose = (type, { menu }) => {
  const { burgerState } = useAppSelector((state) => state.burgerState);
  const dispatch = useAppDispatch();
  const handleClosure = useCallback(
    (event) =>
      !menu.current.contains(event.target) &&
      burgerState !== false &&
      dispatch(closeBurgerState()),
    [dispatch, burgerState, menu]
  );
  useEffect(() => {
    window.addEventListener("click", handleClosure);
    window.addEventListener("focusin", handleClosure);

    return () => {
      window.removeEventListener("click", handleClosure);
      window.removeEventListener("focusin", handleClosure);
    };
  }, [handleClosure, menu]);
};
