import React from "react";
import styles from "./modal.module.scss";
import { Theme } from "@consta/uikit/Theme";
import { whitePreset } from "../../../ui-presets/presetsTheme/whitePreset";
import { Button } from "@consta/uikit/Button";
import { Modal } from "antd";
import { formatPhoneNumber, formatPrice, formatQuantity } from "../../../helpers/helpers";
import { RootState } from "../../../Store/store";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxTypesHooks";
import { useEffect } from "react";
import { fetchAllTypesOfCompaniesThunk } from "../../../Store/contractorsSlice/contractorsSlice";

export const companyTypes = {
  mill: "Мельничный комбинат",
  oil_extraction: "Маслоэкстракционный завод",
  bakery_plant: "Комбинат хлебопродуктов",
  brewery: "Пивоваренный  завод",
  feed_mill: "Комбикормовый завод",
  elevator: "Элеватор",
  traders: "Трейдер",
  broker: "Посредник",
  other_activities: "Иная деятельность",
};

const ModalComponent = ({ isModalVisible, handleModalClose, footer, currentBidInfo }) => {
  const regionsData = useAppSelector((state: RootState) => state.shipping.regions.data);
  const regionsStatus = useAppSelector(
    (state: RootState) => state.shipping.regions.statusRegions
  );

console.log(regionsData)

  const getRegionName = (code) => {
    if (regionsStatus === "resolvedGetRegions" && code !== undefined) {
      const region = regionsData.find((region) => region.codes.includes(code.toString()));

      return region ? region.name : code;
    } else {
      return code;
    }
  };
    
    const getType = (type) => {
        if (type) {
            return companyTypes[currentBidInfo?.company.type];
        } else {
            return type
        }
    }
  console.log("CURRENT BID", currentBidInfo);


  return (
    <Modal open={isModalVisible} onCancel={handleModalClose} footer={null}>
      <div className={styles.modal}>
        <div className={styles.modal_company}>
          <h1 className={styles.modal_company__title}>Данные об организации: </h1>
          <div className={styles.modal_company__info}>
            <div className={styles.modal_company__info__elem}>
              <span>Название:</span>
              {currentBidInfo?.company?.name}
            </div>
            <div className={styles.modal_company__info__elem}>
              <span>Расположение:</span>
              {getRegionName(currentBidInfo?.company?.region)}
            </div>
            <div className={styles.modal_company__info__elem}>
              <span>Деятельность:</span>
              {getType(currentBidInfo?.company?.type)}
            </div>
            <div className={styles.modal_company__info__elem}>
              <span>Телефон:</span>
              {formatPhoneNumber(currentBidInfo?.company?.phone)}
            </div>
          </div>
        </div>
        <div className={styles.modal_user}>
          <h1 className={styles.modal_company__title}>Представитель организации:</h1>
          <div className={styles.modal_user__info}>
            <div className={styles.modal_user__info__elem}>
              <span>ФИО:</span>
              {currentBidInfo?.user?.fullName}
            </div>
            <div className={styles.modal_user__info__elem}>
              <span>Телефон:</span>
              {formatPhoneNumber(currentBidInfo?.user?.mobile)}
            </div>
          </div>
        </div>
        <div className={styles.modal_bidInfo}>
          <div className={styles.modal_bidInfo__elem}>
            <span>По цене: </span>
            {formatPrice(currentBidInfo?.price)} ₽/т
          </div>
          <div className={styles.modal_bidInfo__elem}>
            <span>Количество тонн: </span>
            {formatQuantity(currentBidInfo?.quantity)} т
          </div>
        </div>
        <Theme preset={whitePreset}>
          <div className={styles.myLotsButtons}>
            <Button
              size="s"
              form="round"
              label="Отказаться"
              view="secondary"
              style={{ minWidth: "150px" }}
            ></Button>
            <Button
              size="s"
              form="round"
              label="Принять"
              style={{ minWidth: "150px" }}
            ></Button>
          </div>
        </Theme>
      </div>
    </Modal>
  );
};

export default ModalComponent;
