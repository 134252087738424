/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */

import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { changeLangToEN, changeLangToRU } from "../../Store/langSlice/langSlice";
import { ReactComponent as LkLogoHeader } from "../../Svgs/mainLogoMob.svg";
import { ReactComponent as MenuCloseGreen } from "../../Svgs/Menu_close_green.svg";
import { ReactComponent as MenuOpenGreen } from "../../Svgs/Menu_open_green.svg";

import { ReactComponent as Avatar } from "../../Svgs/Profile.svg";
// import avatar from "../../Svgs/avatar.svg";
import { ReactComponent as LogoutLogoHeader } from "../../Svgs/Logout.svg";
import { Button, Dropdown, Menu, Space } from "antd";
import ruLogo from "../../Svgs/RU.svg";
import enLogo from "../../Svgs/EN.svg";
import { changeBurgerState } from "../../Store/burgerMenuSlice/burgerSlice";
import { logoutThunk } from "../../Store/userSlice/userSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypesHooks";
import "./header.scss";
import { DownOutlined } from '@ant-design/icons';
interface Props {
  setSidebarActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: React.FC<Props> = ({ setSidebarActive }) => {
  const navigate = useNavigate();
  const { burgerState } = useAppSelector((state) => state.burgerState);

  const dispatch = useAppDispatch();

  const burgerHandler = () => {
      dispatch(changeBurgerState());
      console.log("CHANGE BURGER")
  };

  const lang = useAppSelector((state) => state.lang.lang.locale);

  const changeLangClick = (e: any) => {
    e.key === "1" ? dispatch(changeLangToRU()) : dispatch(changeLangToEN());
  };
  const menu = (
    <Menu
      onClick={changeLangClick}
      items={[
        {
          key: "1",

          label: "ru",
          icon: <img src={ruLogo} alt="ru" />,
        },
        {
          key: "2",
          label: "en",
          icon: <img src={enLogo} alt="en" />,
        },
      ]}
    />
  );
  const logoutHandler = () => {
    dispatch(logoutThunk());
    // return navigate('/auth')
  };

  return (
    <div className="header">
      <div className="container">
        <div className="header__inner">
          {burgerState ? (
            <MenuOpenGreen
              className="burger"
              id="menu-button"
              aria-expanded={burgerState}
              aria-controls="menu"
              onClick={(event) => {
                event.stopPropagation();
                burgerHandler();
              }}
            />
          ) : (
            <MenuCloseGreen
              className="burger"
              id="menu-button"
              aria-expanded={burgerState}
              aria-controls="menu"
              onClick={(event) => {
                event.stopPropagation();
                burgerHandler();
              }}
            />
          )}
          <NavLink to="/">
            <LkLogoHeader className="mainlogo" />
          </NavLink>
          <nav className="menu">
            <ul className="menu__list">
              <li className="menu__data" style={{ display: "flex" }}>
                <div style={{marginRight: "-14px"}}>{new Date().toLocaleDateString("ru-RU")}</div>
                <div>
                  {new Date().toLocaleTimeString("ru-RU", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </div>
              </li>
              <li className="menu__lot" onClick={() => navigate("/newlot")}>
                Добавить лот
              </li>
              <li className="menu__lk">
                <Avatar
                  className="menu__lk-logo"
                  onClick={() => navigate("/profileMyCompany")}
                />
                <p
                  className="menu__lk-text"
                  onClick={() => navigate("/profileMyCompany")}
                >
                  Личный кабинет
                </p>
              </li>
                          {/*<Dropdown overlay={menu}>
                <Button style={{border: 'none'}}>
                  <Space>
                    <img src={lang === 'ru-RU' ? ruLogo : enLogo} alt="ru" />
                    <DownOutlined />
                  </Space>
                </Button>
                </Dropdown>*/}
              <LogoutLogoHeader className="menu__logout" onClick={logoutHandler} />
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Header;
