/* eslint-disable no-useless-escape */
import { Button, Checkbox, Input, Spin } from "antd";
import { MaskedInput } from "antd-mask-input";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypesHooks";
import {
  addPropertiesUser,
  authEmailThunk,
  authMobThunk,
  changeStatusToNull,
} from "../../Store/userSlice/userSlice";
import mainLogoMob from "../../Svgs/mainLogoMob.svg";

interface Props {
  selectedMenu: { reg: boolean; login: boolean };
  setSelectedMenu: React.Dispatch<React.SetStateAction<{ reg: boolean; login: boolean }>>;
  setAuthStage: React.Dispatch<React.SetStateAction<number>>;
}

const NumberBlock: React.FC<Props> = ({
  selectedMenu,
  setSelectedMenu,
  setAuthStage,
}) => {
  const dispatch = useAppDispatch();
  const { user, status, error } = useAppSelector((state) => state.user);
  const [phoneInput, setPhoneInput] = useState("+7");
  const [emailInput, setEmailInput] = useState("");

  const [validInput, setValidInput] = useState(false);
  const [agree, setAgree] = useState(false);
  const [validForm, setValidForm] = useState(false);

  const selectRegHandler = () => {
    if (!selectedMenu.reg) {
      setSelectedMenu({ reg: true, login: false });
    }
  };
  const selectLoginHandler = () => {
    if (!selectedMenu.login) {
      setSelectedMenu({ reg: false, login: true });
    }
  };

  const inputHandler = (e: any) => {
    if (user.country === "Russia") {
      setPhoneInput(e.target.value);
      const validPhoneCompare = String(e.target.value).match(
        /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/
      );
      if (validPhoneCompare) {
        setValidInput(true);
      } else {
        setValidInput((prev) => prev && false);
      }
    } else {
      setEmailInput(e.target.value);
      const validCompare = String(e.target.value)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      if (validCompare) {
        setValidInput(true);
      } else {
        setValidInput((prev) => prev && false);
      }
    }
  };
  const agreeHandler = () => {
    setAgree((prev) => !prev);
  };

  useEffect(() => {
    if (selectedMenu.reg) {
      if (validInput && agree) {
        setValidForm(true);
      } else {
        setValidForm((prev) => prev && false);
      }
    } else {
      validInput ? setValidForm(true) : setValidForm((prev) => prev && false);
    }
  }, [validInput, agree, selectedMenu]);

  useEffect(() => {
    status === "resolvedAuth1" && setAuthStage(3);
  }, [status, setAuthStage]);

  console.log(phoneInput);

  useEffect(() => {
    if (error && phoneInput !== "") {
      console.log("------");

      setPhoneInput("");
      setEmailInput("");
    }

    if (error && phoneInput === "+7 (___) ___ __-__") {
      console.log("-=-=-=");

      dispatch(changeStatusToNull());
    }
  }, [error, dispatch, phoneInput]);

  // add functional for work with api
  const addPropToUserHandler = () => {
    if (user.country === "Russia") {
      dispatch(
        addPropertiesUser({
          authMethod: selectedMenu.reg ? "registration" : "authorization",
          agree: agree,
          mobile: "+" + phoneInput.replace(/[^0-9]/g, ""),
        })
      );
      dispatch(authMobThunk("+" + phoneInput.replace(/[^0-9]/g, "")));
    } else {
      dispatch(
        addPropertiesUser({
          authMethod: selectedMenu.reg ? "registration" : "authorization",
          email: emailInput,
          agree: agree,
        })
      );
      dispatch(authEmailThunk(emailInput));
    }
  };

  return (
    <>
      <div className="authblock__top">
        <img src={mainLogoMob} alt="CompanyLogo" className="authblock__main-logo" />
        <h4 className="authblock__title">Авторизация</h4>
        <div className="authblock__s2menu">
          <p
            className="s2menu__item"
            style={
              selectedMenu.reg
                ? {
                    color: "rgba(110, 195, 0, 1)",
                    borderBottom: "2px solid rgba(110, 195, 0, 1)",
                  }
                : {
                    color: "rgba(0, 0, 0, 0.85)",
                    borderBottom: "2px solid transparent",
                  }
            }
            onClick={selectRegHandler}
          >
            Регистрация
          </p>
          <p
            className="s2menu__item"
            style={
              selectedMenu.login
                ? {
                    color: "rgba(110, 195, 0, 1)",
                    borderBottom: "2px solid rgba(110, 195, 0, 1)",
                  }
                : {
                    color: "rgba(0, 0, 0, 0.85)",
                    borderBottom: "2px solid transparent",
                  }
            }
            onClick={selectLoginHandler}
          >
            Вход
          </p>
        </div>
        <p className="authblock__s2textphone">
          {user.country === "Russia"
            ? "Введите номер телефона"
            : "Укажите свою электронную почту"}
        </p>
        {user.country === "Russia" ? (
          <MaskedInput
            type="tel"
            onChange={(e) => inputHandler(e)}
            value={phoneInput}
            placeholder={"+7"}
            mask={"+7 (000) 000 00-00"}
            className="authblock__s2input"
          />
        ) : (
          <Input
            type="email"
            onChange={(e) => inputHandler(e)}
            value={emailInput}
            placeholder={""}
            className="authblock__s2input"
          />
        )}
        <p className="authblock__s2warning">
          <span
            className="s2warning-novisible"
            style={error ? { color: "red" } : { color: "transparent" }}
          >
            Невозможно отправить вам код для авторизации.
          </span>
        </p>
        {selectedMenu.reg && (
          <div className="agreement">
            <Checkbox checked={agree} onChange={agreeHandler} />
            <p className="agreement__text">
              Принять <a target="_blank" href="https://agro.broker/customer_agreement">
                пользовательское соглашение
              </a> и <a target="_blank" href="https://agro.broker/personal_data_policy">
                политику обработки персональных данных
              </a>
            </p>
          </div>
        )}
      </div>
      <Button
        className="authblock__submit-btn"
        disabled={!validForm && (emailInput !== "" || phoneInput !== "")}
        onClick={addPropToUserHandler}
      >
        {status === "loading" ? <Spin /> : "Продолжить"}
          </Button>
          
    </>
  );
};

export default NumberBlock;
