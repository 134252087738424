/* eslint-disable no-unused-vars */

import React, { useEffect, useMemo, useRef, useState } from "react";
import debounce from "lodash/debounce";
import { Spin, Form, Input, Button, Select, InputNumber } from "antd";
import type { SelectProps } from "antd/es/select";
import {
  addCompaniesSuggest,
  addCurrentCompanySuggest,
  fetchAllRegionsThunk,
  fetchAllTypesOfCompaniesThunk,
  fetchPutMyCompanyThunk,
} from "../../Store/contractorsSlice/contractorsSlice";
import ContractorsService from "../../services/ContractorsService";
import { getContryThunk } from "../../Store/userSlice/userSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypesHooks";
import { CurrType, PutMyComp } from "../../Store/contractorsSlice/interfaces";
import useWindowDimensions from "../../hooks/useWindowDimensions";

function validateInn(inn: string | number) {
  var result = false;
  if (typeof inn === "number") {
    inn = inn.toString();
  } else if (typeof inn !== "string") {
    inn = "";
  }
  if (!inn.length) {
    return false;
  } else if (/[^0-9]/.test(inn)) {
    return false;
  } else if ([10, 12].indexOf(inn.length) === -1) {
    return false;
  } else {
    var checkDigit = function (inn, coefficients) {
      var n = 0;
      for (var i in coefficients) {
        n += coefficients[i] * inn[i];
      }
      return parseInt(String((n % 11) % 10));
    };
    switch (inn.length) {
      case 10:
        var n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
        if (n10 === parseInt(inn[9])) {
          result = true;
        }
        break;
      case 12:
        var n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
        var n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
        if (n11 === parseInt(inn[10]) && n12 === parseInt(inn[11])) {
          result = true;
        }
        break;
      default:
        break;
    }
    if (!result) {
      return false;
    }
  }
  return result;
}

const roles = [
  {
    label: "Покупатель",
    value: "buyer",
  },
  {
    label: "Продавец",
    value: "seller",
  },
  {
    label: "Трейдер",
    value: "manufacturer",
  },
];

const countries = [
  {
    label: "Россия",
    value: "RU",
  },
];

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, "options" | "children"> {
  fetchOptions: (search: string) => Promise<ValueType | ValueType[] | any>;
  debounceTimeout?: number;
}

export function DebounceSelect<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
    inn: string | number;
    address: string;
  } = any
>({ fetchOptions, debounceTimeout = 800, ...props }: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        console.log(newOptions);
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
    >
      {options.map((el) => (
        <Select.Option value={el.value}>
          <div>
            <p style={{ fontWeight: "700", marginBottom: "-4px" }}>{el.label}</p>
            <p style={{ marginBottom: "-4px" }}>{el.value}</p>
            <p
              style={{
                width: "100%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {el.address}
            </p>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
}

interface Props {
  setActivePage: React.Dispatch<React.SetStateAction<number>>;
}

const RegistrationCompBlock: React.FC<Props> = ({ setActivePage }) => {
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const contractors = useAppSelector((state) => state.contractors);
  const { myCompanyDate, suggestCompaniesDate, typesOFCompaniesDate, AllRegionsDate } =
    contractors;

    const { suggestCompanies, currentCompany } = suggestCompaniesDate;
    
  const { allTypes, statusTypes } = typesOFCompaniesDate;

    const allTypes2:
    | { label: string | Element | JSX.Element; value: string }[]
      | CurrType[] = allTypes;
    
    const { allRegions, statusRegions } = AllRegionsDate;
    
  const allRegions2:
    | { label: string | Element | JSX.Element; value: string }[]
      | CurrType[] = allRegions;
    
  const [currentCompSelect, setCurrentCompSelect] = useState("");
  console.log(currentCompSelect);
  useEffect(() => {
    if (currentCompSelect) {
      console.log(currentCompany);
      let curr = suggestCompanies.find((el) => el.inn === currentCompSelect);
      form.setFieldsValue({
        inn: curr?.inn,
        name: curr?.name,
        address: curr?.address,
      });
      dispatch(addCurrentCompanySuggest(curr));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompSelect]);

  useEffect(() => {
    if (myCompanyDate.statusMyComp === "resolvedRegComp") {
      setActivePage(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myCompanyDate.statusMyComp]);

  const onFinishForm = (values: any) => {
    console.log("Success:", values);
    const dataForFetch: PutMyComp = {
      name: values.name,
      role: values.role,
      country: values.country,
      email: values.postAddress,
      phone: values.phone,
      region: JSON.parse(values.region).region,
      regionName: JSON.parse(values.region).regionName,
      type: JSON.parse(values.type).type,
      typeName: JSON.parse(values.type).typeName,
      legalRequisites: {
        address: currentCompany.address,
        inn: currentCompany.inn,
        kpp: currentCompany.kpp,
        ogrn: currentCompany.ogrn,
      },
    };
    console.log("data", dataForFetch);
    dispatch(fetchPutMyCompanyThunk(dataForFetch));
  };

  const onFinishFailedForm = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  async function fetchCompaniesList(companyName: string) {
    return ContractorsService.suggestCompany(companyName).then((response) => {
      dispatch(addCompaniesSuggest(response.data));
      return response.data.map((company) => ({
        label: company.name,
        value: company.inn,
        address: company.address,
        key: company.inn,
      })) as { label: string; value: string; address: string; key: string };
    });
  }

  return (
    <>
      <div className="regBlock">
        <Form
          form={form}
          layout={width! > 1250 ? "horizontal" : "vertical"}
          onFinish={onFinishForm}
          onFinishFailed={onFinishFailedForm}
          // onValuesChange={onFormLayoutChange}
        >
          <div className="profile">
            <div className="profile__inner">
              <h3 className="title">Ваша компания еще не зарегистрирована</h3>
              <p className="sub-title">
                После регистрация Вы сможете добавлять лоты и откликаться на существующие
              </p>
              <div
                className="form-profile"
                // onValuesChange={onFormLayoutChange}
              >
                {/* <Form.Item
                  name="fullName"
                  className="input-wrapper l1"
                  label="   Имя, Фамилия"
                  rules={[{ required: true, message: '' }]}
                >
                  <Input className="inputt" />
                </Form.Item> */}
                <Form.Item className="input-wrapper l1" label="Поиск компании">
                  <DebounceSelect
                    className="inputt"
                    showSearch
                    // value={currentCompSelect}
                    placeholder="Начните писать полное название..."
                    fetchOptions={fetchCompaniesList}
                    onChange={(newValue) => {
                      setCurrentCompSelect(String(newValue));
                    }}
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>

                <Form.Item
                  className="input-wrapper l1"
                  label="ИНН"
                  name="inn"
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                    {
                      message: "некорректный ИНН",
                      validator: (_, value) => {
                        if (validateInn(value)) {
                          return Promise.resolve();
                        } else {
                          console.log("validated");
                          return Promise.reject("не валидный ИНН");
                        }
                      },
                    },
                  ]}
                >
                  <InputNumber controls={false} className="inputt" />
                </Form.Item>

                <Form.Item
                  className="input-wrapper l1"
                  label="Название компании"
                  name="name"
                  rules={[{ required: true, message: "" }]}
                >
                  <Input className="inputt" />
                </Form.Item>

                <div className="two-el">
                  <Form.Item
                    className="input-wrapper two-el__children"
                    label="Страна"
                    name="country"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Select
                      className="inputt"
                      onClick={() => dispatch(getContryThunk())}
                      options={countries}
                      placeholder="Выберите"
                    />
                  </Form.Item>
                  <Form.Item
                    className="input-wrapper two-el__children "
                    label="Регион"
                    name="region"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Select
                      className="inputt"
                      onClick={
                        statusRegions !== "resolved"
                          ? () => dispatch(fetchAllRegionsThunk())
                          : undefined
                      }
                      options={
                        statusRegions === "loading"
                          ? [{ label: <Spin />, value: "1" }]
                          : allRegions2
                      }
                      placeholder="Выберите"
                    />
                  </Form.Item>
                </div>
                <div className="two-el">
                  <Form.Item
                    className="input-wrapper two-el__children "
                    label="Деятельность"
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Select
                      className="inputt"
                      placeholder="Выберите"
                      onClick={
                        statusTypes !== "resolved"
                          ? () => dispatch(fetchAllTypesOfCompaniesThunk())
                          : undefined
                      }
                      options={
                        statusTypes === "loading"
                          ? [{ label: <Spin />, value: "1" }]
                          : allTypes2
                      }
                      // defaultValue={profiles[0]}
                    />
                  </Form.Item>
                  <Form.Item
                    className="input-wrapper two-el__children "
                    label="Роль компании"
                    name="role"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Select className="inputt" options={roles} placeholder="Выберите" />
                  </Form.Item>
                </div>
                <div className="two-el">
                  <Form.Item
                    className="input-wrapper two-el__children"
                    label="E-mail компании"
                    name="postAddress"
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                      {
                        type: "email",
                        message: "некорректный email",
                      },
                    ]}
                  >
                    <Input className="inputt" />
                  </Form.Item>
                  <Form.Item
                    className="input-wrapper two-el__children"
                    label="Телефон компании"
                    name="phone"
                  >
                    <Input className="inputt" />
                  </Form.Item>
                </div>
                {/* <Form.Item
                    className="input-wrapper download"
                    valuePropName="fileList"
                    >
                    <Upload action="/upload.do" listType="picture-card">
                        <div className="download-inner">
                        <PlusOutlined />
                        <div>Документы</div>
                        </div>
                    </Upload>
                    <img className="info-circle" src={infoCirleIcon} alt="info" />
                    </Form.Item> */}
              </div>
            </div>
          </div>
          <Form.Item>
            <Button className="submit-all" type="primary" htmlType="submit">
              Cоздать профиль
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default RegistrationCompBlock;
