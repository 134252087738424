
import React, { useState } from "react";
import { PageHeader, Breadcrumb } from "antd";

import RegistrationCompBlock from "../../Components/RegCompPageComponents/RegistrationCompBlock";
import SuccesRegBlock from "../../Components/RegCompPageComponents/SuccesRegBlock";
import { Link } from "react-router-dom";
import "./regProfileComp.scss";




const RegCompanyPage: React.FC = () => {
  const [activePage, setActivePage] = useState<number>(1);
  
  return (
    <>
      <div className="fullbox__main">
        <PageHeader className="site-page-header1" breadcrumb={<Breadcrumb>
          <Breadcrumb.Item><Link to="/" style={{color: 'gray'}}>Главная</Link></Breadcrumb.Item>
          <Breadcrumb.Item>
            Регистрация компании
          </Breadcrumb.Item>
        </Breadcrumb> } />
        {
          activePage === 1 ? <RegistrationCompBlock setActivePage={setActivePage} /> : <SuccesRegBlock />
        }
      </div>
    </>
  );
};

export default RegCompanyPage;
