import axios from "axios";

export const AGROGO_API_URL = "https://driver.agrogo.pro";
const AGROGO_TOKEN = "Token c358d70e4bdc1823ed83d43bc1d6c81ca6b931b8";

const $agrogo_api = axios.create({
  baseURL: AGROGO_API_URL,
  headers: {
    Authorization: AGROGO_TOKEN,
    "Content-Type": "application/json",
  },
});

export default $agrogo_api;
