import { EnvironmentOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, PageHeader } from "antd";
import React, { DetailedHTMLProps, FC, HTMLAttributes, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import InfoBlock from "../../Components/ProfileCompanyPageComponents/InfoBlock";
import { useAppSelector } from "../../hooks/reduxTypesHooks";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import styles from "./profileCompMain.module.scss";

const roles = {
  buyer: "Покупатель",
  seller: "Продавец",
  manufacturer: "Трейдер",
};

export interface ProfileCardProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  headerText: string;
  footerText: string;
}

export const ProfileCard: FC<ProfileCardProps> = ({
  headerText,
  footerText,
}): JSX.Element => {
  return (
    <div className={styles.element}>
      <div className={styles.element__top}>
        <EnvironmentOutlined style={{ color: "#95D708", marginTop: "2px" }} />
        <p
          className={styles.cardHeaderText}
          style={{ textOverflow: "ellipsis", overflow: "hidden" }}
        >
          {headerText}
        </p>
      </div>
      <p className={styles.cardBottomText}>{footerText}</p>
    </div>
  );
};

const ProfileCompanyMain: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const contractors = useAppSelector((state) => state.contractors);
  const { width } = useWindowDimensions();
  const { myCompanyDate } = contractors;
  const { MyComp } = myCompanyDate;
  useEffect(() => {}, []);
  return (
    <div className={styles.main}>
      <div>
        <PageHeader
          className="site-page-header1"
          breadcrumb={
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/" style={{ color: "gray" }}>
                  Главная
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Профиль компании</Breadcrumb.Item>
            </Breadcrumb>
          }
        />
        <div className={styles.profile}>
          <div className={styles.profile_header}>
            <p className={styles.profile_header__top}>{MyComp.name}</p>
            <p className={styles.profile_header__bottom}>
              ИНН {MyComp.legalRequisites?.inn}
            </p>
          </div>
          <div className={styles.profile_location} style={{ marginTop: "16px" }}>
            <div className={styles.profile_location__top}>
              <EnvironmentOutlined style={{ color: "#95D708" }} />
              <p>{MyComp.legalRequisites?.address}</p>
            </div>
            <p className={styles.profile_location__bottom}>Юр. адрес</p>
          </div>
          <hr />
          <div className={styles.profile_main}>
            <div className={styles.profile_main_line}>
              <ProfileCard headerText="Россия" footerText="Страна" />
              <ProfileCard headerText={MyComp?.regionName} footerText="Регион" />
              <ProfileCard headerText={MyComp?.typeName} footerText="Деятельность" />
              <ProfileCard
                headerText={MyComp?.role && roles[MyComp.role]}
                footerText="Род компании"
              />
            </div>
            <div className={styles.profile_main_line}>
              <ProfileCard headerText={MyComp?.email} footerText="E-mail компании" />
              <ProfileCard headerText={MyComp?.phone} footerText="Телефон компании" />
              <ProfileCard
                headerText={MyComp?.webSite}
                style={{ maxWidth: "250px", overflow: "hidden" }}
                footerText="Сайт компании"
              />
              <div style={{ width: "25%" }} />
            </div>

            <div className={styles.requisites}>
              <p className={styles.requisites_title}>Реквизиты</p>
              <div className={styles.profile_main_line}>
                <ProfileCard
                  headerText={MyComp?.bankRequisites?.accountNumber}
                  footerText="Расчетный счет"
                />
                <ProfileCard
                  headerText={MyComp?.bankRequisites?.bankCode}
                  footerText="БИК"
                />
                <ProfileCard
                  headerText={MyComp?.bankRequisites?.name}
                  footerText="Наименование банка"
                />
                <div style={{ width: "25%" }} />
              </div>
            </div>
          </div>
        </div>
        <Button
          className={styles.submitButton}
          type="primary"
          size="large"
          onClick={() => navigate("/changeMyCompanyInfo")}
        >
          <div>Изменить</div>
        </Button>
      </div>
      <div className={styles.infoBlock}>{width! <= 500 ? undefined : <InfoBlock />}</div>
    </div>
  );
};

export default ProfileCompanyMain;
