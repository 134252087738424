import $api from "../http";

export default class MarketService {
  static async getIncoTerms() {
    return $api.get(`/api/v1/market/incoterms/`);
  }
  static async createLot(lotDate) {
    return $api.post(`/api/v1/market/lots/`, lotDate);
  }
  static async putUsersMe(date) {
    return $api.patch(`/api/v1/users/me/`, date);
  }
  static async deleteCurrentOffer(id) {
    return $api.delete(`/api/v1/market/lots/${id}/`);
  }
}
