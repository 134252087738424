import React, { useEffect, useMemo, useRef, useState } from "react";
import uuid from "react-uuid";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../hooks/reduxTypesHooks";
import {
  fetchGetSellLotsThunk,
  firstSellRequestChange,
} from "../../../../../../../Store/marketSlice/marketSlice";
import { RootState } from "../../../../../../../Store/store";
import MobileCard from "../../../../../../MobileLotCard/MobileCard";
import styles from "./mobileList.module.scss";
import useWindowDimensions from "../../../../../../../hooks/useWindowDimensions";
import { Link } from "react-router-dom";

const SellMobileList = ({ regionsHash, culturesHash, buyFetchData }) => {
  const dispatch = useAppDispatch();

  const [pageCount, setPageCount] = useState<number>(1);
  //const [shouldRequestNewPage, setShouldRequestNewPage] = useState<boolean>(false);

  const listRef = useRef<HTMLDivElement | null>(null);

  const sellLotsState: any = useAppSelector((state) => state.market?.sellLots);
  const sellLotsStatus: string | null = useAppSelector(
    (state) => state.market.sellLots.status
  );
  const isFirstRequest = useAppSelector((state) => state.market.sellLots.isFirstRequest);
  const nextPage: string = useAppSelector(
    (state: RootState) => state.market.sellLots.data.next
  );
  const query = useAppSelector((state) => state.market.sellLots.query);
  const { width } = useWindowDimensions();
  const checkSize = useMemo(() => {
    if (width && width < 500) {
      return "s";
    } else if (width && width >= 500 && width <= 1080) {
      return "m";
    } else if (width && width > 1080 && width <= 1350) {
      return "l";
    }
  }, [width]);
  useEffect(() => {
    if (isFirstRequest) {
      dispatch(fetchGetSellLotsThunk({}));
      dispatch(firstSellRequestChange(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const ref = listRef.current;
    if (ref !== null) {
      const options = {
        rootMargin: "0px",
        threshold: 0.1,
      };

      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting && nextPage !== null) {
          setPageCount((prev) => prev + 1);
          dispatch(
            fetchGetSellLotsThunk({
              ...query,
              page: pageCount,
            })
          );
        }
      }, options);

      if (ref?.lastElementChild) {
        observer.observe(ref?.lastElementChild);
      }

      return () => {
        if (ref?.lastElementChild) {
          observer.unobserve(ref?.lastElementChild);
        }
      };
    }
  });
  const BuyMobileCards = sellLotsState.data.results.map((lot, i) => (
    <Link to={`/lot/${lot?.id}`} key={uuid()}>
      <MobileCard
        key={uuid()}
        culture={culturesHash[lot.product.category]}
        lot={lot}
        type="sell"
        checkSize={checkSize}
      />
    </Link>
  ));

  if (sellLotsStatus === "resolvedGetSellLots") {
    return (
      <div className={styles.fullbox_mobile} ref={listRef}>
        {BuyMobileCards}
      </div>
    );
  }
  return <>{BuyMobileCards ? BuyMobileCards : "NO DATA"}</>;
};

export default SellMobileList;
