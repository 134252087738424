import React from "react";
import { formatPhoneNumber } from '../../helpers/helpers';
import { useAppSelector } from "../../hooks/reduxTypesHooks";
import { RootState } from "../../Store/store";
import rightbarLogo from "../../Svgs/avatar4040.svg";

const NewLotPageRightbar: any = (): any => {
  const { MyComp } = useAppSelector(
    (state: RootState) => state.contractors.myCompanyDate as any
  );
  const { user } = useAppSelector((state: RootState) => state.user);
  return (
    <div className="newlot-rightbar">
      <img className="newlot-rightbar__logo" src={rightbarLogo} alt="Avatar" />
      <div className="newlot-rightbar__title">
        <h3 className="title__about">Данные об организации</h3>
        <p className="title__tag">
          Название: <span className="title__subtag">{MyComp?.name}</span>
        </p>
        <p className="title__tag">
          Деятельность: <span className="title__subtag">{MyComp?.typeName}</span>
        </p>
        <p className="title__tag">
          Расположение: <span className="title__subtag">{MyComp?.regionName}</span>
        </p>
        <p className="title__tag">
          Телефон: <span className="title__subtag">{MyComp?.phone}</span>
        </p>
      </div>
      <div className="newlot-rightbar__title">
        <h3 className="title__about">Представители организации:</h3>
        <p className="title__tag">
          ФИО: <span className="title__subtag">{user?.fullName}</span>
        </p>
        <p className="title__tag">
          Телефон: <span className="title__subtag">{formatPhoneNumber(user?.mobile)}</span>
        </p>
      </div>

      {/* <div className="newlot-rightbar__title">
        <h3 className="title__about">Все лоты организации:</h3>
        <p className="title__link-tag">Продам зерновые (5)</p>
        <p className="title__link-tag">Куплю зерновые (5)</p>
      </div> */}
    </div>
  );
};

export default NewLotPageRightbar;
