import { Button, Input } from "antd";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypesHooks";
import {
  addPropertiesUser,
  checkAuthThunk,
  putDateMeThunk,
} from "../../Store/userSlice/userSlice";
import mainLogoMob from "../../Svgs/mainLogoMob.svg";

interface Props {
  setAuthStage: React.Dispatch<React.SetStateAction<number>>;
}

const FioBlock: React.FC<Props> = ({ setAuthStage }) => {
  // const {country, lang} = useSelector(state => state.user);
  const [name, setName] = useState("");
  const { country, lang, mobile } = useAppSelector((state) => state.user.user);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const addCountryAndFullname = () => {
    dispatch(addPropertiesUser({ fullname: name }));

    dispatch(
      putDateMeThunk({
        fullName: name,
        country,
        language: lang === "ru-RU" ? "ru" : "en",
        mobile,
      })
    );
    dispatch(checkAuthThunk());
    navigate("/");
    setAuthStage(1);
  };
  return (
    <>
      <div className="authblock__top">
        <img src={mainLogoMob} alt="CompanyLogo" className="authblock__main-logo" />
        <h4 className="authblock__title">Авторизация</h4>
        <p className="authblock__input-title">Укажите Ваше имя и фамилию</p>
        <Input
          style={{ borderRadius: "12px" }}
          className="authblock__language"
          onChange={(e) => setName(e.target.value)}
        />
      </div>

      <div>
        <Button
          type="primary"
          className="authblock__submit-btn"
          onClick={() => addCountryAndFullname()}
        >
          Продолжить
        </Button>
        <div style={{ marginTop: "20px", marginBottom: "-15px" }}>Демо-версия</div>
      </div>
    </>
  );
};

export default FioBlock;
