import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ContractorsService from "../../services/ContractorsService";
import {
  ChangeProfileComp,
  CurrRegion,
  CurrType,
  PutMyComp,
  ResponseCompany,
} from "./interfaces";

const initialState: any = {
  myCompanyDate: {
    MyComp: {},
    statusMyComp: null,
    errorMyComp: null,
    CompIsReg: null,
  },
  suggestCompaniesDate: {
    suggestCompanies: [],
    currentCompany: {},
    status: null,
    error: null,
  },
  suggestBankCode: {
    suggestCode: [],
    currentCode: {},
    status: null,
    error: null,
  },
  typesOFCompaniesDate: {
    allTypes: [],
    statusTypes: null,
    errorTypes: null,
  },
  AllRegionsDate: {
    allRegions: [],
    statusRegions: null,
    errorRegions: null,
  },
  sendInvite: {
    invite: [],
    statusInvite: null,
    errorInvite: null,
  },
};

export const fetchGetMyCompanyThunk = createAsyncThunk<
  ResponseCompany,
  undefined,
  { rejectValue: string }
>("contractorsCompany/fetchGetMyCompanyThunk", async function (_, { rejectWithValue }) {
  try {
    const response = await ContractorsService.getCompanyMy();
    return response.data as ResponseCompany;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});
export const fetchPutMyCompanyThunk = createAsyncThunk<
  ResponseCompany,
  PutMyComp,
  { rejectValue: string }
>(
  "contractorsCompany/fetchPutMyCompanyThunk",
  async function (companyAttributes, { rejectWithValue }) {
    try {
      const response = await ContractorsService.putCompanyMy(companyAttributes);
      return response.data as ResponseCompany;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchSendInviteThunk = createAsyncThunk<any, any, { rejectValue: string }>(
  "contractorsCompany/fetchSendInviteThunk",
  async function (data, { rejectWithValue }) {
    try {
      const response = await ContractorsService.sendInvite(data);
      return response.data as any;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchChangeProfileCompanyMyThunk = createAsyncThunk<
  ResponseCompany,
  ChangeProfileComp,
  { rejectValue: string }
>(
  "contractorsCompany/fetchChangeProfileCompanyMyThunk",
  async function (companyAttributes, { rejectWithValue }) {
    try {
      const response = await ContractorsService.changeProfileCompanyMy(companyAttributes);
      return response.data as ResponseCompany;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchAllTypesOfCompaniesThunk = createAsyncThunk<
  CurrType[],
  undefined,
  { rejectValue: string }
>(
  "contractorsCompany/fetchAllTypesOfCompaniesThunk",
  async function (_, { rejectWithValue }) {
    try {
      console.log("fetching allTypes");
      const response = await ContractorsService.getCompanyTypes();
      return response.data.map((el: { title: string; code: string }) => ({
        label: el.title,
        value: JSON.stringify({ type: el.code, typeName: el.title }),
      })) as CurrType[];
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchAllRegionsThunk = createAsyncThunk<
  CurrRegion[],
  undefined,
  { rejectValue: string }
>("contractorsCompany/fetchAllRegionsThunk", async function (_, { rejectWithValue }) {
  try {
    // console.log("fetching allRegions");
    const response = await ContractorsService.getRegions();
    return response.data.map((el: { name: string; isoCode: string }) => ({
      label: el.name,
      value: JSON.stringify({ region: el.isoCode, regionName: el.name }),
    })) as CurrRegion[];
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

const contractorsSlice = createSlice({
  name: "contractorsCompany",
  initialState,
  reducers: {
    addBankCode(state, action) {
      state.suggestBankCode.suggestCode = action.payload;
    },
    addCurrentCodeSuggest(state, action) {
      state.suggestBankCode.suggestCode = action.payload;
    },
    addCompaniesSuggest(state, action) {
      state.suggestCompaniesDate.suggestCompanies = action.payload;
    },
    addCurrentCompanySuggest(state, action) {
      state.suggestCompaniesDate.currentCompany = action.payload;
    },
    changeCompIsReg(state, action) {
      state.myCompanyDate.CompIsReg = action.payload;
    },
    changeStatusInvite(state, action) {
      state.sendInvite.statusInvite = action.payload;
    },
    changeStatusMyComp(state, action) {
      state.myCompanyDate.statusMyComp = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchGetMyCompanyThunk.pending, (state) => {
        state.myCompanyDate.statusMyComp = "loading";
        state.myCompanyDate.errorMyComp = null;
      })
      .addCase(fetchGetMyCompanyThunk.fulfilled, (state, action) => {
        state.myCompanyDate.statusMyComp = "resolvedGetMyComp";
        state.myCompanyDate.MyComp = action.payload;
      })
      .addCase(fetchGetMyCompanyThunk.rejected, (state, action) => {
        state.myCompanyDate.statusMyComp = "rejected";
        state.myCompanyDate.errorMyComp = action.payload;
      })

      .addCase(fetchPutMyCompanyThunk.pending, (state) => {
        state.myCompanyDate.statusMyComp = "loading";
        state.myCompanyDate.errorMyComp = null;
      })
      .addCase(fetchPutMyCompanyThunk.fulfilled, (state, action) => {
        state.myCompanyDate.statusMyComp = "resolvedRegComp";
        state.myCompanyDate.MyComp = action.payload;
      })
      .addCase(fetchPutMyCompanyThunk.rejected, (state, action) => {
        state.myCompanyDate.statusMyComp = "rejected";
        state.myCompanyDate.errorMyComp = action.payload;
      })

      .addCase(fetchChangeProfileCompanyMyThunk.pending, (state) => {
        state.myCompanyDate.statusMyComp = "loadingChangeCompProfile";
        state.myCompanyDate.errorMyComp = null;
      })
      .addCase(fetchChangeProfileCompanyMyThunk.fulfilled, (state, action) => {
        state.myCompanyDate.statusMyComp = "resolvedChangeCompProfile";
        state.myCompanyDate.MyComp = action.payload;
      })
      .addCase(fetchChangeProfileCompanyMyThunk.rejected, (state, action) => {
        state.myCompanyDate.statusMyComp = "rejected";
        state.myCompanyDate.errorMyComp = action.payload;
      })

      .addCase(fetchAllTypesOfCompaniesThunk.pending, (state) => {
        state.typesOFCompaniesDate.statusTypes = "loading";
        state.typesOFCompaniesDate.errorTypes = null;
      })
      .addCase(fetchAllTypesOfCompaniesThunk.fulfilled, (state, action) => {
        state.typesOFCompaniesDate.statusTypes = "resolved";
        state.typesOFCompaniesDate.allTypes = action.payload;
      })
      .addCase(fetchAllTypesOfCompaniesThunk.rejected, (state, action) => {
        state.typesOFCompaniesDate.statusTypes = "rejected";
        state.typesOFCompaniesDate.errorTypes = action.payload;
      })

      .addCase(fetchAllRegionsThunk.pending, (state) => {
        state.AllRegionsDate.statusRegions = "loading";
        state.AllRegionsDate.errorRegions = null;
      })
      .addCase(fetchAllRegionsThunk.fulfilled, (state, action) => {
        state.AllRegionsDate.statusRegions = "resolved";
        state.AllRegionsDate.allRegions = action.payload;
      })
      .addCase(fetchAllRegionsThunk.rejected, (state, action) => {
        state.AllRegionsDate.statusRegions = "rejected";
        state.AllRegionsDate.errorRegions = action.payload;
      })
      .addCase(fetchSendInviteThunk.pending, (state) => {
        state.sendInvite.statusInvite = "loading";
        state.sendInvite.errorInvite = null;
      })
      .addCase(fetchSendInviteThunk.fulfilled, (state, action) => {
        state.sendInvite.statusInvite = "resolved";
        state.sendInvite.invite = action.payload;
      })
      .addCase(fetchSendInviteThunk.rejected, (state, action) => {
        state.sendInvite.statusInvite = "rejected";
        state.sendInvite.errorInvite = action.payload;
      });
  },
});

export const {
  addCompaniesSuggest,
  addBankCode,
  addCurrentCodeSuggest,
  addCurrentCompanySuggest,
  changeCompIsReg,
  changeStatusMyComp,
  changeStatusInvite,
} = contractorsSlice.actions;

export default contractorsSlice.reducer;
