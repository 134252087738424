import { Table, TableColumn } from "@consta/uikit/Table";
import { Theme } from "@consta/uikit/Theme";
import React, { useState } from "react";
import { greenPreset } from "../../../ui-presets/presetsTheme/greenPreset";
import styles from "./offerTables.module.scss";
import cn from "classnames";
import Card from "../../MainPageComponents/MainContent/Middle/MiddleCenter/components/Card/Card";
import ApproveButton from "../buttons/ApproveButton";
import DiscardButton from "../buttons/DiscardButton";
import { Button } from "@consta/uikit/Button";
import uuid from "react-uuid";
import { whitePreset } from "../../../ui-presets/presetsTheme/whitePreset";
import CurrentPageRighbar from "../../CurrentLotPageComponents/CurrentPageRighbar";
import { parsePhoneNumber } from "libphonenumber-js";
import dayjs from "dayjs";
import { formatPrice, formatQuantity } from '../../../helpers/helpers';

const CurerntBidsTable = ({
  currentLotBids,
  handleDeleteLot,
  currentLotId,
  currentLotNumber,
}) => {
  const [infoBarActive, setInfoBarActive] = useState<boolean>(false);
  const [infoBarBidInfo, setInfoBarBidInfo] = useState<any>();
    const formatPhoneNumber = (phoneNumber: string): string | undefined => {
      if (!phoneNumber) {
        return "Нет телефона";
      } else {
        const parsed = parsePhoneNumber(phoneNumber, "RU");
        return parsed.formatNational().replace(/^8/, "+7");
      }
    };

  const handleClickCompany = (bidInfo) => {
    setInfoBarBidInfo(bidInfo);
    setInfoBarActive(true);
  };
  let rows = Array(9).fill({});
  console.log(currentLotBids);
  const info = currentLotBids.results;

  if (currentLotBids.count > 0) {
    rows = info.map((bid) => {
      console.log("BID", bid);
      return {
        id: bid.id,
        date: dayjs(bid.creared).format("DD.MM в HH:MM"),
        companyName: (
          <a
            style={{ textDecoration: "underline", color: "#567E23" }}
            onClick={() => handleClickCompany(bid)}
          >
            {bid.company.name}
          </a>
        ),
        phone: formatPhoneNumber(bid.user.mobile),
        quantity: formatQuantity(bid.quantity),
        price: formatPrice(bid.price),
        actionApprove: <ApproveButton />,
        actionDiscard: <DiscardButton />,
      };
    });
  }

  const columns: TableColumn<(typeof rows)[number]>[] = [
    {
      title: "Дата",
      accessor: "date",
      align: "left",
      width: 145,
    },
    {
      title: "Компания",
      accessor: "companyName",
      align: "left",
      width: 175,
    },
    { title: "Телефон", accessor: "phone", align: "left", width: 160 },

    { title: "Объем, т", accessor: "quantity", align: "left" },
    {
      title: "Цена, ₽/т",
      accessor: "price",
      align: "left",
      width: 130,
    },
    {
      title: "Действие",
      accessor: "actionApprove",
      align: "left",
      width: 140,
    },
    {
      title: "Действие",
      accessor: "actionDiscard",
      align: "left",
      width: 140,
    },
  ];
  return (
    <div>
      <Theme preset={whitePreset}>
        <Card
          backgroundColor="white"
          size="xl"
          style={
            currentLotBids.count > 0
              ? { display: "block" }
              : { display: "flex", alignItems: "center" }
          }
        >
          {currentLotBids.count > 0 ? (
            <div>
              <h3 className={styles.h3Tag}>Мне предложили по лоту {currentLotNumber}</h3>
              <div className={cn(styles.table_wrapper, styles.green_border)}>
                <Table
                  zebraStriped={"odd"}
                  verticalAlign="center"
                  size="s"
                  headerVerticalAlign="center"
                  className={styles.table}
                  columns={columns}
                  rows={rows}
                  borderBetweenColumns
                  borderBetweenRows
                  stickyHeader
                  emptyRowsPlaceholder=""
                  getCellWrap={(row) => "truncate"}
                />
              </div>
            </div>
          ) : (
            <>
              <h3 className={styles.h3Tag} style={{ minWidth: "500px" }}>
                По данному лоту нет откликов
              </h3>
            </>
          )}

          <div className={styles.buttons}>
            <Button
              view="primary"
              size="m"
              form="round"
              label="Завершить торги"
              onClick={() => handleDeleteLot()}
            />
          </div>
        </Card>
      </Theme>
      {infoBarActive && (
        <CurrentPageRighbar currentBid={infoBarBidInfo} layout="horizontal" />
      )}
    </div>
  );
};

export default CurerntBidsTable;
