import React, { FC, useEffect, useState } from "react";
import styles from "./myOffersPage.module.scss";
import AllOffersTable from "../../Components/MyOffersPageComponents/tables/AllOffersTable";
import CurerntBidsTable from "../../Components/MyOffersPageComponents/tables/CurrentBidsTable";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypesHooks";
import { fetchGetMyCompanyThunk } from "../../Store/contractorsSlice/contractorsSlice";
import { RootState } from "../../Store/store";
import { fetchGetMyOfferLotsThunk } from "../../Store/marketSlice/marketSlice";
import BidService from "../../services/BidsService";
import MarketService from "../../services/MarketService";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import MobileMyOfferComponent from "../../Components/MyOffersPageComponents/MobileMyOfferComponent";
import { fetchGetAgrogoRegionsThunk } from "../../Store/shippingSlice/shippingSlice";

export interface ITableType {
  tableType: "sell" | "buy";
}

const MyOffersPage: FC<ITableType> = ({ tableType }): JSX.Element => {
  const [tableActive, setTableActive] = useState<boolean>(false);
  const [currentLotBids, setCurrentLotBids] = useState<any>({});
  const [currentLotId, setCurrentLotId] = useState<number>(0);
  const dispatch = useAppDispatch();
  const { width } = useWindowDimensions();
  const myCompany = useAppSelector(
    (state: RootState) => state.contractors.myCompanyDate.MyComp
  );

  useEffect(() => {
    dispatch(fetchGetMyCompanyThunk());
  }, []);

  const getLotsData = async (id) => {
    const res = await BidService.getBid(id);
    setCurrentLotBids(res.data);
  };

  const handleDeleteLot = async () => {
    const res = await MarketService.deleteCurrentOffer(currentLotId);
    console.log(res);
    setTableActive(false);
    dispatch(
      fetchGetMyOfferLotsThunk({
        company: myCompany.id,
        type: tableType,
        counterOffer: false,
        ordering: "-created",
      })
    );
  };
  useEffect(() => {
    dispatch(fetchGetAgrogoRegionsThunk());
  }, []);
  if (myCompany.id) {
    return (
      <div className={styles.mainBlock}>
        {width > 1350 ? (
          <>
            <AllOffersTable
              tableType={tableType}
              companyId={myCompany}
              setCurrentLotId={setCurrentLotId}
              setTableActive={setTableActive}
              getLotsData={getLotsData}
            />
            {tableActive && (
              <div>
                <CurerntBidsTable
                  currentLotBids={currentLotBids}
                  handleDeleteLot={handleDeleteLot}
                  currentLotId={currentLotId}
                />
              </div>
            )}
          </>
        ) : (
          <MobileMyOfferComponent
            offerType={tableType}
            companyId={myCompany}
            setCurrentLotId={setCurrentLotId}
            currentLotBids={currentLotBids}
            getLotsData={getLotsData}
          />
        )}
      </div>
    );
  }
  return <></>;
};

export default MyOffersPage;
