import { Breadcrumb, InputNumber, PageHeader } from "antd";
import React, { FC, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import agrogoLogo from "../../Svgs/agrogoLogo.svg";
import styles from "./shippingCalculatorPage.module.scss";
import "./shippingCalculatorPage.module.scss";
import { formatQuantity } from "../../helpers/helpers";
import {
  Form,
  Input,
  Button,
  Select,
  Tooltip,
  DatePicker,
  Space,
  notification,
  Modal,
} from "antd";
import locale from "antd/lib/date-picker/locale/ru_RU";
import "moment/locale/ru";
import { EnvironmentOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import infoCircle from "../../Svgs/infoCircle.svg";
import Map from "../../Components/ShippingCalculatorComponents/Map";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypesHooks";
import { RootState } from "../../Store/store";
import {
  fetchCalcDistanceThunk,
  fetchCalcPriceThunk,
  fetchCalcTripsThunk,
  fetchGetShippingCultureTypesThunk,
} from "../../Store/shippingSlice/shippingSlice";
import uuid from "react-uuid";
import { ICalcDataRef, IFormDataRef } from "./interfaces";

const ShippingCalculatorPage: FC = (): JSX.Element => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [inputActive, setInputActive] = useState<boolean>(false);
  const [inputType, setInputType] = useState<"from" | "to" | null>(null);
  const [keyForInput, setKeyForInput] = useState<string>(uuid());
  const [quantity, setQuantity] = useState<number | null>(null);
  const [days, setDays] = useState<null | number>(null);
  const [myOfferRubTonKm, setMyOfferRubTonKm] = useState<null | string>(null);
  const [myOfferOnlyTonn, setMyOfferOnlyTonn] = useState<null | string>(null);
  const [myOfferFullPrice, setMyOfferFullPrice] = useState<null | string>(null);

  // storage for map coordinates

  const formData = useRef<IFormDataRef>({
    categoryName: null,
    addressFrom: { address: null, coordinates: null, regionIsoCode: null },
    addressTo: { address: null, coordinates: null, regionIsoCode: null },
    quantity: null,
  });
  const calculatedData = useRef<ICalcDataRef>({
    rubTonsKm: null,
    onlyTons: null,
    fullPrice: null,
  });
  const { distanceValue } = useAppSelector((state: RootState) => state.shipping.distance);
  const { rate, distance } = useAppSelector(
    (state: RootState) => state.shipping.price.data
  );
  const tripsData = useAppSelector((state: RootState) => state.shipping.trips.data);
  const regions = useAppSelector(
    (state: RootState) => state.contractors.AllRegionsDate.allRegions
  );
  const { cultures, statusShipCultures } = useAppSelector(
    (state: RootState) => state.shipping.cultureTypes
  );

  const dispatch = useAppDispatch();

  // useEffect for controlling right card inputs in calculator
  useEffect(() => {
    if (rate && quantity && distance) {
      calculatedData.current.rubTonsKm = (+rate).toFixed(2);
      calculatedData.current.onlyTons = (+rate * +distance).toFixed(0);
      calculatedData.current.fullPrice = (+rate * +quantity * +distance).toFixed(0);
      setMyOfferRubTonKm((+rate).toFixed(2));
      setMyOfferOnlyTonn((+rate * +distance).toFixed(0));
      setMyOfferFullPrice((+rate * +quantity * +distance).toFixed(0));
      setKeyForInput(uuid());
    }
    if (!quantity) {
      calculatedData.current.rubTonsKm = null;
      calculatedData.current.onlyTons = null;
      calculatedData.current.fullPrice = null;
      setMyOfferRubTonKm(null);
      setMyOfferOnlyTonn(null);
      setMyOfferFullPrice(null);
      setKeyForInput(uuid());
    }
  }, [rate, quantity, distance]);

  const parsedRegions = regions.map((el: { label: string; value: string }) => {
    return JSON.parse(el.value);
  });

  // handler for controlling if inputs not empty and make request

  const handleCalcPrice = () => {
    let distance;
    let loadingPlace;
    let unloadingPlace;
    let cargoType;

    if (distanceValue) {
      distance = distanceValue;
    }
    if (formData.current.addressFrom.address) {
      loadingPlace = {
        regionIsoCode: formData.current.addressFrom.regionIsoCode,
        address: formData.current.addressFrom.address,
        lat: formData.current.addressFrom.coordinates.lat,
        lng: formData.current.addressFrom.coordinates.lng,
      };
    }
    if (formData.current.addressTo.address) {
      unloadingPlace = {
        regionIsoCode: formData.current.addressTo.regionIsoCode,
        address: formData.current.addressTo.address,
        lat: formData.current.addressTo.coordinates.lat,
        lng: formData.current.addressTo.coordinates.lng,
      };
    }
    if (formData.current.categoryName) {
      const currentCargo = cultures.find(
        (culture) => culture.name === formData.current.categoryName
      );
      cargoType = { id: currentCargo.id, density: currentCargo.density };
    }

    if (distance && loadingPlace && unloadingPlace && cargoType) {
      dispatch(
        fetchCalcPriceThunk({
          distance,
          loadingPlace,
          unloadingPlace,
          cargoType,
        })
      );
    }
  };

  // useEffect to calc Order Trips

  useEffect(() => {
    if (days && distanceValue && quantity) {
      const data = { weight: quantity, days, distance: distanceValue };
      dispatch(fetchCalcTripsThunk(data));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distanceValue, days, quantity]);

  useEffect(() => {
    if (distanceValue) {
      handleCalcPrice();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distanceValue]);

  const showModal = (address) => {
    setInputType(address);
    setIsModalOpen(true);
  };

  // handler for closing Modal with map and set data to storage

  const handleOk = () => {
    setIsModalOpen(false);
    if (
      formData.current.addressFrom.coordinates.lat &&
      formData.current.addressFrom.coordinates.lng &&
      formData.current.addressTo.coordinates.lat &&
      formData.current.addressTo.coordinates.lng
    ) {
      const data = {
        source: [
          formData.current.addressFrom.coordinates.lat,
          formData.current.addressFrom.coordinates.lng,
        ],
        destination: [
          formData.current.addressTo.coordinates.lat,
          formData.current.addressTo.coordinates.lng,
        ],
      };

      dispatch(fetchCalcDistanceThunk(data));
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSetQuantity = (e) => {
    setQuantity(e);
  };

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const startDate = dayjs(dates[0]);
      const endDate = dayjs(dates[1]);
      const differenceInDays = endDate.diff(startDate, "day");
      if (differenceInDays < 2) {
        notification.open({ message: "Срок не может быть меньше 2 дней!" });
      }
      setDays(differenceInDays);
    }
  };

  //handler to calc new values of MyOffer Inputs

  const handleMyOfferChange = (e: string | any, type: string) => {
    if (!quantity || !distanceValue || !e) {
      setMyOfferFullPrice(null);
      setMyOfferRubTonKm(null);
      setMyOfferOnlyTonn(null);
    }
    if (type === "fullPrice") {
      setMyOfferFullPrice(e.toFixed(0));
      setMyOfferRubTonKm((+e / +quantity / +distanceValue).toFixed(2));
      setMyOfferOnlyTonn((+e / +distanceValue).toFixed(0));
    } else if (type === "rubTonsKm") {
      setMyOfferRubTonKm(e.toFixed(2));
      setMyOfferOnlyTonn((+myOfferRubTonKm * +distanceValue).toFixed(0));
      setMyOfferFullPrice((+myOfferRubTonKm * +distanceValue * +quantity).toFixed(0));
    } else if (type === "onlyTonn") {
      setMyOfferOnlyTonn(e.toFixed(0));
      setMyOfferRubTonKm((+e / distanceValue).toFixed(2));
      setMyOfferFullPrice((distanceValue * +myOfferRubTonKm * quantity).toFixed(0));
    }
  };

  return (
    <div className={styles.calcPage}>
      <div className={styles.header}>
        <div>
          <PageHeader
            className={styles.breadcrumbs}
            breadcrumb={
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/" style={{ color: "gray" }}>
                    Agro.Broker
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Калькулятор</Breadcrumb.Item>
              </Breadcrumb>
            }
          />
          <h1 className={styles.header__title}>Расчет стоимости перевозки</h1>
        </div>
        <img className={styles.agroLogo} src={agrogoLogo} />
      </div>
      <div className={styles.mainCards}>
        <div className={styles.leftCard}>
          <h5 className={styles.leftCard__title}>Параметры перевозки</h5>
          <Form className={styles.leftCard__form} form={form}>
            <div className={styles.twoEl}>
              <Form.Item className={styles.twoEl__element} name="category">
                <label>
                  <p>Тип груза</p>
                  <Select
                    style={{ marginTop: "8px" }}
                    onClick={
                      statusShipCultures !== "resolvedGetShipCultures"
                        ? () => dispatch(fetchGetShippingCultureTypesThunk())
                        : undefined
                    }
                    options={
                      statusShipCultures === "resolvedGetShipCultures"
                        ? cultures.map((el) => {
                            return { label: el.name, value: el.name };
                          })
                        : null
                    }
                    onSelect={() => {
                      handleCalcPrice();
                    }}
                    onChange={(e) => (formData.current.categoryName = e)}
                  />
                </label>
              </Form.Item>
              <Form.Item className={styles.twoEl__element} name="category">
                <label>
                  Тип транспорта
                  <Select style={{ marginTop: "8px" }} disabled />
                </label>
              </Form.Item>
            </div>
            <div className={styles.twoEl}>
              <Form.Item className={styles.twoEl__element}>
                <label>
                  Время погрузки
                  <DatePicker
                    picker="time"
                    locale={locale}
                    format={"HH:mm"}
                    minuteStep={10}
                    showSecond={false}
                    className={styles.datePicker}
                  />
                </label>
              </Form.Item>
              <Form.Item className={styles.twoEl__element}>
                <label>
                  Время выгрузки
                  <DatePicker
                    picker="time"
                    locale={locale}
                    format={"HH:mm"}
                    minuteStep={10}
                    showSecond={false}
                    className={styles.datePicker}
                  />
                </label>
              </Form.Item>
            </div>
            <div className={styles.twoEl}>
              <Form.Item className={styles.twoEl__element}>
                <label>
                  Пункт погрузки
                  <Space.Compact style={{ marginTop: "8px", width: "100%" }}>
                    <Input
                      value={formData.current.addressFrom.address}
                      placeholder="Адрес"
                    />
                    <Button onClick={() => showModal("from")}>
                      <EnvironmentOutlined />
                    </Button>
                  </Space.Compact>
                </label>
              </Form.Item>
              <Form.Item className={styles.twoEl__element} name="addressTo">
                <label>
                  Пункт выгрузки
                  <Space.Compact style={{ marginTop: "8px", width: "100%" }}>
                    <Input
                      value={formData.current.addressTo.address}
                      placeholder="Адрес"
                      readOnly
                    />
                    <Button onClick={() => showModal("to")}>
                      <EnvironmentOutlined />
                    </Button>
                  </Space.Compact>
                </label>
              </Form.Item>
            </div>
            <div className={styles.twoEl}>
              <Form.Item className={styles.twoEl__element}>
                <label className={styles.inputNumber}>
                  Расстояние, км
                  <InputNumber
                    className={styles.inputNumber__input}
                    readOnly={true}
                    style={{ marginTop: "8px" }}
                    value={distanceValue ? distanceValue.toFixed(2) : ""}
                    placeholder="Расстояние"
                    controls={false}
                  />
                </label>
              </Form.Item>
              <Form.Item className={styles.twoEl__element}>
                <label>
                  Срок
                  <DatePicker.RangePicker
                    style={{ marginTop: "8px" }}
                    locale={locale}
                    format={"DD.MM.YYYY"}
                    placeholder={[
                      dayjs().format("DD.MM.YYYY"),
                      dayjs().add(7, "day").format("DD.MM.YYYY"),
                    ]}
                    onChange={(e) => handleDateChange(e)}
                    status={days < 2 && days && "error"}
                  />
                </label>
              </Form.Item>
            </div>
            <div className={styles.twoEl}>
              <Form.Item className={styles.twoEl__element}>
                <label className={styles.inputNumber}>
                  Объем, т
                  <InputNumber
                    style={{ marginTop: "8px", width: "97%" }}
                    placeholder="Тоннаж"
                    value={quantity}
                    controls={false}
                    onChange={(e) => handleSetQuantity(e)}
                  />
                </label>
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className={styles.rightCard}>
          <h5 className={styles.rightCard__title}>Расчеты стоимости</h5>
          <Form className={styles.leftCard__form} form={form}>
            <div className={styles.twoEl}>
              <div className={styles.twoEl__element} style={{ marginTop: "12px" }}>
                <p style={{ color: "#00000073" }}>Количество машин</p>
                <h3 className={styles.twoEl__element__hTag}>
                  ~ {tripsData.orderCount ? tripsData.orderCount : 0}
                </h3>
              </div>
              <div className={styles.twoEl__element} style={{ marginTop: "12px" }}>
                <p style={{ color: "#00000073" }}>Количество рейсов</p>
                <h3 className={styles.twoEl__element__hTag}>
                  ~ {tripsData.tripsPerOrder ? tripsData.tripsPerOrder : 0}
                </h3>
              </div>
            </div>
            <div className={styles.twoEl} style={{ marginTop: "20px" }}>
              <Form.Item className={styles.twoEl__element}>
                <label className={styles.inputNumber}>
                  Рекомендуемая ставка в руб/тн*км
                  <InputNumber
                    className={styles.inputNumber__input}
                    style={{ marginTop: "8px" }}
                    key={keyForInput}
                    value={
                      calculatedData.current.rubTonsKm !== null
                        ? calculatedData.current.rubTonsKm
                        : undefined
                    }
                    placeholder="3"
                    readOnly
                    controls={false}
                  ></InputNumber>
                </label>
              </Form.Item>
              <Form.Item className={styles.twoEl__element}>
                <label className={styles.inputNumber}>
                  <div>
                    Ваша ставка в руб/тн*км{" "}
                    <Tooltip
                      placement="rightTop"
                      color="#76A639"
                      title="Окончательную ставку можно откорректировать самостоятельно"
                    >
                      <img src={infoCircle} alt="info" />
                    </Tooltip>
                  </div>
                  <InputNumber
                    className={styles.inputNumber__input}
                    style={{ marginTop: "8px" }}
                    key={keyForInput}
                    value={myOfferRubTonKm ? myOfferRubTonKm : undefined}
                    onChange={(e) => handleMyOfferChange(e, "rubTonsKm")}
                    placeholder="3"
                    controls={false}
                  ></InputNumber>
                </label>
              </Form.Item>
            </div>
            <div className={styles.twoEl}>
              <Form.Item className={styles.twoEl__element}>
                <label className={styles.inputNumber}>
                  Рекомендуемая ставка за тонну
                  <InputNumber
                    key={keyForInput}
                    value={
                      calculatedData.current.onlyTons !== null
                        ? formatQuantity(calculatedData.current.onlyTons)
                        : undefined
                    }
                    className={styles.inputNumber__input}
                    style={{ marginTop: "8px" }}
                    placeholder="10 000"
                    controls={false}
                    readOnly
                  ></InputNumber>
                </label>
              </Form.Item>
              <Form.Item className={styles.twoEl__element}>
                <label className={styles.inputNumber}>
                  <div>
                    Ваша ставка за тонну {"  "}
                    <Tooltip
                      placement="rightTop"
                      color="#76A639"
                      title="Окончательную ставку можно откорректировать самостоятельно"
                    >
                      <img src={infoCircle} alt="info" />
                    </Tooltip>
                  </div>

                  <InputNumber
                    className={styles.inputNumber__input}
                    style={{ marginTop: "8px" }}
                    key={keyForInput}
                    value={myOfferOnlyTonn ? formatQuantity(myOfferOnlyTonn) : undefined}
                    onChange={(e) => handleMyOfferChange(e, "onlyTonn")}
                    placeholder="10 000"
                    controls={false}
                  ></InputNumber>
                </label>
              </Form.Item>
            </div>
            <div className={styles.twoEl}>
              <Form.Item className={styles.twoEl__element}>
                <label className={styles.inputNumber}>
                  Итоговая стоимость
                  <InputNumber
                    className={styles.inputNumber__input}
                    style={{ marginTop: "8px" }}
                    key={keyForInput}
                    value={
                      calculatedData.current.fullPrice !== null
                        ? formatQuantity(calculatedData.current.fullPrice)
                        : undefined
                    }
                    placeholder="3 500 000"
                    controls={false}
                    readOnly
                  ></InputNumber>
                  <p className={styles.twoEl__element__paragraph}>
                    Стоимость расчитана для ознакомления на основании доступных тарифов
                    перевозчиков, действующих на дату проведения расчета. Окончательную
                    стоимость можно откорректировать самостоятельно во время оформления
                    заявки. Не является публичной офертой.
                  </p>
                </label>
              </Form.Item>
              <Form.Item className={styles.twoEl__element}>
                <label className={styles.inputNumber}>
                  <div>
                    Итоговая стоимость{"  "}
                    <Tooltip
                      placement="rightTop"
                      color="#76A639"
                      title="Окончательную стоимость можно откорректировать самостоятельно"
                    >
                      <img src={infoCircle} alt="info" />
                    </Tooltip>
                  </div>

                  <InputNumber
                    className={styles.inputNumber__input}
                    key={keyForInput}
                    value={
                      myOfferFullPrice ? formatQuantity(myOfferFullPrice) : undefined
                    }
                    onChange={(e) => handleMyOfferChange(e, "fullPrice")}
                    style={{ marginTop: "8px" }}
                    placeholder="3 500 000"
                    controls={false}
                  ></InputNumber>
                </label>
              </Form.Item>
            </div>
            <Button
              htmlType="submit"
              className={styles.submitButton}
              target="_blank"
              href="https://app.agrogo.pro/"
            >
              Перейти в AgroGo
            </Button>
          </Form>
        </div>
      </div>
      <Modal
        title="Выберите местоположение"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ borderRadius: "none" }}
        cancelText="Отмена"
        okText="Выбрать"
        destroyOnClose={true}
      >
        <Map
          setInputActive={setInputActive}
          inputActive={inputActive}
          formData={formData}
          inputType={inputType}
          regions={parsedRegions}
        />
      </Modal>
    </div>
  );
};

export default ShippingCalculatorPage;
