import React, { useEffect, useState } from "react";
import styles from "./Center.module.scss";
import "./center.scss";

import BuyTable from "./components/Tables/BuyTable";
import SellTable from "./components/Tables/SellTable";
import CategoryService from "../../../../../services/CategoriesService";
import RegionsService from "../../../../../services/RegionsService";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import MobileLists from "./components/MobileList/MobileLists";
import { useAppDispatch } from "../../../../../hooks/reduxTypesHooks";
interface Props {
  filters: {
    category?: string;
    price_max?: string;
    price_min?: string;
    quantity_max?: string;
    quantity_min?: string;
    region?: string;
  };
  setFilters: React.Dispatch<React.SetStateAction<any>>;
}

const Center: React.FC<Props> = ({ filters, setFilters }) => {
  const dispatch = useAppDispatch();
  // new
  const { width } = useWindowDimensions();
  // get culture and region Hash Tables for Buy and Sell Tables
  const [cultures, setCultures] = useState({});
  const [regions, setRegions] = useState({});

  useEffect(() => {
      getFilterData();
      console.log("FETCH DATA")
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, []);

  
  async function getFilterData() {
    const res = await Promise.all([
      CategoryService.getCategories(),
      RegionsService.getRegions(),
    ]);

    setCultures(convertCutures(res[0].data));
    setRegions(convertRegions(res[1].data));
  }

  function convertCutures(getCategoriesResponse) {
    const culturesHash = {};
    getCategoriesResponse.forEach((category: any) => {
      culturesHash[category.slug] = category.title;
    });
    return culturesHash;
  }

  function convertRegions(getRegionsResponse) {
    const regionsHash = {};
    getRegionsResponse.forEach((region) => {
      regionsHash[region.isoCode] = region.name;
    });
    return regionsHash;
  }

  if (width) {
    return (
      <div className={styles.center}>
        {width > 1350 ? (
          <>
            <BuyTable regionsHash={regions} culturesHash={cultures} />
            <SellTable regionsHash={regions} culturesHash={cultures} />
          </>
        ) : (
          <MobileLists regionsHash={regions} culturesHash={cultures} />
        )}
      </div>
    );
  } else {
    return <></>;
  }
};

export default Center;
