import React from "react";
import MainContentMiddle from "./Middle/Middle";

const MainContent: React.FC = () => {
 
  return (
    <div className="fullbox__main">
      <MainContentMiddle />
    </div>
  );
};

export default MainContent;
