import React, { FC } from "react";
import { TableFilterContainer } from "@consta/uikit/Table";
import { useState } from "react";
import { Checkbox } from "@consta/uikit/Checkbox";
import { TextField } from "@consta/uikit/TextField";
import { FilterComponentProps } from "@consta/uikit/__internal__/src/components/Table/filtering";

export const PriceFilterComponent: FC<FilterComponentProps> = ({
  onConfirm,
  onCancel,
  filterValue,
}): JSX.Element => {
  const [fromValue, setFromValue] = useState<string>(
    (filterValue as { min: string; max: string } | null)?.min || ""
  );
  const [toValue, setToValue] = useState<string>(
    (filterValue as { min: string; max: string } | null)?.max || ""
  );
  const [withOffer, setWithOffer] = useState<boolean>(false);
  const handleFromValue = ({ value }: { value }) => setFromValue(value);
  const handleToValue = ({ value }: { value }) => setToValue(value);
  const handleOffer = (e) => setWithOffer(e.checked);
  const confirmHandler = (): void => {
    onConfirm({
      min: fromValue,
      max: toValue,
      withOffer,
    });
  };
  return (
    <TableFilterContainer
      onConfirm={confirmHandler}
      onCancel={onCancel}
      className="price-container"
    >
      <div style={{ display: "flex" }}>
        <TextField
          type="text"
          form="defaultClear"
          placeholder="От"
          onChange={handleFromValue}
          value={fromValue}
        ></TextField>
        <TextField
          type="text"
          form="brickDefault"
          placeholder="До"
          onChange={handleToValue}
          value={toValue}
        ></TextField>
      </div>
    </TableFilterContainer>
  );
};
