import React, { useState } from "react";
import {
  HomeOutlined,
  LeftOutlined,
  UserOutlined,
  RightOutlined,
  ProfileOutlined,
  SendOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import { useNavigate } from "react-router-dom";
import "./sidebar.scss";
import { useAppDispatch } from "../../../hooks/reduxTypesHooks";
import { changeBurgerState } from "../../../Store/burgerMenuSlice/burgerSlice";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

interface Props {
  sidebarActive: boolean;
  setSidebarActive: React.Dispatch<React.SetStateAction<boolean>>;
}
const SideBar: React.FC<Props> = ({ sidebarActive, setSidebarActive }) => {
  const { width } = useWindowDimensions();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [current, setCurrent] = useState("1");

  const items = [
    getItem(sidebarActive && "Главная", "Main", <HomeOutlined />),
    getItem(
      sidebarActive && "Личный кабинет",
      "myProfile1",
      <UserOutlined />,
      sidebarActive
        ? [
            getItem("Профиль компании", "profileMyCompany"),
            getItem("Мой профиль", "myProfile"),
            getItem("Информер", "informer"),
          ]
        : undefined
    ),
    getItem(
      sidebarActive && "Мои лоты",
      "Lots",
      <ProfileOutlined />,
      sidebarActive
        ? [
            //getItem("Создать лот", "newlot"),
            //getItem("Upload", "upload"),
            //getItem("Создать предложение", "counterOffer"),
            //getItem("Предложения", "myOffers"),
            getItem("Добавить лот", "newlot"),
            getItem("Продажа", "mySellAuctions"),
            getItem("Покупка", "myBuyAuctions"),
          ]
        : undefined
    ),
    getItem("Калькулятор перевозки", "shippingCalculator", <SendOutlined />),
  ];

  const mobileItems = [
    getItem(sidebarActive && "Главная", "Main", <HomeOutlined />),
    getItem(
      sidebarActive && "Личный кабинет",
      "myProfile1",
      <UserOutlined />,
      sidebarActive
        ? [
            getItem("Профиль компании", "profileMyCompany"),
            getItem("Мой профиль", "myProfile"),
            getItem("Информер", "informer"),
          ]
        : undefined
    ),
    getItem(
      sidebarActive && "Мои лоты",
      "Lots",
      <ProfileOutlined />,
      sidebarActive
        ? [
            //getItem("Создать лот", "newlot"),
            //getItem("Upload", "upload"),
            //getItem("Создать предложение", "counterOffer"),
            //getItem("Предложения", "myOffers"),
            getItem("Добавить лот", "newlot"),
            getItem("Продажа", "mySellAuctions"),
            getItem("Покупка", "myBuyAuctions"),
          ]
        : undefined
    )
  ];
  const onClick: MenuProps["onClick"] = (e): any => {
    // console.log("click ", e);
    setCurrent(e.key);
    // e.key === "sub5" && setSidebarActive((prev) => !prev);
    switch (e.key) {
      case "Main":
        dispatch(changeBurgerState());
        navigate("/");
        break;
      case "profileMyCompany":
        dispatch(changeBurgerState());
        navigate("/profileMyCompany");
        break;
      case "myProfile1":
        dispatch(changeBurgerState());
        navigate("myProfile");
        break;
      case "myProfile":
        dispatch(changeBurgerState());
        navigate("myProfile");
        break;
      case "Informer":
        dispatch(changeBurgerState());
        navigate("myProfile");
        break;
      case "newlot":
        dispatch(changeBurgerState());
        navigate("newlot");
        break;
      case "upload":
        dispatch(changeBurgerState());
        navigate("upload");
        break;
      case "counterOffer":
        dispatch(changeBurgerState());
        navigate("counterOffer");
        break;
      case "myOffers":
        dispatch(changeBurgerState());
        navigate("myOffers");
        break;
      case "mySellAuctions":
        dispatch(changeBurgerState());
        navigate("mySellAuctions");
        break;
      case "myBuyAuctions":
        dispatch(changeBurgerState());
        navigate("myBuyAuctions");
        break;
      case "shippingCalculator":
        dispatch(changeBurgerState());
        navigate("shippingCalculator");
        break;
      case "informer":
        dispatch(changeBurgerState());
        navigate("informer");
        break;
      case "newlot":
        dispatch(changeBurgerState());
        navigate("/newlot");
        break;
      default:
        break;
    }
    /*  (e.key === "sub5" && setSidebarActive((prev) => !prev)) ||
      ((e.key === "sub1" || e.key === "sub2" || e.key === "sub4") &&
        setSidebarActive(true)); */
  };

  return (
    <div className="menu" aria-hidden>
      <Menu
        onClick={onClick}
        style={{
          // height: `calc(100vh - 64px)`,
          fontSize: "14px",
          lineHeight: "22px",
        }}
        defaultOpenKeys={["sub1"]}
        selectedKeys={[current]}
        mode="inline"
        items={width !== null && width > 576 ? items : mobileItems}
      />
    </div>
  );
};

export default SideBar;
