import React, {useState} from "react";

import MiddleCenter from "./MiddleCenter/Center";
import MiddleRightbar from "./MiddleRightbar/MiddleRightbar";
import "./middle.scss"
const MainContentMiddle: React.FC = () => {
  const [filters, setFilters] = useState({})

  return (
    <div className="main__middle">
      <MiddleCenter filters={filters} setFilters={setFilters}/>
      
    </div>
  );
};

export default MainContentMiddle;
