import "./_color/Theme_color_gpnWhite.css";
import "./_color/Theme_color_gpnDark.css";
import "./_control/Theme_control_gpnWhite.css";
import "./_font/Theme_font_gpnGreen.css";
import "./_size/Theme_size_gpnWhite.css";
import "./_space/Theme_space_gpnWhite.css";
import "./_shadow/Theme_shadow_gpnWhite.css";

export const whitePreset = {
  color: {
    primary: "gpnWhite",
    accent: "gpnDark",
    invert: "gpnWhite",
  },
  control: "gpnWhite",
  font: "gpnGreen",
  size: "gpnWhite",
  space: "gpnWhite",
  shadow: "gpnWhite",
};
