import $api from "../http";

export default class LotsService {
  static async getBuyLots(filters) {
    const params = new URLSearchParams();
    let url;
    if (Object.keys(filters).length !== 0) {
      Object.keys(filters).forEach((key) => {
        if (Array.isArray(filters[key])) {
          filters[key].forEach((value) => {
            params.append(key, value);
          });
        } else {
          params.append(key, filters[key]);
        }
      });
      url = `/api/v1/market/lots/?type=buy&status=published&${params.toString()}`;
    } else {
      url = `/api/v1/market/lots/?type=buy&status=published&`;
    }

    return $api.get(url);
  }

  static async getSellLots(filters) {
    const params = new URLSearchParams();
    let url;
    if (Object.keys(filters).length !== 0) {
      Object.keys(filters).forEach((key) => {
        if (Array.isArray(filters[key])) {
          filters[key].forEach((value) => {
            params.append(key, value);
          });
        } else {
          params.append(key, filters[key]);
        }
      });
      url = `/api/v1/market/lots/?type=sell&status=published&${params.toString()}`;
    } else {
      url = `/api/v1/market/lots/?type=sell&status=published`;
    }

    return $api.get(url);
  }
  static async getMyOfferLots(filters) {
    const params = new URLSearchParams();
    let url;
    if (Object.keys(filters).length !== 0) {
      Object.keys(filters).forEach((key) => {
        if (Array.isArray(filters[key])) {
          filters[key].forEach((value) => {
            params.append(key, value);
          });
        } else {
          params.append(key, filters[key]);
        }
      });
      url = `/api/v1/market/lots/?status=published&${params.toString()}`;
    } else {
      url = `/api/v1/market/lots/?status=published`;
    }

    return $api.get(url);
  }
    
   
}
