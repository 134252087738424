import React, { FC } from "react";
import { LayoutBlockProps } from "./Card.interface";
import styles from "./card.module.scss";
import cn from "classnames";

const Card: FC<LayoutBlockProps> = ({
  children,
  backgroundColor = "white",
  size = "l",
  ...props
}): JSX.Element => {
  return (
    <div
      className={cn(styles.block, {
        [styles.green]: backgroundColor === "green",
        [styles.gold]: backgroundColor === "gold",
        [styles.white]: backgroundColor === "white",
        [styles.mobile_size]: size === "s",
        [styles.tablet_size]: size === "m",
        [styles.tablet2_size]: size === "l",
        [styles.desktop_size]: size === "xl",
      })}
      {...props}
    >
      {children}
    </div>
  );
};

export default Card;
