import React, { useState } from "react";
import { Button } from "@consta/uikit/Button";
import { Theme } from '@consta/uikit/Theme';
import { whitePreset } from '../../../ui-presets/presetsTheme/whitePreset';
const DiscardButton = () => {
    return (
      <Theme preset={whitePreset}>
        <Button
          form="round"
          view="secondary"
          label="Отказаться"
          size="xs"
          style={{ minWidth: "100px" }}
        ></Button>
      </Theme>
    );
};

export default DiscardButton;
