import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import uuid from "react-uuid";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypesHooks";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { fetchGetCurrentLotBids } from "../../Store/marketSlice/marketSlice";
import { RootState } from "../../Store/store";
import MobileCard from "../MobileLotCard/MobileCard";
import styles from "./mobileCurrentLotBids.module.scss";
import backBtn from "../../Svgs/backBtn.svg";
import ModalComponent from "./modal/Modal";
import SingeLotService from "../../services/SingleLotService";
const MobileCurrentLotBids = () => {
  const [pageCount, setPageCount] = useState<number>(1);
  const [currentBidInfo, setCurrentBidInfo] = useState<any>({});
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [lotNumber, setLotNumber] = useState<any>("");

  const location = useLocation();
  const locationState: any = location.state;
  const navigate = useNavigate();
  const lotId: any = location.pathname.split("/").pop();
  useEffect(() => {
    if (lotId) {
      getLotData(lotId);
    }
  }, [lotId]);

  const getLotData = async (id) => {
    const res = await SingeLotService.getLot(lotId);
    await setLotNumber(res.data.number);
  };
  const dispatch = useAppDispatch();
  const currentLotBids = useAppSelector(
    (state: RootState) => state.market.currentLotBids
  );
  const currentLotBidsStatus = useAppSelector(
    (state: RootState) => state.market.currentLotBids.status
  );
  const nextPage: string = useAppSelector(
    (state: RootState) => state.market.currentLotBids.data.next
  );
  const listRef = useRef<HTMLDivElement | null>(null);

  const handleModalOpen = (lot) => {
    setIsModalVisible(true);
    setCurrentBidInfo(lot);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const { width } = useWindowDimensions();
  const checkSize = useMemo(() => {
    if (width && width < 500) {
      return "s";
    } else if (width && width >= 500 && width <= 1080) {
      return "m";
    } else if (width && width > 1080 && width <= 1350) {
      return "l";
    }
  }, [width]);
  useEffect(() => {
    dispatch(fetchGetCurrentLotBids(lotId));
  }, []);
  useEffect(() => {
    const ref = listRef.current;
    if (ref !== null) {
      const options = {
        rootMargin: "0px",
        threshold: 0.1,
      };

      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting && nextPage !== null) {
          setPageCount((prev) => prev + 1);
          dispatch(fetchGetCurrentLotBids(lotId));
        }
      }, options);

      if (ref?.lastElementChild) {
        observer.observe(ref?.lastElementChild);
      }

      return () => {
        if (ref?.lastElementChild) {
          observer.unobserve(ref?.lastElementChild);
        }
      };
    }
  });
  console.log("CURRENT LOT BIDS", currentLotBids);
  const handleBackNavigate = () => {
    if (locationState.type === "sell") {
      navigate("/mySellAuctions");
    } else {
      navigate("/myBuyAuctions");
    }
  };
  if (currentLotBidsStatus === "resolvedGetCurrentLotBids") {
    const currentLotBidsCards = currentLotBids.data.results.map((lot, i) => (
      <div key={uuid()} onClick={() => handleModalOpen(lot)}>
        <MobileCard lot={lot} checkSize={checkSize} currentLotBid={true} />
      </div>
    ));
    if (currentLotBidsCards.length > 0) {
      return (
        <>
          <div className={styles.fullbox_mobile} ref={listRef}>
            <div className={styles.fullbox_mobile__header}>
              <img src={backBtn} className={styles.icon} onClick={handleBackNavigate} />
              <p className={styles.fullbox_mobile__header__pTag}>
                Мне предложили по лоту {lotNumber}
              </p>
            </div>
            {currentLotBidsCards}
          </div>
          <ModalComponent
            currentBidInfo={currentBidInfo}
            isModalVisible={isModalVisible}
            handleModalClose={handleModalClose}
            footer={null}
          />
        </>
      );
    } else {
      return (
        <div className={styles.fullbox_mobile} ref={listRef}>
          <img
            src={backBtn}
            className={styles.icon}
            style={{ position: "relative", top: "5px", marginRight: "10px" }}
            onClick={handleBackNavigate}
          />
          По вашему лоту нет откликов
        </div>
      );
    }
  }
};

export default MobileCurrentLotBids;
