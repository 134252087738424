import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice/userSlice";
import langReducer from "./langSlice/langSlice";
import burgerReducer from "./burgerMenuSlice/burgerSlice";
import contractorsReducer from "./contractorsSlice/contractorsSlice";
import marketReducer from "./marketSlice/marketSlice";
import catalogReducer from "./catalogSlice/catalogSlice";
import shippingReducer from "./shippingSlice/shippingSlice";
import informerReducer from "./informerSlice/informerSlice";

// import thunkMiddleware from 'redux-thunk';

const store = configureStore({
  reducer: {
    burgerState: burgerReducer,
    user: userReducer,
    lang: langReducer,
    contractors: contractorsReducer,
    catalog: catalogReducer,
    market: marketReducer,
    shipping: shippingReducer,
    informer: informerReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["your/action/type"],
        // Ignore these field paths in all actions
        ignoredActionPaths: ["meta.arg", "payload.timestamp"],
        // Ignore these paths in the state
        ignoredPaths: ["items.dates"],
      },
    }),
  // middleware: [thunkMiddleware],
});
export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
