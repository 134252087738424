import $api from "../http";

export default class UserService {
  static async usersMe() {
    return $api.get(`/api/v1/users/me/`);
  }
  static async putUsersMe(date) {
    return $api.patch(`/api/v1/users/me/`, date);
  }
  static async usersMeCountry() {
    // return $api.options(`/api/v1/users/me/`);
    return $api.options(`/api/v1/contractors/company/my/`);
  }
}
