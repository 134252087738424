import { Button } from 'antd'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface LocationState {
  from: {
    pathname: string;
  };
}

const SuccesRegBlock: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // const from = location.state?.from?.pathname || '/';
  const { from } = location.state as LocationState || { from: { pathname: "/" } };
  return (
    <div className="success-regblock">
      <div className="profile">
        <div className="profile__inner">
          <h3 className="title">Поздравляем с регистрацией!</h3>
          <p className="sub-title">
            Теперь Вы можете видеть все лоты, размещенные на площадке, и создавать свои собственные.<br/>
            Для осуществления финансовых операций на сайте заполните Ваш персональный профиль и внесите подробные данные на странице профиля компании.
          </p>
          <div className="buttons">
            <Button className="btn" onClick={() => navigate('/profileMyCompany')}>Дополнить профиль компании</Button>
            <Button className="btn" onClick={() => navigate('/profileMyCompany')}>Добавить представителя компании</Button>
           
          </div>
        </div>
      </div>
    </div>
    
  )
}

export default SuccesRegBlock