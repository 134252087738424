import { Button } from "antd";
import React, { FC } from "react";
import uuid from "react-uuid";
import { formatPrice } from "../../helpers/helpers";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypesHooks";
import InformerService from "../../services/InformerService";
import { fetchGetInformerThunk } from "../../Store/informerSlice/informerSlice";
import { IResponseGetInformer } from "../../Store/informerSlice/interfaces";
import { RootState } from "../../Store/store";
import styles from "./informerCard.module.scss";
export interface InformerCardProps {
  informerInfo: IResponseGetInformer;
  setInformerKey: Function;
  regionsData: { isoCode: string; name: string; federalDistrict: any }[];
}
const InformerCard: FC<InformerCardProps> = ({
  informerInfo,
  setInformerKey,
  regionsData,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { allCategories, statusCategories } = useAppSelector(
    (state: RootState) => state.catalog.categories
  );
  const convertRegions = (region) => {
    if (regionsData) {
      const currentReg = regionsData.find((el) => el.isoCode === region);
      return currentReg?.name;
    }
  };

  const convertCulture = (culture) => {
    if (statusCategories === "resolvedCategories" && allCategories) {
      const currentCulture = allCategories.find((el) => el.slug === culture);
      return currentCulture?.title;
    }
  };

  const regions = informerInfo.regions.map((region) => (
    <div key={uuid()}>{convertRegions(region)}</div>
  ));
  const notify = informerInfo.notifyTypes.map((el) => (
    <div style={{marginLeft: "5px"}} key={uuid()}>{el === "email" ? "E-mail" : "SMS"}</div>
  ));

  const handleDeleteInformer = async () => {
    await InformerService.deleteInformer(informerInfo.id);
    await dispatch(fetchGetInformerThunk());
    await setInformerKey(uuid());
  };
  return (
    <div className={styles.card}>
      <div className={styles.card_part}>
        <div className={styles.card_part__el}>
          <div className={styles.card_part__el__item}>
            Культура: {convertCulture(informerInfo.category)}{" "}
            {informerInfo.class && ` , ${informerInfo.class}`}
          </div>
          <div className={styles.card_part__el__item}>
            Тип лота: {informerInfo.lotType === "sell" ? "Продажа" : "Покупка"}
          </div>
          <div style={{ display: "flex" }}>Уведомления:   {notify}</div>
        </div>
        <div className={styles.card_part__el}>
          <div className={styles.card_part__el__item}>
            Цена от: {formatPrice(informerInfo.priceFrom)} ₽/т
          </div>
          <div className={styles.card_part__el__item}>
            Цена до: {formatPrice(informerInfo.priceTo)} ₽/т
          </div>
          <div
            className={styles.card_part__el__item}
            style={{ display: "flex", flexDirection: "column" }}
          >
            Расположение лота: {regions}
          </div>
        </div>
      </div>

      <Button
        type="primary"
        size="large"
        className="submit-all"
        style={{ width: "120px", height: "30px" }}
        onClick={() => handleDeleteInformer()}
      >
        Удалить
      </Button>
    </div>
  );
};

export default InformerCard;
