import dayjs from "dayjs";
import { parsePhoneNumber } from "libphonenumber-js";

export const formatPrice = (price) => {
  if (!price) {
    return price;
  }
  return parseFloat(price).toLocaleString("ru-RU", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatQuantity = (num) => {
  if (!num) {
    return num;
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const dateConverter = (date) => {
  const newDate = dayjs(date, "DD.MM.YYYY");
  const formattedDate = newDate.format("YYYY-MM-DD");
  return formattedDate;
};

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return "Нет телефона";
  } else {
    const parsed = parsePhoneNumber(phoneNumber, "RU");
    return parsed.formatNational().replace(/^8/, "+7");
  }
};
