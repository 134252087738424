import { createSlice } from "@reduxjs/toolkit";

type BurgerMenu = {
  burgerState: boolean;
  filterState: boolean;
}

const initialState: BurgerMenu = {
  burgerState: false,
  filterState: false,
}

const burgerSlice = createSlice({
  name: "burgerState",
  initialState,
  reducers: {
    changeBurgerState(state) {
      state.burgerState = !state.burgerState;
    },
    openBurgerState(state) {
      state.burgerState = true;
    },
    closeBurgerState(state) {
      state.burgerState = false;
    },
    changeFilterState(state) {
      state.filterState = !state.filterState;
    },
    closeFilterState(state) {
      state.filterState = false;
    }
  },
});

export const {changeBurgerState,openBurgerState, closeBurgerState, changeFilterState, closeFilterState} = burgerSlice.actions;

export default burgerSlice.reducer;
