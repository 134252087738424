import React, { useState, useEffect } from "react";
import SingeLotService from "../../services/SingleLotService";
import infoIcon from "../../Svgs/infoCircle.svg";
import GostIcon from "../../Svgs/GOST.svg";
import {
  Breadcrumb,
  Button,
  InputNumber,
  PageHeader,
  Spin,
  Tooltip,
  Form,
  notification,
} from "antd";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypesHooks";
import { fetchGetIncoTermsThunk } from "../../Store/marketSlice/marketSlice";
import "./currentLotPage.scss";
import dayjs from "dayjs";
import { formatPrice } from "../../helpers/helpers";
import { formatQuantity } from "../../helpers/helpers";
import BidService from "../../services/BidsService";
import { RootState } from "../../Store/store";

const camelize = (s: string): string => s.replace(/_./g, (x) => x[1].toUpperCase());

const CurrentLotPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [lot, setLot] = useState<any>(Object);
  const [priceValue, setPriceValue] = useState<number | null>(null);
  const [quantityValue, setQuantityValue] = useState<number | null>(null);
  let { id } = useParams();
  const { isAuth } = useAppSelector((state: RootState) => state.user);
  const { allIncoTerms } = useAppSelector((state: RootState) => state.market.incoTerms);
  const { allRegions } = useAppSelector(
    (state: RootState) => state.contractors.AllRegionsDate
  );
  const { allCategories } = useAppSelector(
    (state: RootState) => state.catalog.categories
  );
  const currentDate: string = dayjs(lot.created).format("DD.MM.YYYY") || "нет даты";
  const currentRegionName = allRegions.find(
    (el) => JSON.parse(el.value).region === lot.region
  )?.label;
  const currentIncoterms = allIncoTerms.find(
    (incoterm) => incoterm.value === lot.incoterms
  )?.label;
  const category = allCategories.find(
    (category) => category.slug === lot?.product?.category
  );
  const [form] = Form.useForm();

  const onFinishForm = async () => {
    try {
      const res = await BidService.postBid({
        price: priceValue ? priceValue : lot.price,
        quantity: quantityValue ? quantityValue : lot.quantity,
        lot: location.pathname.split("/").pop(),
      });

      if (res.status !== 201) {
        throw res.status;
      } else {
        console.log(res);
        notification.open({
          message: "Заявка успешно создана",
        });
        navigate("/");
      }
    } catch (error) {
      console.error(error, "ERROR");
      notification.open({
        message: "Произошла ошибка при формировании заявки",
      });
    }
  };

  const handleChangePrice = (value) => {
    setPriceValue(value);
  };

  const handleChangeQuantity = (value) => {
    setQuantityValue(value);
    console.log(location);
  };
  useEffect(() => {
    dispatch(fetchGetIncoTermsThunk());
  }, [dispatch]);

  // const category = useMemo(
  //   () =>
  //     allCategories.filter(
  //       (category) => category.slug === lot?.product?.category
  //     )[0],
  //   [allCategories, lot?.product]
  // );

  async function getLot() {
    const res = await SingeLotService.getLot(id);
    setLot(res.data);
    setLoading(false);
    console.log("data from back getLot", res.data);
  }
  useEffect(() => {
    console.log("==loading", loading);
    console.log("==category", category);
    console.log("==lot?.product", lot?.product);
    console.log("==token from localSt", localStorage.getItem("token"));
    console.log("==isAuth", isAuth);
  }, [loading, category, isAuth, lot?.product]);
    
    console.log("ДЛЯ ПОЛУЧЕНИЯ ПРАВИЛЬНОГО НОМЕРА ЛОТА НЕОБХОДИМО ДОБАВИТЬ НА БЭКЭ ПОЛЕ NUMBER в market/lots/{id}, ровно такое же, как и в  GET market/lots/ ")

  useEffect(() => {
    getLot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  if (!category || !lot?.product) {
    return <Spin size="large" />;
  }

  return (
    <div className="fullbox__main">
      <div className="current-page">
        <PageHeader
          className="site-page-header"
          style={{ paddingTop: "12px" }}
          breadcrumb={
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/" style={{ color: "gray" }}>
                  Главная
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {lot.type === "sell" ? "Лоты на продажу" : "Лоты на покупку"}
              </Breadcrumb.Item>
              <Breadcrumb.Item>Лот № {lot.number}</Breadcrumb.Item>
            </Breadcrumb>
          }
        />
        <div className="current-page__content">
          <div className="current-middle">
            <div className="current-lot">
              <div className="current-lot__top">
                <span className="curr-top1">
                  {lot.type === "sell" ? "Продажа" : "Покупка"}
                </span>
                <span className="curr-top2">{currentDate}</span>
              </div>
              <div className="current-lot__nameprice">
                <span className="curr-name">
                  {category.title},{" "}
                  {lot.product.categoryClass && `${lot.product.categoryClass} класс`}
                </span>
                <span className="curr-price">
                  {formatPrice(lot.price)} ₽/т {"  "}
                  {lot.ndsRate !== 0 ? `${lot.ndsRate}% НДС` : "без НДС"}
                </span>
              </div>
              <div className="current-lot__nameprice">
                <span className="current-lot__weight">
                  {formatQuantity(lot.quantity)} т ± 10%
                </span>
                {lot.product.gostStandard ? <img style={{width: "80px"}} src={GostIcon} alt="гост" /> : undefined}
              </div>
              <div className="current-lot__middle">
                <span className="curr-num">Лот № {lot.number}</span>
                <div className="curr-bazis">
                  <div className="bazis">Базис</div>
                  <div className="location">
                    {currentRegionName},&ensp;{lot.location}
                  </div>
                  <div className="location">{lot.product.comments}</div>
                </div>
              </div>
              <div className="current-lot__bottom__first">
                <div className="properties properties-year">
                  <p className="properties__title">Год урожая</p>
                  <div className="properties__property">
                    <span className="prop">{lot.product.year}</span>
                  </div>
                </div>
                <div className="properties properties-payment">
                  <p className="properties__title">Оплата</p>
                  <div className="properties__property">
                    <span className="prop">{lot.paymentTerms}</span>
                  </div>
                </div>
                <div className="properties properties-incomterms">
                  <p className="properties__title">Инкотермс</p>
                  <div className="properties__property">
                    <span className="prop ">
                      <span>{`${currentIncoterms}`}</span>
                    </span>
                  </div>
                </div>

                <div className="properties properties-postwork">
                  <p className="properties__title">Подработка</p>
                  <div className="properties__property">
                    <span className="prop">
                      {lot.improvement ? "Возможна" : "Невозможна"}
                      <Tooltip
                        title="В случае несоответстия заявленному качеству производитель в состоянии провести сушки или очистку от сорной примеси на своей территории"
                        placement="rightTop"
                        color="#95D708"
                      >
                        <span className="prop-info">
                          <img src={infoIcon} alt="info" />
                        </span>
                      </Tooltip>
                    </span>
                  </div>
                </div>
              </div>
              <div className="current-lot__bottom__second">
                <div className="properties ">
                  {lot.type === "sell" && !lot.product.gostStandard && (
                    <div className="properties-params">
                      <p className="properties__title">Параметры</p>
                      {category.attributes!.map(
                        (attribute) =>
                          Object.keys(lot.product.attributeValues).find(
                            (el) => el === camelize(attribute.slug)
                          ) && (
                            <div
                              className="properties__property__params"
                              key={attribute.slug}
                            >
                              <span className="prop">{attribute.title}</span>
                              <span className="prop">
                                {lot.product.attributeValues[camelize(attribute.slug)]}
                              </span>
                            </div>
                          )
                      )}
                    </div>
                  )}
                  <div className="properties-docs">
                    <p className="properties__title">Документы</p>
                    <div className="properties__property">
                      <Link to={""} className="prop link">
                        Декларация соответствия, протокол исследования
                      </Link>
                    </div>
                    <div className="properties__property">
                      <Link to={""} className="prop link">
                        Ветеринарный сертификат
                      </Link>
                    </div>
                    <div className="properties__property">
                      <Link to={""} className="prop link">
                        Фитосанитарный сертификат
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <Form form={form} onFinish={onFinishForm}>
                <div className="counterOffer-form">
                  <div className="properties-price">
                    <label
                      style={{ width: "100%", display: "flex", alignItems: "center" }}
                    >
                      <p className="price-paragraph">Цена, ₽/т :</p>
                      <Form.Item className="properties-price__label">
                        <InputNumber
                          className="properties-price__label__input"
                          controls={false}
                          onChange={handleChangePrice}
                          placeholder={lot.price}
                          value={priceValue}
                        ></InputNumber>
                      </Form.Item>
                    </label>
                  </div>
                  <div className="properties-quantity">
                    <label
                      style={{ width: "100%", display: "flex", alignItems: "center" }}
                    >
                      <p className="quantity-paragraph">Объем, т:</p>
                      <Form.Item
                        style={{ width: "100%" }}
                        className="properties-quantity__label"
                        rules={[
                          {
                            message: "Неверный объем",
                            validator: (_, value) => {
                              if (+value > +lot.quantity) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject("не валидный ИНН");
                              }
                            },
                          },
                        ]}
                      >
                        <InputNumber
                          className="properties-quantity__label__input"
                          controls={false}
                          onChange={handleChangeQuantity}
                          placeholder={lot.quantity}
                          style={{ width: "100%" }}
                        ></InputNumber>
                      </Form.Item>
                    </label>
                  </div>
                </div>
                <Button type="primary" className="buy-block__buy" onClick={onFinishForm}>
                  {lot.type === "sell" ? "Продать" : "Купить"}
                </Button>
              </Form>
            </div>
          </div>
          {/*<CurrentPageRighbar layout="vertical" />*/}
        </div>
      </div>
    </div>
  );
};

export default CurrentLotPage;
