import React, { useEffect, useRef, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../hooks/reduxTypesHooks";
import { fetchGetBuyLotsThunk } from "../../../../../../../Store/marketSlice/marketSlice";
import { firstBuyRequestChange } from "../../../../../../../Store/marketSlice/marketSlice";

import MobileCard from "../../../../../../MobileLotCard/MobileCard";
import styles from "./mobileList.module.scss";
import uuid from "react-uuid";
import { RootState } from "../../../../../../../Store/store";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../../../../../../hooks/useWindowDimensions";
import { useMemo } from "react";

const SellMobileList = ({ culturesHash }) => {
  const dispatch = useAppDispatch();
  const [pageCount, setPageCount] = useState<number>(1);
  //const [shouldRequestNewPage, setShouldRequestNewPage] = useState<boolean>(false);

  const listRef = useRef<HTMLDivElement | null>(null);

  const buyLotsState: any = useAppSelector((state) => state.market?.buyLots);
  const buyLotsStatus: string | null = useAppSelector(
    (state) => state.market.buyLots.status
  );
  const isFirstRequest = useAppSelector((state) => state.market.buyLots.isFirstRequest);
  const nextPage: string = useAppSelector(
    (state: RootState) => state.market.buyLots.data.next
  );
  const query = useAppSelector((state) => state.market.buyLots.query);
  const { width } = useWindowDimensions();
  const checkSize = useMemo(() => {
    if (width && width < 500) {
      return "s";
    } else if (width && width >= 500 && width <= 1080) {
      return "m";
    } else if (width && width > 1080 && width <= 1350) {
      return "l";
    }
  }, [width]);
  useEffect(() => {
    if (isFirstRequest) {
      dispatch(fetchGetBuyLotsThunk({}));
      dispatch(firstBuyRequestChange(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const ref = listRef.current;
    if (ref !== null) {
      const options = {
        rootMargin: "0px",
        threshold: 0.1,
      };

      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting && nextPage) {
          setPageCount((prev) => prev + 1);
          dispatch(
            fetchGetBuyLotsThunk({
              ...query,
              page: pageCount,
            })
          );
        }
      }, options);

      if (ref?.lastElementChild) {
        observer.observe(ref?.lastElementChild);
      }

      return () => {
        if (ref?.lastElementChild) {
          observer.unobserve(ref?.lastElementChild);
        }
      };
    }
  });

  const SellMobileCards = buyLotsState.data.results.map((lot, i) => (
    <Link to={`/lot/${lot?.id}`} key={uuid()}>
      <MobileCard
        key={uuid()}
        culture={culturesHash[lot.product.category]}
        lot={lot}
        type="buy"
        checkSize={checkSize}
      />
    </Link>
  ));

  if (buyLotsStatus === "resolvedGetBuyLots") {
    return (
      <div className={styles.fullbox_mobile} ref={listRef}>
        {SellMobileCards}
      </div>
    );
  }
  return <>{SellMobileCards ? SellMobileCards : "NO DATA"}</>;
};

export default SellMobileList;
