import { createAsyncThunk , createSlice } from "@reduxjs/toolkit";
import AuthService from "../../services/AuthService";
import ContractorsService from "../../services/ContractorsService";
import UserService from "../../services/UserService";
import { UserResponse, userState } from "./interfaces";

const initialState: userState = {
  user: {
    id: undefined,
    username: undefined,
    fullName: "",
    language: "",
    country: "",
    email: "",
    mobile: "",
    remoteAddr: "",
    emailVerified:false,
    mobileVerified:false,
    lang: "",
    authMethod: "",
    agree: false,
    detail: "",
  },
  isAuth: null,
  status: null,
  error: null,
  statusGetCountries: null,
  countries: [],
}




export const authMobThunk = createAsyncThunk<{detail?: string}, string, {rejectValue: string}>(
  'user/authMobThunk',
  async function(mobile, {rejectWithValue}) {
    try {
      console.log('mobile', mobile);
      
      const response = await AuthService.authMobile(mobile);
      console.log('response', response);
      return response.data;

    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)
export const authEmailThunk = createAsyncThunk<{detail?: string}, string, {rejectValue: string}>(
  'user/authEmailThunk',
  async function(email, {rejectWithValue}) {
    try {
      const response = await AuthService.authEmail(email);
      console.log('response', response);
      // if(!response.ok) {
      //   throw new Error('errror');
      // }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)
export const registrationThunk = createAsyncThunk<{token: string, errors?: any}, {mobile?: string, email?: string, token: string}, {rejectValue: string}>(
  'user/registrationThunk',
  async function({mobile, email, token}, {rejectWithValue}) {
    try {
      const response: any = await AuthService.registration({mobile, email, token});

      localStorage.setItem('token', response.data.token);
      return (response.data) as {token: string};
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

export const loginThunk = createAsyncThunk<{token?: string, errors?: any}, {mobile?: string, email?: string, token: string}, {rejectValue: string}>(
  'user/loginThunk',
  async function({mobile, email, token}, {rejectWithValue}) {
    try {
      const response = await AuthService.login({mobile, email, token});
      

      localStorage.setItem('token', response.data.token!);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

export const logoutThunk = createAsyncThunk<any, undefined, {rejectValue: string}>(
  'user/logoutThunk',
  async function(_, {rejectWithValue}) {
    try {
      return localStorage.removeItem('token');
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)
export const checkAuthThunk = createAsyncThunk<UserResponse, undefined, {rejectValue: string}>(
  'user/checkAuthThunk',
  async function(_, {rejectWithValue}) {
    try {
      const response = await UserService.usersMe();
      return (response.data) as UserResponse;
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)
export const putDateMeThunk = createAsyncThunk<UserResponse, {country?: string, email?: string, fullName?: string, mobile?: string, language?: string}, {rejectValue: string}>(
  'user/putDateMeThunk',
  async function(date, {rejectWithValue}) {
    try {
      const response = await UserService.putUsersMe(date);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)


interface Resp {
  label: string,
  value: string,
  code: string,
  name: string
}
export const getContryThunk = createAsyncThunk<Resp[], undefined, {rejectValue: string}>(
  'user/getContryThunk',
  async function(_, {rejectWithValue}) {
    try {
      const response = await ContractorsService.getCountry();
      return (response.data
        .map((el: {code: string, name: string}) =>
          ({...el, label: el.name, value: el.code}))) as Resp[];
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addPropertiesUser(state, action) {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
    addPropertiesOfCompany(state, action) {
      state.user = action.payload;
    },
    changeStatusToNull(state) {
      state.status = null;
      state.error = null;
    },
    changeIsAuthToFalse(state) {
      state.isAuth = false;
    },
    removeUser(state) {
      state.user = {
        id: undefined,
        username: undefined,
        fullName: "",
        language: "",
        country: "",
        email: "",
        mobile: "",
        remoteAddr: "",
        emailVerified:false,
        mobileVerified:false,
        lang: "",
        authMethod: "",
        agree: false,
        detail: "",
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(authMobThunk.pending, (state) => {
        state.status = 'loadingAuth1';
        state.error = null;
      })
      .addCase(authMobThunk.fulfilled, (state, action) => {
        state.status = 'resolvedAuth1';
        state.user = {...state.user, ...action.payload};
      })
      .addCase(authMobThunk.rejected, (state, action) => {
        state.status = 'rejectedAuth1';
        state.error = action.payload;
      })

      .addCase(authEmailThunk.pending, (state) => {
        state.status = 'loadingAuth1';
        state.error = null;
      })
      .addCase(authEmailThunk.fulfilled, (state, action) => {
        state.status = 'resolvedAuth1';
        state.user = {...state.user, ...action.payload};
      })
      .addCase(authEmailThunk.rejected, (state, action) => {
        state.status = 'rejectedAuth1';
        state.error = action.payload;
      })

      .addCase(loginThunk.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(loginThunk.fulfilled, (state, action) => {
        state.isAuth = true;
        state.status = 'resolvedLogin';
      })
      .addCase(loginThunk.rejected, (state, action) => {
        state.isAuth = false;
        state.status = 'rejected';
        state.error = action.payload;
      })


      .addCase(registrationThunk.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(registrationThunk.fulfilled, (state, action) => {
        state.isAuth = true;
        state.status = 'resolvedRegistration';
      })
      .addCase(registrationThunk.rejected, (state, action) => {
        state.isAuth = false;
        state.status = 'rejected';
        state.error = action.payload;
      })


      .addCase(logoutThunk.pending, (state) => {
        state.status = 'loadingAuth1';
        state.error = null;
      })
      .addCase(logoutThunk.fulfilled, (state, action) => {
        state.isAuth = false;
        state.status = 'resolved';
        state.user = {
          id: undefined,
          username: undefined,
          fullName: "",
          language: "",
          country: "",
          email: "",
          mobile: "",
          remoteAddr: "",
          emailVerified:false,
          mobileVerified:false,
          lang: "",
          authMethod: "",
          agree: false,
          detail: "",
        };
      })
      .addCase(logoutThunk.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
      })


      .addCase(checkAuthThunk.pending, (state) => {
        state.status = 'loadingAuth1';
        state.error = null;
      })
      .addCase(checkAuthThunk.fulfilled, (state, action) => {
        state.isAuth = true;
        state.status = 'resolvedCheckAuth';
        state.user = {...state.user,...action.payload};
      })
      .addCase(checkAuthThunk.rejected, (state, action) => {
        state.status = 'rejectedCheckAuth';
        state.error = action.payload;
      })


      .addCase(putDateMeThunk.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(putDateMeThunk.fulfilled, (state, action) => {
        state.isAuth = true;
        state.status = 'resolvedPutDateMe';
        state.user = {...state.user,...action.payload};
      })
      .addCase(putDateMeThunk.rejected, (state, action) => {
        state.status = 'rejectedPutDateMe';
        state.error = action.payload;
      })


      .addCase(getContryThunk.pending, (state) => {
        state.statusGetCountries = 'loading';
        state.error = null;
      })
      .addCase(getContryThunk.fulfilled, (state, action) => {
        state.statusGetCountries = 'resolvedGetCountries';
        state.countries = action.payload;
      })
      .addCase(getContryThunk.rejected, (state, action) => {
        state.status = 'rejectedGetCountries';
        state.error = action.payload;
      })
  }
});

export const {addPropertiesUser,addPropertiesOfCompany, changeStatusToNull, changeIsAuthToFalse, removeUser} = userSlice.actions;

export default userSlice.reducer;
