import React, { useState, useCallback } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { Input, List } from "antd";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import styles from "./map.module.scss";
import { IFormDataRef } from "../../pages/ShippingCalculator/interfaces";
const containerStyle = {
  width: "470px",
  height: "260px",
};
const apiKey = "AIzaSyBqrHBNUmjEdHdDJ8ov-pWYtcQQ-o0vD1E";

const libraries = ["places"];

const defaultOptions = {
  panControl: true,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateContol: false,
  clickableIcons: false,
  keyboardShortcuts: false,
  disableDoubleClickZoom: false,
  fullscreenControl: false,
  minZoom: 4,
  maxZoom: 18,
};

export interface MapProps {
  setInputActive: Function;
  inputActive: boolean;
  formData: { current: IFormDataRef };
  inputType: string;
  regions: any;
}

const Map = ({ setInputActive, inputActive, formData, inputType, regions }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: apiKey,
    libraries: libraries as any,
  });

  const center = {
    lat: 55.7558,
    lng: 37.6176,
  };
  const [map, setMap] = useState<any>(null);
  const [markerPosition, setMarkerPosition] = useState<{ lat: number; lng: number }>(
    center
  );
  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>(center);
  const [zoom, setZoom] = useState<number>(9);
  const [inputValue, setInputValue] = useState<string>("");
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } = useGoogle({
    apiKey,
    options: {
      input: "ru",
      types: ["(regions)"],
      componentRestrictions: { country: "ru" },
    },
  });

  const findRegionISOCode = (selectedRegion) => {
    return regions.find((region) => region.regionName === selectedRegion).region;
  };

  const onLoad = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);
  // handler active when setting marker on map
  const handleMapClick = async (event) => {
    const clickedLatLng = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setMarkerPosition(clickedLatLng);

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: clickedLatLng }, (results, status) => {
      if (status === "OK" && results && results[0]) {
        const addressCompLength = results[0].address_components.length;
        const administativeAreaLevel1 =
          results[0].address_components[addressCompLength - 3].long_name;
        const regionISOCode = findRegionISOCode(administativeAreaLevel1);

        if (inputType === "from") {
          formData.current.addressFrom.address = results[0].formatted_address;
          formData.current.addressFrom.coordinates = clickedLatLng;
          formData.current.addressFrom.regionIsoCode = regionISOCode;
        } else if (inputType === "to") {
          formData.current.addressTo.address = results[0].formatted_address;
          formData.current.addressTo.coordinates = clickedLatLng;
          formData.current.addressTo.regionIsoCode = regionISOCode;
        }
      }
    });
  };
  // handler for input
  const handleInputChange = (event) => {
    const eventValue = event.target.value;
    setInputValue(eventValue);
    if (eventValue === "") {
      if (inputType === "from") {
        formData.current.addressFrom.address = "";
        formData.current.addressFrom.coordinates = "";
      } else if (inputType === "to") {
        formData.current.addressTo.address = "";
        formData.current.addressTo.coordinates = "";
      }
    }
    getPlacePredictions({ input: eventValue });
    setInputActive(true);
  };
  // handler active when click on prediction list from input
  const handlePredictionClick = (description) => {
    setInputActive(false);
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: description }, (results, status) => {
      if (status === "OK" && results && results[0]) {
        const selectedLatLng = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        };
        setMarkerPosition(selectedLatLng);
        setMapCenter(selectedLatLng);
        setZoom(10);
        if (inputType === "from") {
          formData.current.addressFrom.address = description;
          formData.current.addressFrom.coordinates = selectedLatLng;
          setInputValue(description);
        } else if (inputType === "to") {
          formData.current.addressTo.address = description;
          formData.current.addressTo.coordinates = selectedLatLng;
          setInputValue(description);
        }
      }
    });
  };

  return (
    isLoaded && (
      <>
        <div
          style={{ width: "470px", marginBottom: "24px" }}
          onClick={() => setInputActive(false)}
        >
          <span style={{ color: "black" }}>Введите адрес или выберите на карте</span>
          <div className={styles.input}>
            <Input
              value={
                inputValue
                  ? inputValue
                  : inputType === "from"
                  ? formData.current.addressFrom.address
                  : formData.current.addressTo.address
              }
              onChange={handleInputChange}
              allowClear
            ></Input>
            {inputActive && !isPlacePredictionsLoading && (
              <List
                dataSource={placePredictions}
                className={styles.input__list}
                renderItem={(item) => (
                  <List.Item
                    className={styles.input__list__item}
                    onClick={() => handlePredictionClick(item.description)}
                  >
                    <List.Item.Meta title={item.description} />
                  </List.Item>
                )}
              />
            )}
          </div>
        </div>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapCenter}
          onLoad={onLoad}
          zoom={zoom}
          onUnmount={onUnmount}
          options={defaultOptions}
          onClick={handleMapClick}
        >
          <Marker position={markerPosition} />
        </GoogleMap>
      </>
    )
  );
};

export default Map;
