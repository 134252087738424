import React, { useEffect, useState } from "react";
import {
  Spin,
  Form,
  Input,
  Button,
  Select,
  PageHeader,
  notification,
  Breadcrumb,
} from "antd";
import {

  changeStatusMyComp,
  fetchAllRegionsThunk,
  fetchAllTypesOfCompaniesThunk,
  fetchChangeProfileCompanyMyThunk,
  fetchGetMyCompanyThunk,
} from "../../Store/contractorsSlice/contractorsSlice";
import { getContryThunk } from "../../Store/userSlice/userSlice";
import { PlusOutlined } from "@ant-design/icons";
import { Link,  useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypesHooks";
import {
  ChangeProfileComp,
  ContractorsSlice,
  CurrType,
} from "../../Store/contractorsSlice/interfaces";
import "./profileComp.scss";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import InfoBlock from "../../Components/ProfileCompanyPageComponents/InfoBlock";
import ContractorsService from "../../services/ContractorsService";
import { ICompanyRole, ICountries } from "./interfaces";
import { RootState } from "../../Store/store";

const roles: Array<ICompanyRole> = [
  {
    label: "Покупатель",
    value: "buyer",
  },
  {
    label: "Продавец",
    value: "seller",
  },
  {
    label: "Трейдер",
    value: "manufacturer",
  },
];

const countries: Array<ICountries> = [
  {
    label: "Россия",
    value: "RU",
  },
];

const ChangeProfileCompany: React.FC = (): JSX.Element => {
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const [dirtyForm, setDirtyForm] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const contractors: ContractorsSlice = useAppSelector(
    (state: RootState) => state.contractors
  );

  const { myCompanyDate, typesOFCompaniesDate, AllRegionsDate } = contractors;
  const { MyComp, statusMyComp } = myCompanyDate;
  const { allTypes, statusTypes } = typesOFCompaniesDate;
  const allTypes2:
    | { label: string | Element | JSX.Element; value: string }[]
    | CurrType[] = allTypes;
  const { allRegions, statusRegions } = AllRegionsDate;
  const allRegions2:
    | { label: string | Element | JSX.Element; value: string }[]
    | CurrType[] = allRegions;

    useEffect(() => {
        dispatch(fetchGetMyCompanyThunk())
    }, [])


  useEffect(() => {
    if (statusMyComp) {
      form.setFieldsValue({
        address: MyComp.legalRequisites?.address,
        country: { value: MyComp.country },
        region: { label: MyComp.regionName, value: MyComp.region },
        type: { label: MyComp.typeName, value: MyComp.type },
        role: { value: MyComp.role },
        email: MyComp?.email,
        phone: MyComp?.phone,
        webSite: MyComp?.webSite || "https://",
        bankCode: MyComp.bankRequisites?.bankCode,
        bankName: MyComp.bankRequisites?.name,
        accountNumber: MyComp.bankRequisites?.accountNumber,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusMyComp]);
  useEffect(() => {
    if (statusMyComp === "resolvedChangeCompProfile") {
      notification.open({
        message: "Изменения сохранены",
      });
      navigate("/profileMyCompany");
      dispatch(changeStatusMyComp(""));
    }
  }, [statusMyComp]);
  const onFinishForm = (values): void => {
    console.log("Success:", values);

    const dataForFetch: ChangeProfileComp = {
      name: MyComp.name,
      role: values.role.value || values.role,
      country: values.country.value,
      email: values.email,
      phone: values.phone,
      webSite: values.webSite,
      region: values.region.value
        ? values.region.value
        : JSON.parse(values.region).region,
      regionName: values.region.label
        ? values.region.label
        : JSON.parse(values.region).regionName,
      type: values.type.value ? values.type.value : JSON.parse(values.type).type,
      typeName: values.type.label ? values.type.label : JSON.parse(values.type).typeName,
      legalRequisites: {
        address: values.address,
        inn: MyComp.legalRequisites?.inn,
        kpp: MyComp.legalRequisites?.kpp,
        ogrn: MyComp.legalRequisites?.ogrn,
      },
      bankRequisites: {
        name: values.bankName,
        bankCode: values.bankCode,
        accountNumber: values.accountNumber,
      },
    };
      console.log("DATA FETCH"
      , dataForFetch)
    dispatch(fetchChangeProfileCompanyMyThunk(dataForFetch));
  };

  const onFinishFailedForm = (errorInfo): void => {
    console.log("Failed:", errorInfo);
  };
  const [currentBankData, setCurrentBankData] = useState<string>("");
  async function fetchBankCode(bankCode: string) {
    if (bankCode.length === 9 && Number.isInteger(+bankCode)) {
      const res = await ContractorsService.suggestBankCode(bankCode);

      if (res.data) {
        setCurrentBankData(res.data);
        form.setFieldsValue({
          accountNumber: res.data.correspondentAccount,
          bankName: res.data.name,
        });
      }
    }
  }

  return (
    <>
      <div className="fullbox__main">
        <PageHeader
          className="site-page-header1"
          breadcrumb={
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/" style={{ color: "gray" }}>
                  Главная
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Изменить профиль компании</Breadcrumb.Item>
            </Breadcrumb>
          }
        />
        <div className="main-block">
          <div className="profile-page">
            <Form
              form={form}
              layout={width! > 1050 ? "horizontal" : "vertical"}
              onFinish={onFinishForm}
              onFinishFailed={onFinishFailedForm}
              onFieldsChange={() => setDirtyForm(true)}
              // onValuesChange={onFormLayoutChange}
            >
              <div className="profile">
                <div className="profile__inner">
                  <h3 className="title">{MyComp && MyComp?.name}</h3>
                  <p className="sub-title">
                    ИНН {`${MyComp && MyComp.legalRequisites?.inn}`}
                  </p>
                  <div
                    className="form-profile"
                    // onValuesChange={onFormLayoutChange}
                  >
                    <Form.Item
                      className="input-wrapper l1"
                      label="Юр. адрес"
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                      ]}
                    >
                      <Input className="inputt" />
                    </Form.Item>

                    <div className="two-el">
                      <Form.Item
                        className="input-wrapper two-el__children"
                        label="Страна"
                        name="country"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                        ]}
                      >
                        <Select
                          onClick={() => dispatch(getContryThunk())}
                          className="inputt"
                          options={countries}
                          placeholder="Выберите"
                        />
                      </Form.Item>
                      <Form.Item
                        className="input-wrapper two-el__children "
                        label="Регион"
                        name="region"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                        ]}
                      >
                        <Select
                          className="inputt"
                          onClick={
                            statusRegions !== "resolved"
                              ? () => dispatch(fetchAllRegionsThunk())
                              : undefined
                          }
                          options={
                            statusRegions === "loading"
                              ? [{ label: <Spin />, value: "1" }]
                              : allRegions2
                          }
                          placeholder="Выберите"
                        />
                      </Form.Item>
                    </div>
                    <div className="two-el">
                      <Form.Item
                        className="input-wrapper two-el__children "
                        label="Деятельность"
                        name="type"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                        ]}
                      >
                        <Select
                          onClick={
                            statusTypes !== "resolved"
                              ? () => dispatch(fetchAllTypesOfCompaniesThunk())
                              : undefined
                          }
                          className="inputt"
                          placeholder="Выберите"
                          options={
                            statusTypes === "loading"
                              ? [{ label: <Spin />, value: "1" }]
                              : allTypes2
                          }
                          // defaultValue={profiles[0]}
                        />
                      </Form.Item>
                      <Form.Item
                        className="input-wrapper two-el__children "
                        label="Роль компании"
                        name="role"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                        ]}
                      >
                        <Select
                          className="inputt"
                          options={roles}
                          placeholder="Выберите"
                        />
                      </Form.Item>
                    </div>
                    <div className="two-el">
                      <Form.Item
                        className="input-wrapper two-el__children"
                        label="E-mail компании"
                        name="email"
                        validateTrigger="onBlur"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                          {
                            type: "email",
                            message: "некорректный email",
                          },
                        ]}
                      >
                        <Input className="inputt" />
                      </Form.Item>
                      <Form.Item
                        className="input-wrapper two-el__children"
                        label="Телефон компании"
                        name="phone"
                      >
                        <Input className="inputt" />
                      </Form.Item>
                    </div>
                    <div className="two-el">
                      <Form.Item
                        className="input-wrapper two-el__children"
                        label="Сайт компании"
                        name="webSite"
                        validateTrigger="onBlur"
                        rules={[
                          {
                            // pattern:
                          },
                        ]}
                      >
                        <Input className="inputt" placeholder="https://..." />
                      </Form.Item>
                    </div>
                    <h4 className="sub-title">Реквизиты</h4>

                    <div className="two-el">
                      <Form.Item
                        className="input-wrapper two-el__children "
                        label="БИК"
                        name="bankCode"
                        rules={[
                          {
                            required: true,
                            message: "Введите цифры",
                            pattern: new RegExp(/^[0-9]+$/),
                          },
                          {
                            message: "Введите 9 цифр",
                            validator: (_, value) => {
                              if (value.length === 9) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject("не валидный ИНН");
                              }
                            },
                          },
                        ]}
                      >
                        <Input
                          className="inputt"
                          onChange={(e) => fetchBankCode(e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item
                        className="input-wrapper two-el__children "
                        label="Расчетный счет"
                        name="accountNumber"
                        rules={[
                          {
                            required: true,
                            message: "",
                            pattern: new RegExp(/^[0-9]+$/),
                          },
                          {
                            message: "не более 20 символов",
                            validator: (_, value) => {
                              if (value.length <= 20) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject("не валидный ИНН");
                              }
                            },
                          },
                        ]}
                      >
                        <Input className="inputt" />
                      </Form.Item>
                    </div>
                    <Form.Item
                      className="input-wrapper l1"
                      label="Наименование банка"
                      name="bankName"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                      ]}
                    >
                      <Input className="inputt" />
                    </Form.Item>

                    <Form.Item className="btn">
                      <Button disabled>
                        <div className="btn-inner">
                          <PlusOutlined />
                          <div>Добавить счет</div>
                        </div>
                      </Button>
                    </Form.Item>
                    {/* <Form.Item
                      className="input-wrapper download"
                      valuePropName="fileList"
                      >
                      <Upload action="/upload.do" listType="picture-card">
                          <div className="download-inner">
                          <PlusOutlined />
                          <div>Документы</div>
                          </div>
                      </Upload>
                      <img className="info-circle" src={infoCirleIcon} alt="info" />
                      </Form.Item> */}
                  </div>
                </div>
              </div>
              {width! <= 500 ? <InfoBlock /> : undefined}

              <Form.Item>
                <Button
                  className="submit-all"
                  size="large"
                  type="primary"
                  htmlType="submit"
                  disabled={!dirtyForm}
                >
                  Сохранить изменения
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeProfileCompany;
