import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import InformerService from "../../services/InformerService";
import { InformerState, IResponseGetInformer, IResponseSetInformer } from "./interfaces";

const initialState: InformerState = {
  getInformer: {
    statusInformer: null,
    errorInformer: null,
    data: {},
  },
  setInformer: {
    statusInformer: null,
    errorInformer: null,
    data: {},
  },
};

export const fetchGetInformerThunk = createAsyncThunk<
  IResponseGetInformer,
  undefined,
  { rejectValue: string }
>("shipping/fetchGetShippingCultureThunk", async function (_, { rejectWithValue }) {
  try {
    const response = await InformerService.getInformer();
    return response.data as IResponseGetInformer;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const fetchSetInformerThunk = createAsyncThunk<
  IResponseSetInformer,
  Omit<IResponseSetInformer, "id">,
  { rejectValue: string }
>("shipping/fetchCalcDistanceThunk", async function (data, { rejectWithValue }) {
  try {
    const response = await InformerService.setInformer(data);

    return response.data as IResponseSetInformer;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

const informerSlice = createSlice({
  name: "informer",
  initialState,
  reducers: {
    changeSetStatus(state, action) {
      state.setInformer.statusInformer = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchGetInformerThunk.pending, (state) => {
        state.getInformer.statusInformer = "loading";
        state.getInformer.errorInformer = null;
      })
      .addCase(fetchGetInformerThunk.fulfilled, (state, action) => {
        state.getInformer.statusInformer = "resolvedGetInformer";
        state.getInformer.data = action.payload;
      })
      .addCase(fetchGetInformerThunk.rejected, (state, action) => {
        state.getInformer.statusInformer = "rejected";
        state.getInformer.errorInformer = action.payload;
      })
      .addCase(fetchSetInformerThunk.pending, (state) => {
        state.setInformer.statusInformer = "loading";
        state.setInformer.errorInformer = null;
      })
      .addCase(fetchSetInformerThunk.fulfilled, (state, action) => {
        state.setInformer.statusInformer = "resolvedSetInformer";
        state.setInformer.data = action.payload;
      })
      .addCase(fetchSetInformerThunk.rejected, (state, action) => {
        state.setInformer.statusInformer = "rejected";
        state.setInformer.errorInformer = action.payload;
      });
  },
});

export const { changeSetStatus } = informerSlice.actions;

export default informerSlice.reducer;
